import React from 'react';
import { Query } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getPlatformFeature } from '../../graphql/queries/platformFeature';
import Header from '../common/Header';
import SanitizedHTML from '../sanitizedHTML';
import isQuillEmpty from '../../helpers/isQuillEmpty';

const PlatformFeature = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  return (
    <div className="list">
      <Header
        title="platformFeatures.show.title"
        linkTo={`/admin/platform_features/${id}/edit`}
        buttonLabel="shared.edit"
      />
      <Query {...getPlatformFeature(id)}>
        {({ loading, data }) => {
          if (loading) return <div className="lds-dual-ring" />;
          if (data) {
            const { platformFeature } = data;
            const hashtags = platformFeature.hashtags.map(keyword => keyword.name).join(', ');
            const nestedPlatformFeatures = platformFeature.nestedPlatformFeatures
              .map(feature => feature.title)
              .join(', ');
            return (
              <div className="common-show">
                <div className="grid">
                  <div className="col-12">
                    <div className="common-show__infos">
                      <div className="col-12">
                        <div className="common-show__infos--field">
                          {t('platformFeatures.attributes.title')}
                        </div>
                        <div className="common-show__infos--value">{platformFeature.title}</div>
                        <div className="common-show__infos--field">
                          {t('platformFeatures.attributes.keywords')}
                        </div>
                        <div className="common-show__infos--value">
                          {hashtags || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('platformFeatures.attributes.description')}
                        </div>
                        <div className="common-show__infos--value">
                          {isQuillEmpty(platformFeature.description) ? (
                            t('shared.notAvailable')
                          ) : (
                            <SanitizedHTML html={platformFeature.description} />
                          )}
                        </div>
                        <div className="common-show__infos--field">
                          {t('platformFeatures.attributes.updatedBy')}
                        </div>
                        <div className="common-show__infos--value">
                          {platformFeature.updatedBy.name}
                        </div>
                        <div className="common-show__infos--field">
                          {t('platformFeatures.attributes.updatedAt')}
                        </div>
                        <div className="common-show__infos--value">{platformFeature.updatedAt}</div>
                        <div className="common-show__infos--field">
                          {t('platformFeatures.attributes.nestedPlatformFeatures')}
                        </div>
                        <div className="common-show__infos--value">
                          {nestedPlatformFeatures || t('shared.notAvailable')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return null;
        }}
      </Query>
    </div>
  );
};

export default PlatformFeature;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@bit/mno-wtag.welltravel.button';

import BaseModal from '../rclComponents/BaseModal';
import { useMutation } from '@apollo/react-hooks';
import ErrorComponent from '../common/ErrorComponent';
import PropTypes from 'prop-types';
import HotelInformationCard from './HotelInformationCard';
import { getCombinedHotelByUniqueId } from '../../graphql/queries/hotelCombinedDatabase';
import { EDIT_HUMAN_VERIFIED_HOTEL } from '../../graphql/mutations/humanVerifiedHotel';
import { getHumanVerifiedHotel } from '../../graphql/queries/humanVerifiedHotel';
import { Query } from 'react-apollo';

const SearchForBestMatch = props => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [editHumanVerifiedHotel, callbackDataOnEdit] = useMutation(EDIT_HUMAN_VERIFIED_HOTEL);
  const id = props.id;
  const submitToBackEnd = bestMatch => {
    setErrorOccurred(false);
    editHumanVerifiedHotel({
      variables: { id, bestMatch },
      refetchQueries: [getHumanVerifiedHotel(id)],
    })
      .then(() => {
        setModalOpen(false);
      })
      .catch(() => {
        setErrorOccurred(true);
      });
  };

  return (
    <BaseModal
      linkText={t('humanVerifiedHotels.buttons.searchForOtherMatches')}
      linkClassName="button"
      defaultPadding={true}
      header={t('humanVerifiedHotels.buttons.searchForOtherMatches')}
      open={modalOpen}
      onModalClose={() => {
        setModalOpen(false);
      }}
      onModalOpen={() => {
        setModalOpen(true);
      }}
      size="huge"
    >
      <div className="search-for-best-match-content">
        {props.matchedSources.map(bestMatch => (
          <div className="search-for-best-match-content__hotel" key={bestMatch}>
            <Query {...getCombinedHotelByUniqueId(bestMatch)}>
              {({ loading, data }) => {
                if (loading) return <div className="lds-dual-ring" />;
                if (data) {
                  const { hotelCombinedDatabase } = data;
                  return (
                    <React.Fragment>
                      <HotelInformationCard
                        id={hotelCombinedDatabase.id}
                        latitude={hotelCombinedDatabase.latitude}
                        longitude={hotelCombinedDatabase.longitude}
                        name={hotelCombinedDatabase.name}
                        iso31661={hotelCombinedDatabase.iso31661}
                        iso31662={hotelCombinedDatabase.iso31662}
                        supplierCodes={hotelCombinedDatabase.supplierCodes}
                        itemType="hotelCombinedDatabases"
                        welltravelCodes={hotelCombinedDatabase.welltravelCodes}
                        additionalContents={hotelCombinedDatabase.additionalContents}
                        description={hotelCombinedDatabase.description}
                        detailedInformation={false}
                      />
                      {errorOccurred && <ErrorComponent error={{ callbackDataOnEdit }} />}
                      <Button
                        label={t('humanVerifiedHotels.buttons.selectAsBestMatch')}
                        onClick={() => submitToBackEnd(bestMatch)}
                      />
                    </React.Fragment>
                  );
                }
              }}
            </Query>
          </div>
        ))}
      </div>
    </BaseModal>
  );
};

SearchForBestMatch.propTypes = {
  id: PropTypes.string.isRequired,
  matchedSources: PropTypes.arrayOf(PropTypes.string.isRequired),
  currentCursor: PropTypes.string,
  searchParam: PropTypes.string,
};

export default SearchForBestMatch;

import React from 'react';
import {
  GET_ANCILLARY_CATEGORIES,
  GET_CURSORS_FOR_ANCILLARY_CATEGORIES,
} from '../../graphql/queries/ancillaryCategory';
import { DELETE_ANCILLARY_CATEGORY } from '../../graphql/mutations/ancillaryCategory';
import List from '../common/List';

const AncillaryCategoryList = () => {
  return (
    <List
      getItems={GET_ANCILLARY_CATEGORIES}
      getCursorsForItems={GET_CURSORS_FOR_ANCILLARY_CATEGORIES}
      deleteItem={DELETE_ANCILLARY_CATEGORY}
      listAttributes={['identifier']}
      itemType="ancillaryCategories"
      modelName="AncillaryCategory"
      title="nav.ancillaryCategories"
      linkTo="/admin/ancillary_categories/new"
      buttonLabel="shared.addNew"
    />
  );
};

export default AncillaryCategoryList;

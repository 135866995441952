import React from 'react';
import Icon from '../icon/Icon';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const EmptyContentPlaceholder = props => {
  const { t } = useTranslation();

  return (
    <div className="empty-content-placeholder">
      <div className="empty-content-placeholder__icon">
        <Icon name={props.modelName} />
      </div>
      <div className="empty-content-placeholder__text">
        {t('shared.emptyContentPlaceholderText', { entityName: props.entityName })}
      </div>
    </div>
  );
};

EmptyContentPlaceholder.defaultProps = {
  modelName: null,
  entityName: null
};

EmptyContentPlaceholder.propTypes = {
  modelName: PropTypes.string,
  entityName: PropTypes.string
};

export default EmptyContentPlaceholder;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LabelHint from '../LabelHint';

const checkboxSize = size => {
  switch (size) {
    case 'large':
      return 'checkbox--large';
    case 'huge':
      return 'checkbox--huge';
    case 'small':
      return 'checkbox--small';
    default:
      return null;
  }
};

const CheckBox = props => {
  const { label, size, disabled, dark, name, hint, className, ...others } = props;

  return (
    <label
      htmlFor={name}
      className={classNames('checkbox', className, checkboxSize(size), {
        'checkbox--disabled': disabled,
        'checkbox-dark': dark,
      })}
    >
      <input
        className="checkbox__input"
        id={name}
        type="checkbox"
        disabled={disabled}
        {...others}
        onChange={event => props.onChange(event, event.target.checked)}
      />
      <span className="checkbox__check" />
      {label && <span className="checkbox__label">{label}</span>}
      {hint && <LabelHint hint={hint} />}
    </label>
  );
};

CheckBox.defaultProps = {
  label: null,
  size: null,
  disabled: false,
  dark: false,
  onChange: null,
  hint: null,
  className: null,
};

CheckBox.propTypes = {
  label: PropTypes.node,
  size: PropTypes.oneOf(['large', 'huge', 'small']),
  disabled: PropTypes.bool,
  dark: PropTypes.bool,
  name: PropTypes.string.isRequired,
  hint: PropTypes.node,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default CheckBox;

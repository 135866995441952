import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const FileUpload = props => {
  const { t } = useTranslation();
  const onDrop = files => {
    props.onFileUpload(files);
  };
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    onDrop,
    multiple: props.multiple,
  });

  const acceptedFilesItems = acceptedFiles.map(file => (
    <li key={file.path}>
      <div>
        {file.path} - {file.size} {t('fileUpload.bytes')}
      </div>
      <div>
        <img src={URL.createObjectURL(file)} className="file-upload__image-preview" alt="preview" />
      </div>
    </li>
  ));

  return (
    <div
      {...getRootProps()}
      className={classNames('file-upload__dropzone', {
        'file-upload__dropzone--active': isDragActive,
      })}
    >
      <input {...getInputProps()} />
      {isDragActive ? <p> {t('fileUpload.active')}</p> : <p>{t('fileUpload.label')}</p>}
      <aside>
        <h4>{t('fileUpload.header')}</h4>
        <ul>{acceptedFilesItems}</ul>
      </aside>
    </div>
  );
};
export default FileUpload;

import gql from 'graphql-tag';

export const CREATE_FEATURE = gql`
  mutation createFeature($name: String!, $icon: String!) {
    createFeature(name: $name, icon: $icon) {
      id
      name
      icon
    }
  }
`;

export const EDIT_FEATURE = gql`
  mutation editFeature($id: ID!, $name: String!, $icon: String!) {
    editFeature(id: $id, name: $name, icon: $icon) {
      id
      name
      icon
    }
  }
`;

export const DELETE_FEATURE = gql`
  mutation deleteFeature($id: ID!) {
    deleteFeature(id: $id) {
      name
      icon
    }
  }
`;

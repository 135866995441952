import React from 'react';
import { useTranslation } from 'react-i18next';
import availableLanguageOptions from '../../helpers/availableLanguageOptions';
import SelectBox from '@bit/mno-wtag.welltravel.select-box';

const LanguageSelection = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;

  return (
    <div className="language-selection">
      <SelectBox
        value={language}
        onChange={value => i18n.changeLanguage(value)}
        options={availableLanguageOptions}
        size="tiny"
        create={false}
        persist={false}
      />
    </div>
  );
};

export default LanguageSelection;

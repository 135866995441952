import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../icon/Icon';

const classForSize = size => {
  switch (size) {
    case 'tiny':
      return `label-hint--tiny`;
    case 'small':
      return `label-hint--small`;
    case 'large':
      return `label-hint--large`;
    case 'huge':
      return `label-hint--huge`;
    default:
      return null;
  }
};

const LabelHint = props => {
  const { hint, size, icon, noMargin, placement, className, minWidthAuto, children } = props;

  return (
    <span
      className={classNames(
        'label-hint',
        classForSize(size),
        {
          'label-hint--no-margin': noMargin,
        },
        className,
      )}
    >
      {!children && <Icon name={icon} />}
      {children}
      <span
        className={classNames('label-hint__popup', `label-hint__popup--${placement}`, {
          'label-hint__popup--width-auto': minWidthAuto,
        })}
      >
        {hint}
      </span>
    </span>
  );
};

LabelHint.defaultProps = {
  children: null,
  size: null,
  noMargin: false,
  icon: 'invalidOutline',
  placement: 'top-right',
  className: null,
  minWidthAuto: false,
};

LabelHint.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
  noMargin: PropTypes.bool,
  size: PropTypes.oneOf(['tiny', 'small', 'large', 'huge']),
  hint: PropTypes.node.isRequired,
  placement: PropTypes.oneOf(['top-left', 'top-right']),
  className: PropTypes.string,
  minWidthAuto: PropTypes.bool,
};

export default LabelHint;

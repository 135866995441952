import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@bit/mno-wtag.welltravel.button';
import Input from '@bit/mno-wtag.welltravel.input';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  GET_STATIC_MESSAGE,
  getStaticMessage,
} from '../../graphql/queries/staticMessage';
import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import {
  CREATE_STATIC_MESSAGE,
  EDIT_STATIC_MESSAGE,
} from '../../graphql/mutations/staticMessage';
import clearCache from '../../helpers/clearCache';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

const StaticMessageForm = props => {
  const { t } = useTranslation();
  let { id } = useParams();
  const isNewRecord = !id;

  const [createStaticMessage, callbackDataOnCreate] = useMutation(CREATE_STATIC_MESSAGE, {
    update: cache => clearCache(cache, /StaticMessage/),
  });
  const [editStaticMessage, callbackDataOnEdit] = useMutation(EDIT_STATIC_MESSAGE);
  const staticMessageFromId = useQuery(GET_STATIC_MESSAGE, {
    variables: { id },
    skip: isNewRecord,
  });

  const [key, setKey] = useState('');
  const [message, setMessage] = useState('');

  const onKeyChange = event => {
    setKey(event.target.value);
  };

  const onMessageChange = event => {
    setMessage(event.target.value);
  };

  useEffect(() => {
    if (
      staticMessageFromId &&
      staticMessageFromId.data &&
      !staticMessageFromId.loading
    ) {
      setKey(staticMessageFromId.data.staticMessage.key);
      setMessage(staticMessageFromId.data.staticMessage.message);
    }
  }, [staticMessageFromId]);

  const submitToBackEnd = debounce(() => {
    props.resetError();

    if (isNewRecord) {
      createStaticMessage({
        variables: { key, message },
      })
        .then(({ data }) => {
          props.history.push(`/admin/static_messages/${data.createStaticMessage.id}`);
        })
        .catch(() => props.onError());
    } else {
      editStaticMessage({
        variables: { id, key, message },
        refetchQueries: [getStaticMessage(id)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/static_messages/${data.editStaticMessage.id}`);
        })
        .catch(() => props.onError());
    }
  }, 500);

  return (
    <div className="common-form">
      <div className="common-form__title">
        {isNewRecord ?
          t('shared.new', { entityName: t('staticMessages.show.title') })
          : t('shared.editTitle', { entityName: t('staticMessages.show.title') })}
      </div>
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit, callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-lg-8 col-md-10 col-sm-10 col-xs-10">
            <div className="col-12 col-bleed-y">
              <div className="common-form__field validation-message-form__name">
                <Input
                  label={t('staticMessages.attributes.key')}
                  value={key}
                  onChange={onKeyChange}
                />
              </div>
              <div className="common-form__field validation-message-form__code">
                <Input
                  label={t('staticMessages.attributes.message')}
                  value={message}
                  onChange={onMessageChange}
                />
              </div>
              <div className="common-form__submit-button">
                <Button
                  label={isNewRecord ? t('shared.create') : t('shared.update')}
                  onClick={submitToBackEnd}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithErrorHandler(StaticMessageForm);

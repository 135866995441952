import React, { useState } from 'react';
import Button from '@bit/mno-wtag.welltravel.button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Icon from '../icon/Icon';
import { all, one } from '../../graphql/queries/prepareQueryParams';
import {
  GET_PLATFORM_FEATURE,
  GET_PLATFORM_FEATURE_CATEGORIES,
  GET_PLATFORM_FEATURES,
  getPlatformFeature,
} from '../../graphql/queries/platformFeature';
import { useMutation, useQuery } from '@apollo/react-hooks';

import {
  DELETE_PLATFORM_FEATURE,
  EDIT_PLATFORM_FEATURE,
} from '../../graphql/mutations/platformFeature';

const PlatformFeatureData = props => {
  const { t } = useTranslation();
  const { data, currentCursor, searchParam } = props;
  const [id, setId] = useState('');
  const notId = !id;

  const platformFeatureFromId = useQuery(GET_PLATFORM_FEATURE, {
    variables: { id },
    skip: notId,
  });

  if (platformFeatureFromId && platformFeatureFromId.data && !platformFeatureFromId.loading) {
    const platformFeatureData = platformFeatureFromId.data.platformFeature;
    data['subPlatformFeatures'] = platformFeatureData.nestedPlatformFeatures;
  }

  const [expand, setExpand] = useState(false);
  const [deletePlatformFeature] = useMutation(DELETE_PLATFORM_FEATURE);
  const [editPlatformFeature] = useMutation(EDIT_PLATFORM_FEATURE);

  const onPlatformFeatureDelete = id => {
    if (data.parentId) {
      window.confirm(t('platformFeatures.index.confirmDelete')) &&
        deletePlatformFeature({
          variables: { id },
          refetchQueries: [
            one(GET_PLATFORM_FEATURE, data.parentId),
            all(GET_PLATFORM_FEATURE_CATEGORIES),
          ],
        });
    } else {
      window.confirm(t('platformFeatures.index.confirmDelete')) &&
        deletePlatformFeature({
          variables: { id },
          refetchQueries: [
            all(GET_PLATFORM_FEATURES, currentCursor, searchParam),
            all(GET_PLATFORM_FEATURES, currentCursor),
            all(GET_PLATFORM_FEATURE_CATEGORIES),
          ],
        });
    }
  };

  const processExpansion = () => {
    if (expand) {
      delete data.subPlatformFeatures;
      setId('');
      setExpand(false);
    } else {
      setId(data.id);
      setExpand(true);
    }
  };

  const updatePlatformFeatureOrder = (platformFeature, down = false) => {
    const { id, title, parentId } = platformFeature;
    let order = platformFeature.order;
    order = down ? order - 1 : order + 1;

    if (data.parentId) {
      editPlatformFeature({
        variables: { id, title, order, userId: props.userId },
        refetchQueries: [getPlatformFeature(parentId), getPlatformFeature(id)],
      });
    } else {
      editPlatformFeature({
        variables: { id, title, order, userId: props.userId },
        refetchQueries: [
          getPlatformFeature(id),
          all(GET_PLATFORM_FEATURES, currentCursor, searchParam),
          all(GET_PLATFORM_FEATURES, currentCursor),
        ],
      });
    }
  };

  return (
    <React.Fragment key={data.id}>
      <div className="platform-feature-list__data">
        <div className="platform-feature-list__value-topic" onClick={processExpansion}>
          <p style={{ paddingLeft: props.depth * 15 + 'px' }}>{data['title']}</p>
          <div className="platform-feature-list__value-icon">
            {data.nestedPlatformFeatures &&
              data.nestedPlatformFeatures.length > 0 &&
              (expand ? <Icon name="ArrowUp" /> : <Icon name="ArrowDown" />)}
          </div>
        </div>
        <div className="platform-feature-list__value">
          <Button label={t('shared.up')} onClick={() => updatePlatformFeatureOrder(data)} />
          <div className="platform-feature-list__value-order">{data.order}</div>
          <Button
            label={t('shared.down')}
            type="darkOverImage"
            disabled={!data.order}
            onClick={() => updatePlatformFeatureOrder(data, true)}
          />
        </div>
        <div className="platform-feature-list__value platform-feature-list__value--actions">
          <Link to={`/admin/platform_features/${data.id}`}>
            <Button label={t('shared.show')} />
          </Link>
          <Link to={`/admin/platform_features/${data.id}/edit`}>
            <Button label={t('shared.edit')} />
          </Link>
          <Button label={t('shared.delete')} onClick={() => onPlatformFeatureDelete(data.id)} />
        </div>
      </div>
      {data.subPlatformFeatures &&
        data.subPlatformFeatures.length > 0 &&
        data.subPlatformFeatures.map(data => (
          <React.Fragment key={data.id}>
            <PlatformFeatureData
              data={data}
              currentCursor={props.currentCursor}
              searchParam={props.searchParam}
              userId={props.userId}
              depth={props.depth + 1}
            />
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};

PlatformFeatureData.propTypes = {
  data: PropTypes.object.isRequired,
  currentCursor: PropTypes.string,
  searchParam: PropTypes.string,
  userId: PropTypes.string.isRequired,
  depth: PropTypes.number.isRequired,
};

export default PlatformFeatureData;

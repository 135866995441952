import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_FEATURES = gql`
  query features($query: String) {
    features(query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_FEATURES = gql`
  query features($first: Int!, $after: String, $query: String) {
    features(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          name
          icon
        }
      }
    }
  }
`;

export const getFeatures = after => all(GET_FEATURES, after);

export const GET_FEATURE = gql`
  query feature($id: ID!) {
    feature(id: $id) {
      name
      icon
    }
  }
`;

export const getFeature = id => one(GET_FEATURE, id);

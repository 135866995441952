import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@bit/mno-wtag.welltravel.button';
import Input from '@bit/mno-wtag.welltravel.input';
import TextArea from '@bit/mno-wtag.welltravel.text-area';

import BaseModal from '../rclComponents/BaseModal';
import WrappedMap from '../common/WrappedMap';
import { useQuery, useMutation } from '@apollo/react-hooks';
import ErrorComponent from '../common/ErrorComponent';
import { all } from '../../graphql/queries/prepareQueryParams';
import { humanVerifiedHotelsPerPage } from '../../variables';
import { CREATE_HOTEL_COMBINED_DATABASE } from '../../graphql/mutations/hotelCombinedDatabase';
import { EDIT_HUMAN_VERIFIED_HOTEL } from '../../graphql/mutations/humanVerifiedHotel';
import PropTypes from 'prop-types';
import { GET_GOOGLE_MAPS_PUBlIC_API_KEY } from '../../graphql/queries/google_maps_public_api_key';

const CreateNewHotel = props => {
  const { t } = useTranslation();
  const [createHotelCombinedDatabase, callbackDataOnCreate] = useMutation(
    CREATE_HOTEL_COMBINED_DATABASE,
  );
  const { data } = useQuery(GET_GOOGLE_MAPS_PUBlIC_API_KEY);
  const renderMap = data && data.googleMapsPublicApiKey;

  const [modalOpen, setModalOpen] = useState(false);
  const [editHumanVerifiedHotel] = useMutation(EDIT_HUMAN_VERIFIED_HOTEL);
  const rawHotel = props.rawHotel;
  const currentCursor = props.currentCursor;
  const id = props.humanVerifiedHotelId;

  const [name, setName] = useState(rawHotel.name || '');
  const [welltravelCodes, setWelltravelCodes] = useState(
    JSON.stringify([`${rawHotel.welltravelCode}`], undefined, 0),
  );
  const [mainSource, setMainSource] = useState(rawHotel.provider);
  const [iso31661, setIso31661] = useState(rawHotel.iso31661);
  const [iso31662, setIso31662] = useState(rawHotel.iso31662);
  const [imageUrls, setImageUrls] = useState(JSON.stringify(rawHotel.imageUrls, undefined, 4));
  const [description, setDescription] = useState(rawHotel.description || '');
  const [latitude, setLatitude] = useState(rawHotel.latitude);
  const [longitude, setLongitude] = useState(rawHotel.longitude);
  const [additionalContents, setAdditionalContents] = useState(
    JSON.stringify(rawHotel.additionalContents, undefined, 4),
  );
  const [supplierCodes, setSupplierCodes] = useState(
    JSON.stringify(rawHotel.supplierCodes, undefined, 4),
  );
  const [proximityPercentage] = useState(100);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const setLatLong = value => {
    setLatitude(value.lat);
    setLongitude(value.lng);
  };

  const submitOnCreateToBackEnd = () => {
    const verified = true;
    setErrorOccurred(false);
    window.confirm(t('humanVerifiedHotels.index.confirmAdd')) &&
      createHotelCombinedDatabase({
        variables: {
          name,
          welltravelCodes,
          mainSource,
          iso31661,
          iso31662,
          imageUrls,
          description,
          latitude,
          longitude,
          additionalContents,
          supplierCodes,
          proximityPercentage,
        },
      })
        .then(() => {
          editHumanVerifiedHotel({
            variables: { id, verified },
            refetchQueries: [all(props.getHotels, currentCursor, '', humanVerifiedHotelsPerPage)],
          }).then(() => {
            setModalOpen(false);
            return props.refetchTotalVerifiableHotels();
          });
        })
        .catch(() => {
          setErrorOccurred(true);
        });
  };

  return (
    <BaseModal
      linkText={t('humanVerifiedHotels.buttons.createAsNew')}
      linkClassName="button"
      defaultPadding={true}
      header={t('humanVerifiedHotels.buttons.createAsNew')}
      open={modalOpen}
      onModalClose={() => {
        setModalOpen(false);
      }}
      onModalOpen={() => {
        setModalOpen(true);
      }}
      size="huge"
    >
      <div className="create-new-hotel-content">
        <div className="create-new-hotel-content__attributes">
          <Input
            label={t('hotelCombinedDatabases.attributes.name')}
            value={name}
            onChange={event => setName(event.target.value)}
          />
          <Input
            label={t('hotelCombinedDatabases.attributes.welltravelCodes')}
            value={welltravelCodes}
            onChange={event => setWelltravelCodes(event.target.value)}
          />
          <Input
            label={t('hotelCombinedDatabases.attributes.mainSource')}
            value={mainSource}
            onChange={event => setMainSource(event.target.value)}
          />
          <Input
            label={t('hotelCombinedDatabases.attributes.iso31661')}
            value={iso31661}
            onChange={event => setIso31661(event.target.value)}
          />
          <Input
            label={t('hotelCombinedDatabases.attributes.iso31662')}
            value={iso31662}
            onChange={event => setIso31662(event.target.value)}
          />
          <Input
            type="number"
            label={t('hotelCombinedDatabases.attributes.latitude')}
            value={latitude}
            onChange={event => setLatitude(event.target.value)}
          />
          <Input
            type="number"
            label={t('hotelCombinedDatabases.attributes.longitude')}
            value={longitude}
            onChange={event => setLongitude(event.target.value)}
          />
          <div className="create-new-hotel-content__attributes-map">
            {renderMap && (
              <WrappedMap
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${data.googleMapsPublicApiKey}`}
                latLong={{ lat: latitude, lng: longitude }}
                infoWindowContent={name}
                getLatLong={value => {
                  setLatLong(value);
                }}
              />
            )}
          </div>
          <TextArea
            label={t('hotelCombinedDatabases.attributes.description')}
            value={description}
            onChange={event => setDescription(event.target.value)}
          />
          <TextArea
            label={`${t('hotelCombinedDatabases.attributes.imageUrls')}(${t(
              'hotelCombinedDatabases.hints.array',
            )})`}
            value={imageUrls}
            onChange={event => setImageUrls(event.target.value)}
          />
          <TextArea
            label={`${t('hotelCombinedDatabases.attributes.supplierCodes')}(${t(
              'hotelCombinedDatabases.hints.json',
            )})`}
            value={supplierCodes}
            onChange={event => setSupplierCodes(event.target.value)}
          />
          <TextArea
            label={`${t('hotelCombinedDatabases.attributes.additionalContents')}(${t(
              'hotelCombinedDatabases.hints.json',
            )})`}
            value={additionalContents}
            onChange={event => setAdditionalContents(event.target.value)}
          />
          {errorOccurred && <ErrorComponent error={{ callbackDataOnCreate }} />}
        </div>

        <div className="create-new-hotel-content__submit">
          <Button label={t('shared.create')} onClick={submitOnCreateToBackEnd} />
        </div>
        <div className="create-new-hotel-content__cancel">
          <Button
            type={'danger'}
            label={t('shared.cancel')}
            onClick={() => {
              setModalOpen(false);
            }}
          />
        </div>
      </div>
    </BaseModal>
  );
};

CreateNewHotel.propTypes = {
  rawHotel: PropTypes.object.isRequired,
  humanVerifiedHotelId: PropTypes.string.isRequired,
  currentCursor: PropTypes.string,
  getHotels: PropTypes.object.isRequired,
  refetchTotalVerifiableHotels: PropTypes.func.isRequired,
};

export default CreateNewHotel;

import React from 'react';
import { useTranslation } from 'react-i18next';

const NoResultsFound = () => {
  const { t } = useTranslation();

  return <div className="no-results-found">{t('shared.noResultsFound')}</div>;
};

export default NoResultsFound;

import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_AIRPORTS = gql`
  query airports($first: Int, $after: String, $query: String) {
    airports(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_AIRPORTS = gql`
  query airports($first: Int, $after: String, $query: String) {
    airports(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          name
          code
          city {
            id
            translatedNames {
              id
              name
              locale
            }
          }
          description
          publishDescription
          place {
            id
            name
          }
          deactivated
        }
      }
    }
  }
`;

export const getAirports = after => all(GET_AIRPORTS, after);

export const GET_AIRPORT = gql`
  query airport($id: ID!) {
    airport(id: $id) {
      name
      code
      city {
        id
        translatedNames {
          id
          name
          locale
        }
      }
      description
      publishDescription
      twitterHandle
      imageUrls
      place {
        id
        name
      }
      deactivated
    }
  }
`;

export const getAirport = id => one(GET_AIRPORT, id);

import { validate as uuidValidate } from 'uuid';

const normalizeMultiFormAttributes = attributes => {
  return attributes.map(item => {
    let itemToNormalize = Object.assign({}, item);
    // __typename is generated from graphql, we do not need this attribute to create / update supplier fare brand
    delete itemToNormalize.__typename;

    if (uuidValidate(itemToNormalize.id)) {
      // item.id can be integer or an uuid, if uuid it means its a new item. To create a new object we do not want to keep the id.
      delete itemToNormalize.id;
    } else {
      itemToNormalize.id = parseInt(itemToNormalize.id);
    }

    return itemToNormalize;
  });
};

export default normalizeMultiFormAttributes;

import React from 'react';
import { Query } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFlight } from '../../graphql/queries/flight';
import Header from '../common/Header';

const Flight = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  return (
    <div className="list">
      <Header
        title="flights.show.title"
        linkTo={`/admin/flights/${id}/edit`}
        buttonLabel="shared.edit"
      />
      <Query {...getFlight(id)}>
        {({ loading, data }) => {
          if (loading) return <div className="lds-dual-ring" />;
          if (data) {
            const { flight } = data;

            return (
              <div className="common-show">
                <div className="grid">
                  <div className="col-12">
                    <div className="common-show__infos">
                      <div className="col-12">
                        <div className="common-show__infos--field">
                          {t('flights.attributes.flightNumber')}
                        </div>
                        <div className="common-show__infos--value">{flight.flightNumber}</div>
                        <div className="common-show__infos--field">
                          {t('flights.attributes.wifiAvailability')}
                        </div>
                        <div className="common-show__infos--value">
                          {typeof flight.wifiAvailability === 'boolean'
                            ? flight.wifiAvailability.toString()
                            : t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('flights.attributes.onTimePercentage')}
                        </div>
                        <div className="common-show__infos--value">
                          {flight.onTimePercentage || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('flights.attributes.airline')}
                        </div>
                        <div className="common-show__infos--value">{flight.airline.name}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return null;
        }}
      </Query>
    </div>
  );
};

export default Flight;

import React from 'react';
import {
  GET_ANCILLARY_SERVICES,
  GET_CURSORS_FOR_ANCILLARY_SERVICES,
} from '../../graphql/queries/ancillaryService';
import { DELETE_ANCILLARY_SERVICE } from '../../graphql/mutations/ancillaryService';
import List from '../common/List';

const AncillaryServiceList = () => {
  return (
    <List
      getItems={GET_ANCILLARY_SERVICES}
      getCursorsForItems={GET_CURSORS_FOR_ANCILLARY_SERVICES}
      deleteItem={DELETE_ANCILLARY_SERVICE}
      listAttributes={['ssrCode', 'description', 'serviceType', 'fullDescription']}
      itemType="ancillaryServices"
      modelName="AncillaryService"
      title="nav.ancillaryServices"
      linkTo="/admin/ancillary_services/new"
      buttonLabel="shared.addNew"
    />
  );
};

export default AncillaryServiceList;

const availableLanguageOptions = [
  {
    label: 'AF (AFRIKAANS)',
    value: 'af',
  },
  {
    label: 'DE (SWITZERLAND)',
    value: 'de',
  },
  {
    label: 'EN (GB)',
    value: 'en',
  },
  {
    label: 'MS (BAHASA MELAYU)',
    value: 'ms',
  },
  {
    label: 'TH (ไทย)',
    value: 'th',
  },
];

export default availableLanguageOptions;

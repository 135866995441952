import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_FAQS = gql`
  query faqs($first: Int, $after: String, $query: String) {
    faqs(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_FAQS = gql`
  query faqs($first: Int, $after: String, $query: String) {
    faqs(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          title
          order
          thumbsUp
          thumbsDown
          parentId
          nestedFaqs {
            id
            title
            order
          }
        }
      }
    }
  }
`;

export const getFaqs = after => all(GET_FAQS, after);

export const GET_FAQ = gql`
  query faq($id: ID) {
    faq(id: $id) {
      id
      title
      description
      order
      thumbsUp
      thumbsDown
      updatedAt
      updatedBy {
        name
        id
      }
      hashtags {
        id
        name
      }
      nestedFaqs {
        id
        title
        order
        thumbsUp
        thumbsDown
        parentId
        nestedFaqs {
          id
          title
        }
      }
      parentId
    }
  }
`;

export const getFaq = id => one(GET_FAQ, id);

export const GET_FAQ_CATEGORIES = gql`
  query faqCategories($id: ID) {
    faqCategories(id: $id) {
      label
      value
    }
  }
`;

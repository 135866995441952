import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { debounce } from 'lodash';

import Button from '@bit/mno-wtag.welltravel.button';
import Input from '@bit/mno-wtag.welltravel.input';
import SelectBox from '@bit/mno-wtag.welltravel.select-box';
import MultiSelectBox from '../rclComponents/selectBox';

import { GET_TRAVEL_NEWS, GET_TRAVEL_NEWS_PLATFORMS } from '../../graphql/queries/travelNews';
import { GET_HASHTAGS } from '../../graphql/queries/hashtag';
import { GET_PLACES } from '../../graphql/queries/place';
import { GET_COUNTRIES } from '../../graphql/queries/country';
import { getTravelNews } from '../../graphql/queries/travelNews';
import { CREATE_TRAVEL_NEWS, EDIT_TRAVEL_NEWS } from '../../graphql/mutations/travelNews';

import TextEditor from '../common/TextEditor';
import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';

import clearCache from '../../helpers/clearCache';
import returnEmptyStringWhenNull from '../../helpers/returnEmptyStringWhenNull';
import imageUrl from '../../helpers/imageUrl';
import FileUpload from '../common/FileUpload';
import NoResultsFound from '../common/NoResultsFound';

const TravelNewsForm = props => {
  const { t } = useTranslation();
  let { id } = useParams();
  const isNewRecord = !id;

  const [createTravelNews, callbackDataOnCreate] = useMutation(CREATE_TRAVEL_NEWS, {
    update: cache => clearCache(cache, /TravelNews/),
  });

  const [editTravelNews, callbackDataOnEdit] = useMutation(EDIT_TRAVEL_NEWS);

  const travelNewsFromId = useQuery(GET_TRAVEL_NEWS, {
    variables: { id },
    skip: isNewRecord,
  });

  const [placeQuery, setPlaceQuery] = useState('');
  const [skipPlaceSearch, setSkipPlaceSearch] = useState(true);
  const [places, setPlaces] = useState(null);

  const [title, setTitle] = useState('');
  const [teaser, setTeaser] = useState('');
  const [source, setSource] = useState('');
  const [externalLink, setExternalLink] = useState('');
  const [author, setAuthor] = useState('');
  const [platform, setPlatform] = useState('');
  const [content, setContent] = useState('');
  const [placeId, setPlaceId] = useState('');
  const [countryId, setCountryId] = useState('');
  const [hashtags, setHashtags] = useState([]);
  const [image, setImage] = useState(null);
  const [previousImage, setPreviousImage] = useState(null);

  const { loading: platformListLoading, data: platformListData } = useQuery(
    GET_TRAVEL_NEWS_PLATFORMS,
  );
  const { loading: countryListLoading, data: countryList } = useQuery(GET_COUNTRIES);
  const { loading: hashtagListLoading, data: hashtagList } = useQuery(GET_HASHTAGS);
  const { loading: placeListLoading, data: placeList } = useQuery(GET_PLACES, {
    variables: { query: placeQuery, first: 20 },
    skip: skipPlaceSearch,
  });

  const callSearchParam = useRef(
    debounce(async inputParam => {
      setPlaceQuery(inputParam);
      setSkipPlaceSearch(false);
    }, 1000),
  );

  const onTitleChange = event => {
    setTitle(event.target.value);
  };

  const onTeaserChange = event => {
    setTeaser(event.target.value);
  };

  const onSourceChange = event => {
    setSource(event.target.value);
  };

  const onExternalLinkChange = event => {
    setExternalLink(event.target.value);
  };

  const onAuthorChange = event => {
    setAuthor(event.target.value);
  };

  const onPlatformChange = value => {
    setPlatform(value);
  };

  const onContentChange = value => {
    setContent(value);
  };

  const onPlaceIdChange = value => {
    setPlaceId(value);
  };

  const onCountryIdChange = value => {
    setCountryId(value);
  };

  const onHashtagsChange = value => {
    setHashtags(value);
  };

  const onPlaceSearch = value => {
    if (value.length < 3) {
      return;
    }

    callSearchParam.current(value);
  };

  const onImageFileUpload = files => {
    setImage(files[0]);
  };

  useEffect(() => {
    if (travelNewsFromId && travelNewsFromId.data && !travelNewsFromId.loading) {
      const travelNews = travelNewsFromId.data.travelNewsOne;

      setTitle(travelNews.title);
      setTeaser(travelNews.teaser);
      setSource(travelNews.source);
      setExternalLink(travelNews.externalLink);
      setAuthor(travelNews.author);
      setPlatform(returnEmptyStringWhenNull(travelNews.platform));
      setContent(travelNews.content);
      setPlaceId(travelNews.place && travelNews.place.id);
      setCountryId(travelNews.country && travelNews.country.id);
      setHashtags(
        travelNews.hashtags.map(hashtag => ({
          value: hashtag.id,
          label: hashtag.name,
        })),
      );
      setPreviousImage(travelNews.imageUrls.large);
    }

    if (!placeListLoading && placeList && placeList.places) {
      setPlaces(placeList.places);
    }
  }, [travelNewsFromId, placeList, placeListLoading]);

  const submitToBackEnd = debounce(() => {
    props.resetError();
    const hashtagIds = hashtags && hashtags.map(hashtag => hashtag.value);
    if (isNewRecord) {
      createTravelNews({
        variables: {
          title,
          teaser,
          source,
          externalLink,
          author,
          platform,
          content,
          placeId,
          hashtagIds,
          countryId,
          image,
        },
      })
        .then(({ data }) => {
          props.history.push(`/admin/travel_news/${data.createTravelNews.id}`);
        })
        .catch(() => props.onError());
    } else {
      editTravelNews({
        variables: {
          id,
          title,
          teaser,
          source,
          externalLink,
          author,
          platform,
          content,
          placeId,
          hashtagIds,
          countryId,
          image,
        },
        refetchQueries: [getTravelNews(id)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/travel_news/${data.editTravelNews.id}`);
        })
        .catch(() => props.onError());
    }
  }, 500);

  let platformOptions = [];
  if (!platformListLoading) {
    platformOptions = platformListData.travelNewsPlatforms.map(platform => ({
      value: platform.value,
      label: t(`categories.${platform.value}`),
    }));
  }

  let placeOptions = [];
  if (!places) {
    if (!isNewRecord && !travelNewsFromId.loading) {
      const placeData = travelNewsFromId.data.travelNewsOne.place;

      if (placeData) {
        placeOptions = [
          {
            value: placeData.id,
            label: placeData.name,
          },
        ];
      }
    }
  } else {
    placeOptions =
      places &&
      places.edges.map(place => ({
        value: place.node.id,
        label: place.node.name,
      }));
  }

  let countryOptions = [];
  if (!countryListLoading) {
    countryOptions = countryList.countries.edges.map(country => ({
      value: country.node.id,
      label: country.node.name,
    }));
  }

  let hashtagOptions = [];
  if (!hashtagListLoading) {
    hashtagOptions = hashtagList.hashtags.edges.map(hashtag => ({
      value: hashtag.node.id,
      label: hashtag.node.name,
    }));
  }

  return (
    <div className="common-form">
      <div className="common-form__title">
        {isNewRecord ?
          t('shared.new', { entityName: t('travelNews.show.title') })
          : t('shared.editTitle', { entityName: t('travelNews.show.title') })}
      </div>
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit, callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-lg-8 col-md-10 col-sm-10 col-xs-10">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-bleed-y">
              <div className="common-form__field travel-news-form__title">
                <Input
                  label={t('travelNews.attributes.title')}
                  value={title}
                  onChange={onTitleChange}
                />
              </div>
              <div className="common-form__field travel-news-form__teaser">
                <Input
                  label={t('travelNews.attributes.teaser')}
                  value={teaser}
                  onChange={onTeaserChange}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field travel-news-form__source">
                <Input
                  label={t('travelNews.attributes.source')}
                  value={source}
                  onChange={onSourceChange}
                />
              </div>
              <div className="common-form__field travel-news-form__author">
                <Input
                  label={t('travelNews.attributes.author')}
                  value={author}
                  onChange={onAuthorChange}
                />
              </div>
              <div className="common-form__field travel-news-form__platform">
                <SelectBox
                  label={t('travelNews.attributes.platform')}
                  value={platform}
                  options={platformOptions}
                  onChange={onPlatformChange}
                  renderNoResultsFound={() => <NoResultsFound />}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field travel-news-form__externalLink">
                <Input
                  label={t('travelNews.attributes.externalLink')}
                  value={externalLink}
                  onChange={onExternalLinkChange}
                />
              </div>
              <div className="common-form__field travel-news-form__city">
                <SelectBox
                  label={t('travelNews.attributes.city')}
                  value={placeId}
                  options={placeOptions}
                  onChange={onPlaceIdChange}
                  onSearchChange={value => onPlaceSearch(value)}
                  renderNoResultsFound={() => <NoResultsFound />}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field travel-news-form__hashtags">
                <MultiSelectBox
                  isMulti={true}
                  label={t('travelNews.attributes.hashtags')}
                  value={hashtags}
                  options={hashtagOptions}
                  onChange={onHashtagsChange}
                />
              </div>
              <div className="common-form__field travel-news-form__country">
                <SelectBox
                  label={t('travelNews.attributes.country')}
                  value={countryId}
                  options={countryOptions}
                  onChange={onCountryIdChange}
                  renderNoResultsFound={() => <NoResultsFound />}
                />
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-bleed-y">
              <div className="common-form__field travel-news-form__content">
                <h2>{t('travelNews.attributes.content')}</h2>
                <TextEditor customModule={true} value={content} onChange={onContentChange} />
              </div>
              <div className="common-form__submit-button">
                <Button
                  label={isNewRecord ? t('shared.create') : t('shared.update')}
                  onClick={submitToBackEnd}
                />
              </div>
            </div>
          </div>
          <div className="col-4 col-bleed-y">
            <h2>{t('travelNews.attributes.image')}</h2>
            {previousImage && (
              <div className="common-form__previous-image">
                <img src={imageUrl(previousImage)} alt="preview" />
              </div>
            )}
            <FileUpload onFileUpload={onImageFileUpload} multiple={false} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithErrorHandler(TravelNewsForm);

const renderTranslations = (translations, defaultText, attr) => {
  if (translations.length > 0) {
    return translations
      .map(translation => `${translation[attr]} (${translation.locale})`)
      .join(', ');
  }
  return defaultText;
};

export default renderTranslations;

import gql from 'graphql-tag';

export const CREATE_GLOSSARY = gql`
  mutation createGlossary(
    $term: String!
    $category: String!
    $subCategory: String
    $keywordIds: [String!]
    $definition: String
  ) {
    createGlossary(
      term: $term
      category: $category
      subCategory: $subCategory
      keywordIds: $keywordIds
      definition: $definition
    ) {
      id
      term
      category
      subCategory
      keywords {
        id
        name
      }
      definition
    }
  }
`;

export const EDIT_GLOSSARY = gql`
  mutation editGlossary(
    $id: ID!
    $term: String!
    $category: String!
    $subCategory: String
    $keywordIds: [String!]
    $definition: String
  ) {
    editGlossary(
      id: $id
      term: $term
      category: $category
      subCategory: $subCategory
      keywordIds: $keywordIds
      definition: $definition
    ) {
      id
      term
      category
      subCategory
      keywords {
        id
        name
      }
      definition
    }
  }
`;

export const DELETE_GLOSSARY = gql`
  mutation deleteGlossary($id: ID!) {
    deleteGlossary(id: $id) {
      id
    }
  }
`;

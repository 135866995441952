import gql from 'graphql-tag';

export const CREATE_HOTEL_COMBINED_DATABASE = gql`
  mutation createHotelCombinedDatabase(
    $name: String!
    $welltravelCodes: JSON!
    $mainSource: String
    $iso31661: String
    $iso31662: String
    $imageUrls: JSON
    $description: String
    $latitude: Float
    $longitude: Float
    $additionalContents: JSON
    $supplierCodes: JSON
    $proximityPercentage: Float
  ) {
    createHotelCombinedDatabase(
      name: $name
      welltravelCodes: $welltravelCodes
      mainSource: $mainSource
      iso31661: $iso31661
      iso31662: $iso31662
      imageUrls: $imageUrls
      description: $description
      latitude: $latitude
      longitude: $longitude
      additionalContents: $additionalContents
      supplierCodes: $supplierCodes
      proximityPercentage: $proximityPercentage
    ) {
      id
      name
    }
  }
`;

export const EDIT_HOTEL_COMBINED_DATABASE = gql`
  mutation editHotelCombinedDatabase(
    $id: ID!
    $name: String
    $alternateNames: JSON
    $welltravelCodes: JSON
    $mainSource: String
    $iso31661: String
    $iso31662: String
    $imageUrls: JSON
    $description: String
    $uniqueId: String
    $latitude: Float
    $longitude: Float
    $additionalContents: JSON
    $supplierCodes: JSON
    $otherSources: JSON
    $multipleSource: Boolean
    $proximityPercentage: Float
  ) {
    editHotelCombinedDatabase(
      id: $id
      name: $name
      alternateNames: $alternateNames
      welltravelCodes: $welltravelCodes
      mainSource: $mainSource
      iso31661: $iso31661
      iso31662: $iso31662
      imageUrls: $imageUrls
      description: $description
      uniqueId: $uniqueId
      latitude: $latitude
      longitude: $longitude
      additionalContents: $additionalContents
      supplierCodes: $supplierCodes
      otherSources: $otherSources
      multipleSource: $multipleSource
      proximityPercentage: $proximityPercentage
    ) {
      id
      name
      alternateNames
      welltravelCodes
      mainSource
      iso31661
      iso31662
      imageUrls
      description
      uniqueId
      latitude
      longitude
      additionalContents
      supplierCodes
      otherSources
      multipleSource
      proximityPercentage
    }
  }
`;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@bit/mno-wtag.welltravel.button';
import Input from '@bit/mno-wtag.welltravel.input';
import TextArea from '@bit/mno-wtag.welltravel.text-area';

import BaseModal from '../rclComponents/BaseModal';
import Radio from '../rclComponents/Radio';
import WrappedMap from '../common/WrappedMap';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { GET_GOOGLE_MAPS_PUBlIC_API_KEY } from '../../graphql/queries/google_maps_public_api_key';
import { GET_HOTEL_COMBINED_DATABASE_BY_UNIQUE_ID } from '../../graphql/queries/hotelCombinedDatabase';
import RawJSON from '../common/RawJSON';
import CombineHotelView from './CombineHotelView';

const HotelCombineEditor = props => {
  const { t } = useTranslation();
  const { data } = useQuery(GET_GOOGLE_MAPS_PUBlIC_API_KEY);

  const hotelCombinedDatabase = useQuery(GET_HOTEL_COMBINED_DATABASE_BY_UNIQUE_ID, {
    variables: { uniqueId: props.uniqueId },
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [customizeName, setCustomizeName] = useState(false);
  const [customizeIso31661, setCustomizeIso31661] = useState(false);
  const [customizeIso31662, setCustomizeIso31662] = useState(false);
  const [customizeDescription, setCustomizeDescription] = useState(false);
  const [descriptionSelected, setDescriptionSelected] = useState(false);
  const [customizeAdditionalContents, setCustomizeAdditionalContents] = useState(false);
  const [additionalContentsSelected, setAdditionalContentsSelected] = useState(false);
  const rawHotel = props.rawHotel;

  const rawHotelName = rawHotel.name;
  const rawHotelIso31661 = rawHotel.iso31661;
  const rawHotelIso31662 = rawHotel.iso31662;
  const rawHotelDescription = rawHotel.description;
  const rawHotelAdditionalContents = rawHotel.additionalContents;
  const rawHotelSupplierCodes = rawHotel.supplierCodes;

  const [name, setName] = useState('');
  const [iso31661, setIso31661] = useState('');
  const [iso31662, setIso31662] = useState('');
  const [description, setDescription] = useState('');
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [additionalContents, setAdditionalContents] = useState({});
  const [supplierCodes, setSupplierCodes] = useState({});
  const [welltravelCodes, setWelltravelCodes] = useState([]);

  const [combinedHotelId, setCombinedHotelId] = useState('');
  const [combinedHotelName, setCombinedHotelName] = useState('');
  const [combinedHotelIso31661, setCombinedHotelIso31661] = useState('');
  const [combinedHotelIso31662, setCombinedHotelIso31662] = useState('');
  const [combinedHotelDescription, setCombinedHotelDescription] = useState('');
  const [combinedHotelAdditionalContents, setCombinedHotelAdditionalContents] = useState('');

  const setLatLong = value => {
    setLatitude(value.lat);
    setLongitude(value.lng);
  };

  const toggleName = value => {
    setName(value);
    setCustomizeName(false);
  };

  const toggleIso31661 = value => {
    setIso31661(value);
    setCustomizeIso31661(false);
  };

  const toggleIso31662 = value => {
    setIso31662(value);
    setCustomizeIso31662(false);
  };

  const toggleDescription = value => {
    setDescription(value);
    setCustomizeDescription(false);
    setDescriptionSelected(true);
  };

  const toggleAdditionalContents = value => {
    setAdditionalContents(value);
    setCustomizeAdditionalContents(false);
    setAdditionalContentsSelected(true);
  };

  useEffect(() => {
    if (hotelCombinedDatabase && hotelCombinedDatabase.data && !hotelCombinedDatabase.loading) {
      const hotelCombinedDatabaseData = hotelCombinedDatabase.data.hotelCombinedDatabase;
      setCombinedHotelId(hotelCombinedDatabaseData.id);
      setCombinedHotelName(hotelCombinedDatabaseData.name);
      setName(hotelCombinedDatabaseData.name);
      setCombinedHotelIso31661(hotelCombinedDatabaseData.iso31661);
      setIso31661(hotelCombinedDatabaseData.iso31661);
      setCombinedHotelIso31662(hotelCombinedDatabaseData.iso31662);
      setIso31662(hotelCombinedDatabaseData.iso31662);
      setCombinedHotelDescription(hotelCombinedDatabaseData.description);
      setDescription(hotelCombinedDatabaseData.description);
      setLatitude(hotelCombinedDatabaseData.latitude);
      setLongitude(hotelCombinedDatabaseData.longitude);
      setWelltravelCodes(hotelCombinedDatabaseData.welltravelCodes);
      setSupplierCodes({ ...rawHotelSupplierCodes, ...hotelCombinedDatabaseData.supplierCodes });
      setCombinedHotelAdditionalContents(hotelCombinedDatabaseData.additionalContents);
      setAdditionalContents(hotelCombinedDatabaseData.additionalContents);
    }
  }, [hotelCombinedDatabase, rawHotelSupplierCodes]);

  const renderMap = data && data.googleMapsPublicApiKey && latitude && longitude;

  return (
    <BaseModal
      linkText={t('humanVerifiedHotels.buttons.combineWithBestMatch')}
      linkClassName="button"
      defaultPadding={true}
      header={t('humanVerifiedHotels.buttons.combineWithBestMatch')}
      open={modalOpen}
      onModalClose={() => {
        setModalOpen(false);
      }}
      onModalOpen={() => {
        setModalOpen(true);
      }}
      size="huge"
    >
      <div className="hotel-combine-editor-content">
        <div className="hotel-combine-editor-content__hints">
          <p>{t('hotelCombinedDatabases.hints.selectToModify')}</p>
        </div>
        <div className="hotel-combine-editor-content hotel-attributes">
          <div className="hotel-combine-editor-content hotel-attributes-attribute">
            <div className="hotel-combine-editor-content hotel-attributes-attribute__name">
              {t('hotelCombinedDatabases.attributes.name')}
            </div>
            <div className="hotel-combine-editor-content hotel-attributes-attribute__value">
              <div className="hotel-combine-editor-content hotel-attributes-attribute__value-selector">
                <Radio
                  label={rawHotelName || t('shared.noInformation')}
                  onChange={() => toggleName(rawHotelName)}
                  name="name"
                  disabled={!rawHotelName}
                />
                <Radio
                  label={`${combinedHotelName || t('shared.noInformation')}(${t(
                    'humanVerifiedHotels.show.bestMatch',
                  )})`}
                  onChange={() => toggleName(combinedHotelName)}
                  name="name"
                  disabled={!combinedHotelName}
                />
                <Radio
                  label={t('hotelCombinedDatabases.show.customizeOrAddNew')}
                  onChange={() => setCustomizeName(true)}
                  name="name"
                />
              </div>
              {customizeName && (
                <Input
                  label={t('hotelCombinedDatabases.show.customizeOrAddNew')}
                  value={name}
                  onChange={event => setName(event.target.value)}
                />
              )}
            </div>
          </div>

          <div className="hotel-combine-editor-content hotel-attributes-attribute">
            <div className="hotel-combine-editor-content hotel-attributes-attribute__name">
              {t('hotelCombinedDatabases.attributes.iso31661')}
            </div>
            <div className="hotel-combine-editor-content hotel-attributes-attribute__value">
              <div className="hotel-combine-editor-content hotel-attributes-attribute__value-selector">
                <Radio
                  label={rawHotelIso31661 || t('shared.noInformation')}
                  onChange={() => toggleIso31661(rawHotelIso31661)}
                  name="iso31661"
                  disabled={!rawHotelIso31661}
                />
                <Radio
                  label={`${combinedHotelIso31661 || t('shared.noInformation')}(${t(
                    'humanVerifiedHotels.show.bestMatch',
                  )})`}
                  onChange={() => toggleIso31661(combinedHotelIso31661)}
                  name="iso31661"
                  disabled={!combinedHotelIso31661}
                />
                <Radio
                  label={t('hotelCombinedDatabases.show.customizeOrAddNew')}
                  onChange={() => setCustomizeIso31661(true)}
                  name="iso31661"
                />
              </div>
              {customizeIso31661 && (
                <Input
                  label={t('hotelCombinedDatabases.show.customizeOrAddNew')}
                  value={iso31661}
                  onChange={event => setIso31661(event.target.value)}
                />
              )}
            </div>
          </div>

          <div className="hotel-combine-editor-content hotel-attributes-attribute">
            <div className="hotel-combine-editor-content hotel-attributes-attribute__name">
              {t('hotelCombinedDatabases.attributes.iso31662')}
            </div>
            <div className="hotel-combine-editor-content hotel-attributes-attribute__value">
              <div className="hotel-combine-editor-content hotel-attributes-attribute__value-selector">
                <Radio
                  label={rawHotelIso31662 || t('shared.noInformation')}
                  onChange={() => toggleIso31662(rawHotelIso31662)}
                  name="iso31662"
                  disabled={!rawHotelIso31662}
                />
                <Radio
                  label={`${combinedHotelIso31662 || t('shared.noInformation')}(${t(
                    'humanVerifiedHotels.show.bestMatch',
                  )})`}
                  onChange={() => toggleIso31662(combinedHotelIso31662)}
                  name="iso31662"
                  disabled={!combinedHotelIso31662}
                />
                <Radio
                  label={t('hotelCombinedDatabases.show.customizeOrAddNew')}
                  onChange={() => setCustomizeIso31662(true)}
                  name="iso31662"
                />
              </div>
              {customizeIso31662 && (
                <Input
                  label={t('hotelCombinedDatabases.show.customizeOrAddNew')}
                  value={iso31662}
                  onChange={event => setIso31662(event.target.value)}
                />
              )}
            </div>
          </div>

          <div className="hotel-combine-editor-content hotel-attributes-attribute">
            <div className="hotel-combine-editor-content hotel-attributes-attribute__name">
              {t('hotelCombinedDatabases.show.location')}
            </div>
            <div className="hotel-combine-editor-content hotel-attributes-attribute__value">
              <Input
                type="number"
                label={t('hotelCombinedDatabases.attributes.latitude')}
                value={latitude}
                onChange={event => setLatitude(event.target.value)}
              />
              <Input
                type="number"
                label={t('hotelCombinedDatabases.attributes.longitude')}
                value={longitude}
                onChange={event => setLongitude(event.target.value)}
              />
              <div className="hotel-combine-editor-content hotel-attributes-attribute__value-map">
                {renderMap && (
                  <WrappedMap
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${data.googleMapsPublicApiKey}`}
                    latLong={{ lat: latitude, lng: longitude }}
                    infoWindowContent={name}
                    getLatLong={value => {
                      setLatLong(value);
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="hotel-combine-editor-content hotel-attributes-attribute">
            <div className="hotel-combine-editor-content hotel-attributes-attribute__name">
              {t('hotelCombinedDatabases.attributes.description')}
            </div>
            <div className="hotel-combine-editor-content hotel-attributes-attribute__value">
              <div className="hotel-combine-editor-content hotel-attributes-attribute__value-selector">
                <Radio
                  label={t('rawHotels.show.title')}
                  onChange={() => toggleDescription(rawHotelDescription)}
                  name="description"
                  disabled={!rawHotelDescription}
                />
                <Radio
                  label={t('hotelCombinedDatabases.show.title')}
                  onChange={() => toggleDescription(combinedHotelDescription)}
                  name="description"
                  disabled={!combinedHotelDescription}
                />
                <Radio
                  label={t('hotelCombinedDatabases.show.customizeOrAddNew')}
                  onChange={() => setCustomizeDescription(true)}
                  name="description"
                />
              </div>

              {customizeDescription && (
                <TextArea
                  label={t('hotelCombinedDatabases.show.customizeOrAddNew')}
                  value={description}
                  onChange={event => setDescription(event.target.value)}
                />
              )}
              {!customizeDescription && descriptionSelected && (
                <div className="hotel-combine-editor-content hotel-attributes-attribute__value-description">
                  {description}
                </div>
              )}
            </div>
          </div>

          <div className="hotel-combine-editor-content hotel-attributes-attribute">
            <div className="hotel-combine-editor-content hotel-attributes-attribute__name">
              {t('hotelCombinedDatabases.attributes.additionalContents')}
            </div>
            <div className="hotel-combine-editor-content hotel-attributes-attribute__value">
              <div className="hotel-combine-editor-content hotel-attributes-attribute__value-selector">
                <Radio
                  label={t('rawHotels.show.title')}
                  onChange={() => toggleAdditionalContents(rawHotelAdditionalContents)}
                  name="additionalContents"
                  disabled={!rawHotelAdditionalContents}
                />
                <Radio
                  label={t('hotelCombinedDatabases.show.title')}
                  onChange={() => toggleAdditionalContents(combinedHotelAdditionalContents)}
                  name="additionalContents"
                  disabled={!combinedHotelAdditionalContents}
                />
                <Radio
                  label={t('hotelCombinedDatabases.show.customizeOrAddNew')}
                  onChange={() => setCustomizeAdditionalContents(true)}
                  name="additionalContents"
                />
              </div>

              {customizeAdditionalContents && (
                <TextArea
                  label={t('hotelCombinedDatabases.show.customizeOrAddNew')}
                  value={JSON.stringify(additionalContents, undefined, 4)}
                  onChange={event => setAdditionalContents(event.target.value)}
                />
              )}
              {!customizeAdditionalContents && additionalContentsSelected && (
                <div className="hotel-combine-editor-content hotel-attributes-attribute__value-description-json">
                  <RawJSON data={additionalContents} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="hotel-combine-editor-content__submit">
          <CombineHotelView
            combinedHotelId={combinedHotelId}
            name={name}
            closeModal={() => setModalOpen(false)}
            iso31661={iso31661}
            iso31662={iso31662}
            description={description}
            latitude={latitude}
            longitude={longitude}
            welltravelCodes={welltravelCodes}
            additionalContents={additionalContents}
            supplierCodes={supplierCodes}
            currentCursor={props.currentCursor}
            humanVerifiedHotelId={props.humanVerifiedHotelId}
            refetchTotalVerifiableHotels={props.refetchTotalVerifiableHotels}
          />
        </div>
        <div className="hotel-combine-editor-content__cancel">
          <Button
            type={'danger'}
            label={t('shared.cancel')}
            onClick={() => {
              setModalOpen(false);
            }}
          />
        </div>
      </div>
    </BaseModal>
  );
};

HotelCombineEditor.propTypes = {
  humanVerifiedHotelId: PropTypes.string,
  currentCursor: PropTypes.string,
  searchParam: PropTypes.string,
  rawHotel: PropTypes.object.isRequired,
  uniqueId: PropTypes.string,
  refetchTotalVerifiableHotels: PropTypes.func.isRequired,
};

export default HotelCombineEditor;

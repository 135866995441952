import React from 'react';
import { GET_AIRCRAFT_TYPES, GET_CURSORS_FOR_AIRCRAFT_TYPES } from '../../graphql/queries/aircraftType';
import { DELETE_AIRCRAFT_TYPE } from '../../graphql/mutations/aircraftType';
import List from '../common/List';

const AircraftTypeList = () => {
  return (
    <List
      getItems={GET_AIRCRAFT_TYPES}
      getCursorsForItems={GET_CURSORS_FOR_AIRCRAFT_TYPES}
      deleteItem={DELETE_AIRCRAFT_TYPE}
      listAttributes={['name', 'code']}
      itemType="aircraftTypes"
      modelName="AircraftType"
      title="nav.aircraftTypes"
      linkTo="/admin/aircraft_types/new"
      buttonLabel="shared.addNew"
    />
  );
};

export default AircraftTypeList;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';

import Button from '@bit/mno-wtag.welltravel.button';
import Input from '@bit/mno-wtag.welltravel.input';
import SelectBox from '@bit/mno-wtag.welltravel.select-box';
import MultiSelectBox from '../rclComponents/selectBox';
import TextEditor from '../common/TextEditor';
import {
  GET_PLATFORM_FEATURE,
  GET_PLATFORM_FEATURE_CATEGORIES,
  GET_PLATFORM_FEATURES,
  getPlatformFeature,
} from '../../graphql/queries/platformFeature';
import { GET_HASHTAGS } from '../../graphql/queries/hashtag';
import {
  CREATE_PLATFORM_FEATURE,
  EDIT_PLATFORM_FEATURE,
} from '../../graphql/mutations/platformFeature';
import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import clearCache from '../../helpers/clearCache';
import PropTypes from 'prop-types';
import { all } from '../../graphql/queries/prepareQueryParams';
import NoResultsFound from '../common/NoResultsFound';
import parseQuillHTML from '../../helpers/quillhtmlparser';
import { debounce } from 'lodash';

const PlatformFeatureForm = props => {
  const { t } = useTranslation();
  let { id } = useParams();
  const isNewRecord = !id;

  const userId = props.userId;

  const [createPlatformFeature, callbackDataOnCreate] = useMutation(CREATE_PLATFORM_FEATURE, {
    update: cache => clearCache(cache, /PlatformFeature/),
  });

  const [editPlatformFeature, callbackDataOnEdit] = useMutation(EDIT_PLATFORM_FEATURE);

  const platformFeatureFromId = useQuery(GET_PLATFORM_FEATURE, {
    variables: { id },
    skip: isNewRecord,
  });

  const { loading: platformFeatureListLoading, data: platformFeatureListData } = useQuery(
    GET_PLATFORM_FEATURE_CATEGORIES,
    {
      variables: { id },
    },
  );

  const { loading: hashtagListLoading, data: hashtagList } = useQuery(GET_HASHTAGS);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [parentId, setParentId] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [updatedBy, setUpdatedBy] = useState('');
  const [hashtags, setHashtags] = useState([]);

  const onTitleChange = event => {
    setTitle(event.target.value);
  };

  const onPlatformFeatureLabelChange = value => {
    setParentId(value);
  };

  const onHashtagsChange = value => {
    setHashtags(value);
  };

  const onDescriptionChange = value => {
    setDescription(value);
  };

  useEffect(() => {
    if (platformFeatureFromId && platformFeatureFromId.data && !platformFeatureFromId.loading) {
      const platformFeatureData = platformFeatureFromId.data.platformFeature;
      setTitle(platformFeatureData.title);
      setHashtags(
        platformFeatureFromId.data.platformFeature.hashtags.map(hashtag => ({
          value: hashtag.id,
          label: hashtag.name,
        })),
      );
      setDescription(platformFeatureData.description);
      setParentId(platformFeatureData.parentId);
      setUpdatedAt(platformFeatureData.updatedAt);
      setUpdatedBy(platformFeatureData.updatedBy && platformFeatureData.updatedBy.name);
    }
  }, [platformFeatureFromId]);

  const submitToBackEnd = debounce(() => {
    props.resetError();
    
    const parsedDescription = parseQuillHTML(description);
    const hashtagIds = hashtags && hashtags.map(hashtag => hashtag.value);
    if (isNewRecord) {
      createPlatformFeature({
        variables: { title, description: parsedDescription, parentId, userId, hashtagIds },
        refetchQueries: [all(GET_PLATFORM_FEATURE_CATEGORIES)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/platform_features/${data.createPlatformFeature.id}`);
        })
        .catch(() => props.onError());
    } else if (parentId) {
      editPlatformFeature({
        variables: { id, title, description: parsedDescription, parentId, hashtagIds, userId },
        refetchQueries: [
          all(GET_PLATFORM_FEATURES, null, ''),
          getPlatformFeature(parentId),
          getPlatformFeature(id),
        ],
      })
        .then(({ data }) => {
          props.history.push(`/admin/platform_features/${data.editPlatformFeature.id}`);
        })
        .catch(() => props.onError());
    } else {
      editPlatformFeature({
        variables: { id, title, description: parsedDescription, parentId, hashtagIds, userId },
        refetchQueries: [all(GET_PLATFORM_FEATURES, null, ''), getPlatformFeature(id)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/platform_features/${data.editPlatformFeature.id}`);
        })
        .catch(() => props.onError());
    }
  }, 500);

  let platformFeatureLabelOptions = [];
  if (!platformFeatureListLoading) {
    platformFeatureLabelOptions = platformFeatureListData.platformFeatureCategories.map(
      feature => ({
        value: feature.value,
        label: feature.label,
      }),
    );
  }

  let hashtagOptions = [];
  if (!hashtagListLoading) {
    hashtagOptions = hashtagList.hashtags.edges.map(hashtag => ({
      value: hashtag.node.id,
      label: hashtag.node.name,
    }));
  }

  return (
    <div className="common-form">
      <div className="common-form__title">
        {isNewRecord ?
          t('shared.new', { entityName: t('platformFeatures.show.title') })
          : t('shared.editTitle', { entityName: t('platformFeatures.show.title') })}
      </div>
      {!isNewRecord && (
        <div className="common-form__header">
          {t('platformFeatures.attributes.lastModified')}: {updatedAt}({updatedBy})
        </div>
      )}
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit, callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-lg-8 col-md-10 col-sm-10 col-xs-10">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-bleed-y">
            <div className="common-form__field platform-feature-form__title">
              <Input
                label={t('platformFeatures.attributes.title')}
                value={title}
                onChange={onTitleChange}
              />
            </div>
            <div className="common-form__field platform-feature-form__platform-feature-label">
              <SelectBox
                width="large"
                label={t('platformFeatures.attributes.label')}
                value={parentId}
                options={platformFeatureLabelOptions}
                onChange={onPlatformFeatureLabelChange}
                renderNoResultsFound={() => <NoResultsFound />}
              />
            </div>
            <div className="common-form__field platform-feature-form__description">
              <h2>{t('platformFeatures.attributes.description')}</h2>
              <TextEditor customModule={true} value={description} onChange={onDescriptionChange} />
            </div>
            <div className="common-form__field-multi-select-box platform-feature-form__hashtags">
              <MultiSelectBox
                width="large"
                isMulti={true}
                label={t('platformFeatures.attributes.keywords')}
                value={hashtags}
                options={hashtagOptions}
                onChange={onHashtagsChange}
              />
            </div>
            <div className="common-form__submit-button">
              <Button
                label={isNewRecord ? t('shared.create') : t('shared.update')}
                onClick={submitToBackEnd}
              />
            </div>
          </div>
          </div>
        </div>
      </form>
    </div>
  );
};

PlatformFeatureForm.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default WithErrorHandler(PlatformFeatureForm);

import gql from 'graphql-tag';

export const CREATE_FAQ = gql`
  mutation createFaq(
    $title: String!
    $description: String
    $userId: ID!
    $parentId: ID
    $hashtagIds: [String!]
  ) {
    createFaq(
      title: $title
      description: $description
      userId: $userId
      parentId: $parentId
      hashtagIds: $hashtagIds
    ) {
      id
      title
      description
      order
      thumbsUp
      thumbsDown
      updatedBy {
        name
        id
      }
      hashtags {
        id
        name
      }
      parentId
    }
  }
`;

export const EDIT_FAQ = gql`
  mutation editFaq(
    $id: ID!
    $title: String!
    $description: String
    $thumbsUp: Int
    $thumbsDown: Int
    $order: Int
    $userId: ID!
    $parentId: ID
    $hashtagIds: [String!]
  ) {
    editFaq(
      id: $id
      title: $title
      description: $description
      thumbsUp: $thumbsUp
      thumbsDown: $thumbsDown
      order: $order
      userId: $userId
      parentId: $parentId
      hashtagIds: $hashtagIds
    ) {
      id
      title
      description
      order
      thumbsUp
      thumbsDown
      updatedBy {
        name
        id
      }
      hashtags {
        id
        name
      }
      parentId
    }
  }
`;

export const DELETE_FAQ = gql`
  mutation deleteFaq($id: ID!) {
    deleteFaq(id: $id) {
      id
    }
  }
`;

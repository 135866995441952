import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';

import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import imageUrl from '../../helpers/imageUrl';
import { SAVE_ATTACHED_IMAGE } from '../../graphql/mutations/saveAttachedImage';

const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);
const formats = [
  'bold',
  'blockquote',
  'font',
  'header',
  'image',
  'indent',
  'italic',
  'link',
  'list',
  'size',
  'strike',
  'underline',
];

const TextEditor = props => {
  const [customModule, setcustomModule] = useState({});
  const quillRef = useRef();
  const [saveAttachedImage] = useMutation(SAVE_ATTACHED_IMAGE);

  const handleOnChange = (html, _delta, source) => {
    if (source === 'user') {
      props.onChange(html);
    }
  };

  const imageHandler = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const image = input.files[0];

      saveAttachedImage({
        variables: {
          image: image,
        },
      }).then(({ data }) => {
        const quill = quillRef.current.getEditor();
        // Save current cursor state
        const range = quill.getSelection();
        // Insert Image URL
        quill.insertEmbed(
          range.index,
          'image',
          imageUrl(data.saveAttachedImage.imageUrls.large),
          'user',
        );
        // Move cursor to right side of image (easier to continue typing)
        quill.setSelection(range.index + 1);
      });
    };
  }, [saveAttachedImage]);

  useEffect(() => {
    setcustomModule({
      toolbar: {
        container: [
          [{ header: '1' }, { header: '2' }, { font: [] }],
          [{ size: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['link', 'image'],
          ['clean'],
        ],
        clipboard: {
          matchVisual: false,
        },
        handlers: { image: imageHandler },
      },
    });
  }, [imageHandler]);

  return (
    <ReactQuill
      ref={quillRef}
      theme="snow"
      modules={props.customModule && customModule}
      value={props.value}
      onChange={handleOnChange}
      formats={formats}
    />
  );
};

export default TextEditor;

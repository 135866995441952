// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !! COPIED FROM RCL as it's not available in Meta, yet !!
// !! Please don't modify                                !!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select';
import colorOptions from './colorOptions';

const sizeBasedSelector = (element, size) => {
  switch (size) {
    case 'tiny':
      return `select-box__${element}--tiny`;
    case 'small':
      return `select-box__${element}--small`;
    case 'large':
      return `select-box__${element}--large`;
    default:
      return null;
  }
};

class SelectBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: props.value,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ selectedOption: this.props.value });
    }
  }

  customTheme = theme => {
    const { primary, primary75, primary50, primary25 } = this.props.colors;

    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary,
        primary75,
        primary50,
        primary25,
      },
    };
  };

  renderPlaceHolder = () => {
    const { preIcon, placeholderText, size } = this.props;

    return (
      <div className="select-box__placeholder">
        {preIcon && (
          <span className={classnames('select-box__pre-icon', sizeBasedSelector('pre-icon', size))}>
            {preIcon}
          </span>
        )}
        <span
          className={classnames(
            'select-box__placeholder-text',
            sizeBasedSelector('placeholder-text', size),
          )}
        >
          {placeholderText}
        </span>
      </div>
    );
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    this.props.onChange(selectedOption);
  };

  handleInputChange = inputValue => {
    this.props.onInputChange(inputValue);
  };

  getFlags = () => {
    const { isClearable, isDisabled, isMulti, isSearchable } = this.props;
    return {
      isClearable,
      isDisabled,
      isMulti,
      isSearchable,
    };
  };

  render() {
    const { selectedOption } = this.state;
    const {
      autoFocus,
      className,
      classNamePrefix,
      colors,
      errorMsg,
      label,
      name,
      options,
      size,
      touched,
      isMulti,
      width,
    } = this.props;
    const hasError = touched && errorMsg;

    return (
      <>
        {label && <span className={`select-box__label select-box__label--${size}`}>{label}</span>}
        <Select
          {...this.getFlags()}
          autoFocus={autoFocus}
          className={classnames(
            `${className} ${className}--${size}`,
            sizeBasedSelector('width', width),
            {
              'select-box-container--notMulti': !isMulti,
              'select-box-container--error': hasError,
            },
          )}
          classNamePrefix={classNamePrefix}
          colors={colors}
          theme={this.customTheme}
          value={selectedOption}
          name={name}
          options={options}
          onInputChange={this.handleInputChange}
          onChange={this.handleChange}
          placeholder={this.renderPlaceHolder()}
        />
        {errorMsg && <span className="select-box__error">{errorMsg}</span>}
      </>
    );
  }
}

SelectBox.defaultProps = {
  autoFocus: false,
  className: 'select-box-container',
  classNamePrefix: 'select-box',
  colors: {
    primary: colorOptions.coolGray20,
    primary75: colorOptions.coolGray45,
    primary50: colorOptions.coolGray70,
    primary25: colorOptions.coolGray95,
  },
  errorMsg: '',
  isClearable: true,
  isDisabled: false,
  isMulti: false,
  isSearchable: true,
  label: '',
  name: 'select-box',
  onChange: () => {},
  onInputChange: () => {},
  options: [],
  placeholderText: 'Select...',
  preIcon: null,
  required: false,
  size: 'small',
  touched: false,
  value: null,
  width: 'small',
};

SelectBox.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  colors: PropTypes.shape({
    primary: PropTypes.string,
    primary75: PropTypes.string,
    primary50: PropTypes.string,
    primary25: PropTypes.string,
  }),
  errorMsg: PropTypes.string,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  preIcon: PropTypes.node,
  placeholderText: PropTypes.string,
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  required: PropTypes.bool,
  size: PropTypes.oneOf(['tiny', 'small', 'large']),
  touched: PropTypes.bool,
  width: PropTypes.oneOf(['tiny', 'small', 'large']),
};

export default SelectBox;

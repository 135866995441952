import React from 'react';
import { Query } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../common/Header';
import SanitizedHTML from '../sanitizedHTML';
import { getWelltravelNewsOne } from '../../graphql/queries/welltravelNews';
import RenderImage from '../common/RenderImage';
import isQuillEmpty from '../../helpers/isQuillEmpty';

const WelltravelNews = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  return (
    <div className="list">
      <Header
        title={'welltravelNews.show.title'}
        linkTo={`/admin/welltravel_news/${id}/edit`}
        buttonLabel={'shared.edit'}
      />
      <Query {...getWelltravelNewsOne(id)}>
        {({ loading, data }) => {
          if (loading) return <div className="lds-dual-ring" />;
          if (data) {
            const { welltravelNewsOne } = data;
            const hashtags = welltravelNewsOne.hashtags.map(keyword => keyword.name).join(', ');

            return (
              <div className="common-show">
                <div className="grid">
                  <div className="col-12">
                    <div className="common-show__infos">
                      <div className="col-12">
                        <div className="common-show__infos--field">
                          {t('welltravelNews.attributes.title')}
                        </div>
                        <div className="common-show__infos--value">
                          {welltravelNewsOne.title || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('welltravelNews.attributes.teaser')}
                        </div>
                        <div className="common-show__infos--value">
                          {welltravelNewsOne.teaser || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('welltravelNews.attributes.externalLink')}
                        </div>
                        <div className="common-show__infos--value">
                          {welltravelNewsOne.externalLink || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('welltravelNews.attributes.platform')}
                        </div>
                        <div className="common-show__infos--value">
                          {welltravelNewsOne.platform || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('welltravelNews.attributes.hashtags')}
                        </div>
                        <div className="common-show__infos--value">
                          {hashtags || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('welltravelNews.attributes.content')}
                        </div>
                        <div className="common-show__infos--value">
                          {isQuillEmpty(welltravelNewsOne.content) ? (
                            t('shared.notAvailable')
                          ) : (
                            <SanitizedHTML html={welltravelNewsOne.content} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="common-show__images">
                  <div className="common-show__infos--field">
                    {t('welltravelNews.attributes.image')}
                  </div>
                  <RenderImage url={welltravelNewsOne.imageUrls.large} />
                </div>
              </div>
            );
          }
          return null;
        }}
      </Query>
    </div>
  );
};

export default WelltravelNews;

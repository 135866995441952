import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const HotelDescription = props => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);

  const getPartialDescription = description => {
    /*
        First, it takes any character(specified by minLength props) from the beginning
        after that it takes one or more character until it reaches white space or end of line.
        For example: "this component is created for hotel description"
        if the minlength is 5 for the string then from the used regex we will get output "this component"
        rather than "this c" And here $1 refers the matching character from first bracket
        */
    const subStringPattern = new RegExp(`^(.{${props.minLength}}[^\\s]*).*`);
    return description.replace(subStringPattern, '$1');
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const description = [];

  const subDescription = getPartialDescription(props.hotelDescription);
  const collapseButton = (
    <span
      className="hotel-details--collapse"
      onClick={toggleShowAll}
      role="presentation"
      key="collapseButton"
      onKeyPress={toggleShowAll}
    >
      {showAll
        ? t('humanVerifiedHotels.buttons.readLess')
        : t('humanVerifiedHotels.buttons.readMore')}
    </span>
  );

  if (subDescription.length < props.hotelDescription.length) {
    description.push(
      showAll ? (
        <span className="hotel-details" key={props.id}>
          {props.hotelDescription}{' '}
        </span>
      ) : (
        <span className={'hotel-details'} key={props.id}>{`${subDescription}...`}</span>
      ),
    );
    description.push(collapseButton);
  } else {
    description.push(<span key="description">{props.hotelDescription}</span>);
  }
  return <React.Fragment> {description} </React.Fragment>;
};

HotelDescription.defaultProps = {
  minLength: 65,
};

HotelDescription.propTypes = {
  hotelDescription: PropTypes.string.isRequired,
  minLength: PropTypes.number,
  id: PropTypes.string.isRequired,
};

export default HotelDescription;

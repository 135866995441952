import React from 'react';
import { Query } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../common/Header';
import SanitizedHTML from '../sanitizedHTML';
import { getFaq } from '../../graphql/queries/faq';
import isQuillEmpty from '../../helpers/isQuillEmpty';

const Faq = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  return (
    <div className="list">
      <Header title="faqs.show.title" linkTo={`/admin/faqs/${id}/edit`} buttonLabel="shared.edit" />
      <Query {...getFaq(id)}>
        {({ loading, data }) => {
          if (loading) return <div className="lds-dual-ring" />;
          if (data) {
            const { faq } = data;
            const hashtags = faq.hashtags.map(keyword => keyword.name).join(', ');
            const nestedFaqs = faq.nestedFaqs.map(faq => faq.title).join(', ');
            const isDescriptionNull = isQuillEmpty(faq.description);
            return (
              <div className="common-show">
                <div className="grid">
                  <div className="col-12">
                    <div className="common-show__infos">
                      <div className="col-12">
                        <div className="common-show__infos--field">
                          {t('faqs.attributes.title')}
                        </div>
                        <div className="common-show__infos--value">
                          {faq.title || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('faqs.attributes.thumbsUp')}
                        </div>
                        <div className="common-show__infos--value">{faq.thumbsUp}</div>
                        <div className="common-show__infos--field">
                          {t('faqs.attributes.thumbsDown')}
                        </div>
                        <div className="common-show__infos--value">{faq.thumbsDown}</div>
                        <div className="common-show__infos--field">
                          {t('faqs.attributes.keywords')}
                        </div>
                        <div className="common-show__infos--value">
                          {hashtags || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('faqs.attributes.description')}
                        </div>
                        <div className="common-show__infos--value">
                          {isDescriptionNull ?
                            (t('shared.notAvailable'))
                            : (<SanitizedHTML html={faq.description} />)
                          }
                        </div>
                        <div className="common-show__infos--field">
                          {t('faqs.attributes.updatedBy')}
                        </div>
                        <div className="common-show__infos--value">
                          {faq.updatedBy.name || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('faqs.attributes.updatedAt')}
                        </div>
                        <div className="common-show__infos--value">
                          {faq.updatedAt || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('faqs.attributes.nestedFaqs')}
                        </div>
                        <div className="common-show__infos--value">
                          {nestedFaqs  || t('shared.notAvailable')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return null;
        }}
      </Query>
    </div>
  );
};

export default Faq;

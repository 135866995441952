import gql from 'graphql-tag';
import { one } from './prepareQueryParams';

export const GET_FARE_BRAND = gql`
  query fareBrand($id: ID!) {
    fareBrand(id: $id) {
      id
      airlineCabinClassId
      name
      bookingClass
      cabinClass {
        id
        name
        airline {
          id
          code
          name
        }
      }
      cancelPenalty
      cancelPenaltyCurrency
      catering
      changePenalty
      changePenaltyCurrency
      changeable
      co2Compensation
      digitalMagazines
      exclusiveCheckIn
      fastTrackSecurity
      flatbed
      handLuggage
      handLuggageCount
      handLuggageWeight
      loungeAccess
      mileUpgrade
      milesAward
      milesAwardAmount
      milesStatus
      milesStatusAmount
      fareBrandNameChange
      noShow
      noShowPenalty
      noShowPenaltyCurrency
      priorityBoarding
      priorityLuggage
      refundable
      seatReservation
      welcomeDrink
      supplierFareBrands{
        id
        supplierName
        supplierBrandName
      }
    }
  }
`;

export const getFareBrand = id => one(GET_FARE_BRAND, id);

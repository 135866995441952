import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Button from '@bit/mno-wtag.welltravel.button';
import Input from '@bit/mno-wtag.welltravel.input';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_UNSPLASH_IMAGE_SEARCH_RESULTS } from '../../graphql/queries/unsplashImageSearch';
import { GET_PICTURES } from '../../graphql/queries/picture';
import { CREATE_PICTURE, DELETE_PICTURE } from '../../graphql/mutations/picture';
import imageUrl from '../../helpers/imageUrl';
import BaseModal from '../rclComponents/BaseModal';
import Icon from '@wtag/rcl-icon';
import SpinnerIcon from '../../assets/icons/spinner.svg';
import './styles.scss';

const UnsplashImageSearch = ({ imageableType, imageableId }) => {
  const { t } = useTranslation();

  const [query, setQuery] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedImageIds, setSelectedImageIds] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [pictureIdsToDelete, setPictureIdsToDelete] = useState([]);
  const [pictureIdList, setPictureIdList] = useState([]);
  const [deleteSelectedDisable, setDeleteSelectedDisable] = useState(true);
  const [deleteAllDisable, setDeleteAllDisable] = useState(true);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isCreateLoading, setIsCreateLoading] = useState(false);

  const [fetchPictures, { data: pictureData, loading }] = useLazyQuery(GET_PICTURES, {
    variables: {
      imageableType: imageableType,
      imageableId: imageableId,
    },
    fetchPolicy: 'network-only',
    onCompleted: savedPictures => {
      setPictures(savedPictures.pictures.edges);
      setPictureIdList(
        savedPictures.pictures.edges.map(edge => {
          return edge.node.unsplashPhotoId;
        }),
      );

      if (savedPictures.pictures.edges.length > 0) {
        setDeleteAllDisable(false);
      } else {
        setDeleteAllDisable(true);
      }

      setIsCreateLoading(false);
      setIsDeleteLoading(false);
    },
  });

  useEffect(() => {
    fetchPictures();
  }, [fetchPictures]);

  const [searchInUnsplash] = useLazyQuery(GET_UNSPLASH_IMAGE_SEARCH_RESULTS, {
    skip: query == null,
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setResults(data.unsplashImageSearch);
      setOpenModal(true);
    },
  });

  const [createPicture] = useMutation(CREATE_PICTURE, {});

  const [deletePicture] = useMutation(DELETE_PICTURE, {});

  const onSearch = () => {
    searchInUnsplash({
      variables: { query },
    });
  };

  const addOrRemoveFromList = (id, prevState) => {
    const imageIdIndex = prevState.indexOf(id);

    if (imageIdIndex > -1) {
      return prevState.filter(item => item !== id);
    } else {
      return [...prevState, id];
    }
  };

  const onClickImage = event => {
    const id = event.currentTarget.id;

    setSelectedImageIds(prevState => {
      const imageIdIndex = prevState.indexOf(id);

      if (imageIdIndex > -1) {
        return prevState.filter(item => item !== id);
      } else {
        return [...prevState, id];
      }
    });
  };

  const onClickImageToDelete = event => {
    const id = event.currentTarget.id;

    setPictureIdsToDelete(prevState => {
      const list = addOrRemoveFromList(id, prevState);
      if (list.length > 0) {
        setDeleteSelectedDisable(false);
      } else {
        setDeleteSelectedDisable(true);
      }
      return list;
    });
  };

  const deleteAllImages = async () => {
    const promises = [];
    const pictureIds = pictureData.pictures.edges.map(edge => {
      return edge.node.id;
    });
    setIsDeleteLoading(true);

    if (pictureIds.length > 0) {
      pictureIds.forEach(id => {
        promises.push(
          deletePicture({
            variables: {
              Id: id,
            },
          }),
        );
      });
      await Promise.all(promises);

      fetchPictures();
    }
  };

  const deleteSelectedImages = async () => {
    const promises = [];
    setIsDeleteLoading(true);

    if (pictureIdsToDelete.length > 0) {
      pictureIdsToDelete.forEach(id => {
        promises.push(
          deletePicture({
            variables: {
              Id: id,
            },
          }),
        );
      });
      await Promise.all(promises);
      setPictureIdsToDelete([]);
      fetchPictures();
    }
  };

  const onClickConfirm = async () => {
    setOpenModal(false);
    setIsCreateLoading(true);

    const finalSelectedImageIds = selectedImageIds.filter(element => {
      return pictureIdList.indexOf(element) < 0;
    });

    const promises = [];

    if (finalSelectedImageIds.length > 0) {
      finalSelectedImageIds.forEach(id => {
        promises.push(
          createPicture({
            variables: {
              imageableId: imageableId,
              imageableType: imageableType,
              unsplashPhotoId: id,
            },
          }),
        );
      });
      await Promise.all(promises);
      fetchPictures();
    }
  };

  const renderLoading = () => {
    let title = null;
    if (loading && !isDeleteLoading) {
      title = t('unsplashImageSearch.loading.fetch');
    } else if (isCreateLoading) {
      title = t('unsplashImageSearch.loading.create');
    } else if (isDeleteLoading) {
      title = t('unsplashImageSearch.loading.delete');
    }

    if (title) {
      return (
        <div className="unsplash-images__loading">
          <div className="unsplash-images__loading-title">{title}</div>

          <img className="list__search-post-icon" src={SpinnerIcon} alt="spinner" />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="gird">
      <h1>{t('unsplashImageSearch.labels.unsplashImage')}</h1>
      <Input
        label={t('unsplashImageSearch.labels.imageSearch')}
        onChange={event => setQuery(event.target.value)}
        value={query}
      />
      <div className="unsplash-images__select-button">
        <Button
          label={t('list.search.label')}
          onClick={onSearch}
          disabled={query === null || query === ''}
        />
      </div>

      <BaseModal
        open={openModal}
        onModalClose={() => setOpenModal(false)}
        header={'Unsplash images'}
        defaultPadding={true}
        size="large"
      >
        <div className="col-grid col-bleed">
          <div
            className={classNames('unsplash-images__list', {
              'unsplash-images--without-images': !results.length > 0,
            })}
          >
            {results && results.length > 0 ? (
              results.map(image => (
                <span
                  className={classNames('unsplash-images__list-item', {
                    'unsplash-images__list-item--selected':
                      selectedImageIds.indexOf(image.imageId) > -1 ||
                      pictureIdList.indexOf(image.imageId) > -1,
                  })}
                  onClick={onClickImage}
                  onKeyPress={onClickImage}
                  role="presentation"
                  id={image.imageId}
                  key={image.imageId}
                >
                  <img src={image.smallImageUrl} alt="preview" />
                  <div className="unsplash-images__list-item-overlay">
                    <Icon name="checkCircle" />
                  </div>
                </span>
              ))
            ) : (
              <p key="no-image-found">{'Images Not Found'}</p>
            )}
          </div>
          <div className="unsplash-images__select-button">
            <Button label={t('unsplashImageSearch.labels.selectImage')} onClick={onClickConfirm} />
          </div>
        </div>
      </BaseModal>

      {renderLoading()}
      <div
        className={classNames(
          'unsplash-images__list',
          {
            'unsplash-images--without-images': !pictures.length > 0,
          },
          { 'unsplash-images__list-medium__list': pictures.length > 8 },
          { 'unsplash-images__list-small__list': pictures.length < 5 },
        )}
      >
        {pictures && pictures.length > 0 ? (
          pictures.map(image => (
            <span
              className={classNames('unsplash-images__list-item', {
                'unsplash-images__list-item--selected':
                  pictureIdsToDelete.indexOf(image.node.id) > -1,
              })}
              onClick={onClickImageToDelete}
              onKeyPress={onClickImageToDelete}
              role="presentation"
              id={image.node.id}
              key={image.node.id}
            >
              <img src={imageUrl(image.node.imageUrls.small)} alt="preview" />
              <div className="unsplash-images__list-item-overlay">
                <Icon name="delete" />
              </div>
            </span>
          ))
        ) : (
          <p key="no-image-found">{'No Images'}</p>
        )}
      </div>
      <div className="unsplash-images__delete-button">
        <Button
          label={t('unsplashImageSearch.labels.deleteSelected')}
          onClick={deleteSelectedImages}
          disabled={deleteSelectedDisable}
        />
        <Button
          label={t('unsplashImageSearch.labels.deleteAll')}
          onClick={deleteAllImages}
          disabled={deleteAllDisable}
        />
      </div>
    </div>
  );
};

export default UnsplashImageSearch;

import gql from 'graphql-tag';
import { one, all, oneWithUserDefinedKey } from './prepareQueryParams';

export const GET_CURSORS_FOR_HOTEL_COMBINED_DATABASES = gql`
  query hotelCombinedDatabases($first: Int, $after: String, $query: String, $currentSearchAttribute: String) {
    hotelCombinedDatabases(
      first: $first
      after: $after
      query: $query
      currentSearchAttribute: $currentSearchAttribute
    ) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_HOTEL_COMBINED_DATABASES = gql`
  query hotelCombinedDatabases($first: Int, $after: String, $query: String, $currentSearchAttribute: String) {
    hotelCombinedDatabases(
      first: $first
      after: $after
      query: $query
      currentSearchAttribute: $currentSearchAttribute
    ) {
      edges {
        cursor
        node {
          id
          name
          alternateNames
          welltravelCodes
          mainSource
          iso31661
          iso31662
          imageUrls
          description
          uniqueId
          latitude
          longitude
          additionalContents
          supplierCodes
          otherSources
          multipleSource
          proximityPercentage
        }
      }
    }
  }
`;

export const getHotelCombinedDatabases = after => all(GET_HOTEL_COMBINED_DATABASES, after);

export const GET_HOTEL_COMBINED_DATABASE = gql`
  query hotelCombinedDatabase($id: ID!) {
    hotelCombinedDatabase(id: $id) {
      id
      name
      alternateNames
      welltravelCodes
      mainSource
      iso31661
      iso31662
      imageUrls
      description
      uniqueId
      latitude
      longitude
      additionalContents
      supplierCodes
      otherSources
      multipleSource
      proximityPercentage
    }
  }
`;

export const getHotelCombinedDatabase = id => one(GET_HOTEL_COMBINED_DATABASE, id);

export const GET_HOTEL_COMBINED_DATABASE_BY_UNIQUE_ID = gql`
  query hotelCombinedDatabase($uniqueId: String!) {
    hotelCombinedDatabase(uniqueId: $uniqueId) {
      id
      name
      alternateNames
      welltravelCodes
      mainSource
      iso31661
      iso31662
      imageUrls
      description
      uniqueId
      latitude
      longitude
      additionalContents
      supplierCodes
      proximityPercentage
    }
  }
`;

export const getCombinedHotelByUniqueId = uniqueId =>
  oneWithUserDefinedKey(GET_HOTEL_COMBINED_DATABASE_BY_UNIQUE_ID, 'uniqueId', uniqueId);

import React from 'react';
import {
  GET_CONTINENTS,
  GET_CURSORS_FOR_CONTINENTS,
} from '../../graphql/queries/continent';
import { DELETE_CONTINENT } from '../../graphql/mutations/continent';
import List from '../common/List';

const ContinentList = () => {
  return (
    <List
      getItems={GET_CONTINENTS}
      getCursorsForItems={GET_CURSORS_FOR_CONTINENTS}
      deleteItem={DELETE_CONTINENT}
      listAttributes={['name', 'code']}
      itemType="continents"
      modelName="Continent"
      title="nav.continents"
      linkTo="/admin/continents/new"
      buttonLabel="shared.addNew"
      minLengthToSearch={ 2 }
    />
  );
};

export default ContinentList;

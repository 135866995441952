import React from 'react';
import { Query } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getPlace } from '../../graphql/queries/place';
import Header from '../common/Header';
import RenderImage from '../common/RenderImage';

const Place = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  return (
    <div className="list">
      <Header
        title="places.show.title"
        linkTo={`/admin/places/${id}/edit`}
        buttonLabel="shared.edit"
      />
      <Query {...getPlace(id)}>
        {({ loading, data }) => {
          if (loading) return <div className="lds-dual-ring" />;
          if (data) {
            const { place } = data;

            return (
              <div className="common-show">
                <div className="grid">
                  <div className="col-12">
                    <div className="common-show__infos">
                      <div className="col-12">
                        <div className="common-show__infos--field">
                          {t('places.attributes.name')}
                        </div>
                        <div className="common-show__infos--value">{place.name}</div>
                        <div className="common-show__infos--field">
                          {t('places.attributes.twitterHashtag')}
                        </div>
                        <div className="common-show__infos--value">
                          {place.twitterHashtag || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('places.attributes.country')}
                        </div>
                        <div className="common-show__infos--value">{place.country.name}</div>
                        <div className="common-show__infos--field">
                          {t('places.attributes.geonameIdentifier')}
                        </div>
                        <div className="common-show__infos--value">
                          {place.geonameIdentifier || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('places.attributes.admin1Code')}
                        </div>
                        <div className="common-show__infos--value">
                          {place.admin1Code || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('places.attributes.admin2Code')}
                        </div>
                        <div className="common-show__infos--value">
                          {place.admin2Code || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('places.attributes.admin3Code')}
                        </div>
                        <div className="common-show__infos--value">
                          {place.admin3Code || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('places.attributes.admin4Code')}
                        </div>
                        <div className="common-show__infos--value">
                          {place.admin4Code || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('places.attributes.featureCode')}
                        </div>
                        <div className="common-show__infos--value">
                          {place.featureCode || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('places.attributes.latitude')}
                        </div>
                        <div className="common-show__infos--value">
                          {place.latitude || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('places.attributes.longitude')}
                        </div>
                        <div className="common-show__infos--value">
                          {place.longitude || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('places.attributes.timezone')}
                        </div>
                        <div className="common-show__infos--value">
                          {place.timezone || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('places.attributes.description')}
                        </div>
                        <div className="common-show__infos--value">
                          {place.description || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('place.attributes.publishDescription')}
                        </div>
                        <div className="common-show__infos--value">
                          {place.publishDescription.toString()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="common-show__images">
                  <div className="common-show__infos--field">{t('places.attributes.image')}</div>
                  <RenderImage url={place.imageUrls.large} />
                </div>
              </div>
            );
          }
          return null;
        }}
      </Query>
    </div>
  );
};

export default Place;

import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_GLOSSARIES = gql`
  query glossaries($first: Int, $after: String, $query: String, $currentTab: Int) {
    glossaries(first: $first, after: $after, query: $query, category: $currentTab) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_GLOSSARIES = gql`
  query glossaries($first: Int, $after: String, $query: String, $currentTab: Int) {
    glossaries(first: $first, after: $after, query: $query, category: $currentTab) {
      edges {
        cursor
        node {
          id
          term
          category
          subCategory
          keywords {
            id
            name
          }
          definition
        }
      }
    }
  }
`;

export const getGlossaries = after => all(GET_GLOSSARIES, after);

export const GET_GLOSSARY = gql`
  query glossary($id: ID!) {
    glossary(id: $id) {
      id
      term
      category
      subCategory
      keywords {
        id
        name
      }
      definition
    }
  }
`;

export const getGlossary = id => one(GET_GLOSSARY, id);

export const GET_GLOSSARY_CATEGORIES = gql`
  query glossaryCategories {
    glossaryCategories {
      label
      value
    }
  }
`;

export const GET_GLOSSARY_SUBCATEGORIES = gql`
  query glossarySubCategories {
    glossarySubCategories {
      label
      value
    }
  }
`;

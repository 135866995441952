import React from 'react';
import { GET_FLIGHTS, GET_CURSORS_FOR_FLIGHTS } from '../../graphql/queries/flight';
import { DELETE_FLIGHT } from '../../graphql/mutations/flight';
import List from '../common/List';

const FlightList = () => {
  return (
    <List
      getItems={GET_FLIGHTS}
      getCursorsForItems={GET_CURSORS_FOR_FLIGHTS}
      deleteItem={DELETE_FLIGHT}
      listAttributes={['flightNumber', 'wifiAvailability', 'onTimePercentage']}
      itemType="flights"
      modelName="Flight"
      title="nav.flights"
      linkTo="/admin/flights/new"
      buttonLabel="shared.addNew"
    />
  );
};

export default FlightList;

import gql from 'graphql-tag';

export const CREATE_PLATFORM_FEATURE = gql`
  mutation createPlatformFeature(    
    $title: String!
    $description: String
    $userId: ID!
    $parentId: ID
    $hashtagIds: [String!]
    ) 
    {
    createPlatformFeature(
      title: $title
      description: $description
      userId: $userId
      parentId: $parentId
      hashtagIds: $hashtagIds
      ) 
      {
      id
      title
      description
      order
      updatedBy {
        name
        id
      }
      hashtags {
        id
        name
      }
      parentId
    }
  }
`;

export const EDIT_PLATFORM_FEATURE = gql`
  mutation editPlatformFeature(
    $id: ID!
    $title: String!
    $description: String
    $order: Int
    $userId: ID!
    $parentId: ID
    $hashtagIds: [String!]
  ) {
    editPlatformFeature(
      id: $id
      title: $title
      description: $description
      order: $order
      userId: $userId
      parentId: $parentId
      hashtagIds: $hashtagIds
      ) {
      id
      title
      description
      order
      updatedBy {
        name
        id
      }
      hashtags {
        id
        name
      }
      parentId
    }
  }
`;

export const DELETE_PLATFORM_FEATURE = gql`
  mutation deletePlatformFeature($id: ID!) {
    deletePlatformFeature(id: $id) {
      id
      title
    }
  }
`;

import gql from 'graphql-tag';

export const CREATE_PLACE_SYNONYM = gql`
  mutation createPlaceSynonym(
    $name: String!
    $geonameIdentifier: String
    $locale: String
    $placeId: ID!
  ) {
    createPlaceSynonym(
      name: $name
      geonameIdentifier: $geonameIdentifier
      locale: $locale
      placeId: $placeId
    ) {
      id
      name
      geonameIdentifier
      locale
      place {
        id
        name
      }
    }
  }
`;

export const EDIT_PLACE_SYNONYM = gql`
  mutation editPlaceSynonym(
    $id: ID!
    $name: String!
    $geonameIdentifier: String
    $locale: String
    $placeId: ID!
  ) {
    editPlaceSynonym(
      id: $id
      name: $name
      geonameIdentifier: $geonameIdentifier
      locale: $locale
      placeId: $placeId
    ) {
      id
      name
      geonameIdentifier
      locale
      place {
        id
        name
      }
    }
  }
`;

export const DELETE_PLACE_SYNONYM = gql`
  mutation deletePlaceSynonym($id: ID!) {
    deletePlaceSynonym(id: $id) {
      name
    }
  }
`;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@bit/mno-wtag.welltravel.button';
import Input from '@bit/mno-wtag.welltravel.input';
import { useQuery, useMutation } from '@apollo/react-hooks';
import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import { GET_FEATURE, getFeature } from '../../graphql/queries/feature';
import { CREATE_FEATURE, EDIT_FEATURE } from '../../graphql/mutations/feature';
import clearCache from '../../helpers/clearCache';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

const FeatureForm = props => {
  const { t } = useTranslation();
  let { id } = useParams();
  const isNewRecord = !id;

  const [createFeature, callbackDataOnCreate] = useMutation(CREATE_FEATURE, {
    update: cache => clearCache(cache, /Feature/),
  });
  const [editFeature, callbackDataOnEdit] = useMutation(EDIT_FEATURE);
  const featureFromId = useQuery(GET_FEATURE, {
    variables: { id },
    skip: isNewRecord,
  });

  const [name, setName] = useState('');
  const [icon, setIcon] = useState('');

  const onNameChange = event => {
    setName(event.target.value);
  };

  const onIconChange = event => {
    setIcon(event.target.value);
  };

  useEffect(() => {
    if (featureFromId && featureFromId.data && !featureFromId.loading) {
      setName(featureFromId.data.feature.name);
      setIcon(featureFromId.data.feature.icon);
    }
  }, [featureFromId]);

  const submitToBackEnd = debounce(() => {
    props.resetError();

    if (isNewRecord) {
      createFeature({
        variables: { name, icon },
      })
        .then(({ data }) => {
          props.history.push(`/admin/features/${data.createFeature.id}`);
        })
        .catch(() => props.onError());
    } else {
      editFeature({
        variables: { id, name, icon },
        refetchQueries: [getFeature(id)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/features/${data.editFeature.id}`);
        })
        .catch(() => props.onError());
    }
  }, 500);

  return (
    <div className="common-form">
      <div className="common-form__title">
        {isNewRecord ?
          t('shared.new', { entityName: t('features.show.title') })
          : t('shared.editTitle', { entityName: t('features.show.title') })}
      </div>
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit, callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-lg-6 col-md-10 col-sm-10 col-xs-10">
            <div className="col-12 col-bleed-y">
              <div className="common-form__field feature-form__name">
                <Input label={t('features.attributes.name')} value={name} onChange={onNameChange} />
              </div>
              <div className="common-form__field feature-form__icon">
                <Input label={t('features.attributes.icon')} value={icon} onChange={onIconChange} />
              </div>
              <div className="common-form__submit-button">
                <Button
                  label={isNewRecord ? t('shared.create') : t('shared.update')}
                  onClick={submitToBackEnd}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithErrorHandler(FeatureForm);

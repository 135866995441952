import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_VISA_PROCUREMENT_CATEGORIES = gql`
  query visaProcurementCategories($first: Int, $after: String, $query: String) {
    visaProcurementCategories(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_VISA_PROCUREMENT_CATEGORIES = gql`
  query visaProcurementCategories($first: Int, $after: String, $query: String) {
    visaProcurementCategories(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          name
          visaCategory
        }
      }
    }
  }
`;

export const getVisaProcurementCategories = after => all(GET_VISA_PROCUREMENT_CATEGORIES, after);

export const GET_VISA_PROCUREMENT_CATEGORY = gql`
  query visaProcurementCategory($id: ID!) {
    visaProcurementCategory(id: $id) {
      id
      name
      visaCategory
    }
  }
`;

export const getVisaProcurementCategory = id => one(GET_VISA_PROCUREMENT_CATEGORY, id);

export const GET_VISA_CATEGORIES = gql`
  query visaCategories {
    visaCategories {
      label
      value
    }
  }
`;

import React from 'react';
import {
  GET_STATIC_MESSAGES,
  GET_CURSORS_FOR_STATIC_MESSAGES,
} from '../../graphql/queries/staticMessage';
import { DELETE_STATIC_MESSAGE } from '../../graphql/mutations/staticMessage';
import List from '../common/List';

const StaticMessageList = () => {
  return (
    <List
      getItems={GET_STATIC_MESSAGES}
      getCursorsForItems={GET_CURSORS_FOR_STATIC_MESSAGES}
      deleteItem={DELETE_STATIC_MESSAGE}
      listAttributes={['key', 'message']}
      itemType="staticMessages"
      modelName="StaticMessage"
      title="nav.staticMessages"
      linkTo="/admin/static_messages/new"
      buttonLabel="shared.addNew"
    />
  );
};

export default StaticMessageList;

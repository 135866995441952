import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_BANNERS = gql`
  query banners($first: Int, $after: String, $query: String) {
    banners(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_BANNERS = gql`
  query banners($first: Int, $after: String, $query: String) {
    banners(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          imageUrls
          description
          publishDescription
        }
      }
    }
  }
`;

export const getBanners = after => all(GET_BANNERS, after);

export const GET_BANNER = gql`
  query banner($id: ID!) {
    banner(id: $id) {
      imageUrls
      description
      publishDescription
    }
  }
`;

export const getBanner = id => one(GET_BANNER, id);

import { ReactComponent as AircraftType } from '../../assets/icons/aircraft-type.svg';
import { ReactComponent as Airline } from '../../assets/icons/airline.svg';
import { ReactComponent as Airport } from '../../assets/icons/airport.svg';
import { ReactComponent as AncillaryCategory } from '../../assets/icons/ancillary-category.svg';
import { ReactComponent as AncillaryService } from '../../assets/icons/ancillary-service.svg';
import { ReactComponent as Banner } from '../../assets/icons/banner.svg';
import { ReactComponent as CarRentalCompany } from '../../assets/icons/car-rental-company.svg';
import { ReactComponent as City } from '../../assets/icons/place-tag.svg';
import { ReactComponent as Continent } from '../../assets/icons/continent.svg';
import { ReactComponent as Country } from '../../assets/icons/country.svg';
import { ReactComponent as Faq } from '../../assets/icons/faq.svg';
import { ReactComponent as Feature } from '../../assets/icons/feature.svg';
import { ReactComponent as Flight } from '../../assets/icons/flight.svg';
import { ReactComponent as Glossary } from '../../assets/icons/glossary.svg';
import { ReactComponent as Place } from '../../assets/icons/place.svg';
import { ReactComponent as PlaceSynonym } from '../../assets/icons/place_synonym.svg';
import { ReactComponent as PlaceTag } from '../../assets/icons/place-tag.svg';
import { ReactComponent as PlatformFeature } from '../../assets/icons/feature.svg';
import { ReactComponent as RawHotel } from '../../assets/icons/raw-hotel.svg';
import { ReactComponent as HumanVerifiedHotel } from '../../assets/icons/human-verified-hotel.svg';
import { ReactComponent as HotelCombinedDatabase } from '../../assets/icons/hotel-combined-database.svg';
import { ReactComponent as TaxCategory } from '../../assets/icons/tax-category.svg';
import { ReactComponent as StaticMessage } from '../../assets/icons/static-message.svg';
import { ReactComponent as VisaProcurementCategory } from '../../assets/icons/visa-procurement-category.svg';
import { ReactComponent as VisaRequirement } from '../../assets/icons/visa-requirement.svg';
import { ReactComponent as News } from '../../assets/icons/news.svg';
import { ReactComponent as WifiPackage } from '../../assets/icons/wifi-package.svg';
import { ReactComponent as SpinnerIcon } from '../../assets/icons/spinner.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow_top-24px.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow_down-24px.svg';
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !! COPIED FROM RCL as it's not available in Meta, yet !!
// !! Please don't modify                                !!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
import { ReactComponent as Close } from '../../assets/icons/close.svg';

export default {
  AircraftType: AircraftType,
  Airline: Airline,
  Airport: Airport,
  AncillaryCategory: AncillaryCategory,
  AncillaryService: AncillaryService,
  Banner: Banner,
  CarRentalCompany: CarRentalCompany,
  City: City,
  Continent: Continent,
  Country: Country,
  Faq: Faq,
  Feature: Feature,
  Flight: Flight,
  HotelCombinedDatabase: HotelCombinedDatabase,
  HumanVerifiedHotel: HumanVerifiedHotel,
  PlaceTag: PlaceTag,
  Place: Place,
  PlaceSynonym: PlaceSynonym,
  PlatformFeature: PlatformFeature,
  RawHotel: RawHotel,
  TaxCategory: TaxCategory,
  TravelNews: News,
  Glossary: Glossary,
  StaticMessage: StaticMessage,
  VisaProcurementCategory: VisaProcurementCategory,
  VisaRequirement: VisaRequirement,
  WelltravelNews: News,
  WifiPackage: WifiPackage,
  SpinnerIcon: SpinnerIcon,
  ArrowUp: ArrowUp,
  ArrowDown: ArrowDown,
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // !! COPIED FROM RCL as it's not available in Meta, yet !!
  // !! Please don't modify                                !!
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  close: Close,
};

import gql from 'graphql-tag';

export const CREATE_CONTINENT = gql`
  mutation createContinent(
    $name: String!
    $code: String!
  ) {
    createContinent(
      name: $name
      code: $code
    ) {
      id
      name
      code
    }
  }
`;

export const EDIT_CONTINENT = gql`
  mutation editContinent(
    $id: ID!
    $name: String!
    $code: String!
  ) {
    editContinent(
      id: $id
      name: $name
      code: $code
    ) {
      id
      name
      code
    }
  }
`;

export const DELETE_CONTINENT = gql`
  mutation deleteContinent($id: ID!) {
    deleteContinent(id: $id) {
      code
    }
  }
`;

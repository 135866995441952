// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !! COPIED FROM RCL as it's not available in Meta, yet !!
// !! Please don't modify                                !!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const radioSize = size => {
  switch (size) {
    case 'large':
      return 'radio--large';
    case 'huge':
      return 'radio--huge';
    case 'small':
      return 'radio--small';
    default:
      return null;
  }
};

const Radio = props => {
  const { label, size, disabled, dark, name, ...others } = props;
  const radioId = `modal-${Math.random()}`;
  return (
    <label
      htmlFor={`${name}${radioId}`}
      className={classNames('radio', radioSize(size), {
        'radio--disabled': disabled,
        'radio-dark': dark,
      })}
    >
      <input
        {...others}
        className="radio__input"
        id={`${name}${radioId}`}
        name={name}
        type="radio"
      />
      <span className="radio__check" />
      {label && <span className="radio__label">{label}</span>}
    </label>
  );
};

Radio.defaultProps = {
  label: null,
  touched: false,
  size: null,
  disabled: false,
  dark: false,
};

Radio.propTypes = {
  touched: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(['large', 'huge', 'small']),
  disabled: PropTypes.bool,
  dark: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

export default Radio;

import gql from 'graphql-tag';

export const CREATE_WELLTRAVEL_NEWS = gql`
  mutation createWelltravelNews(
    $title: String!
    $teaser: String
    $externalLink: String
    $platform: String
    $hashtagIds: [String!]
    $content: String
    $image: Upload
  ) {
    createWelltravelNews(
      title: $title
      teaser: $teaser
      externalLink: $externalLink
      platform: $platform
      hashtagIds: $hashtagIds
      content: $content
      image: $image
    ) {
      id
      title
      teaser
      externalLink
      platform
      hashtags {
        id
        name
      }
      content
      imageUrls
    }
  }
`;

export const EDIT_WELLTRAVEL_NEWS = gql`
  mutation editWelltravelNews(
    $id: ID!
    $title: String!
    $teaser: String
    $externalLink: String
    $platform: String
    $hashtagIds: [String!]
    $content: String
    $image: Upload
  ) {
    editWelltravelNews(
      id: $id
      title: $title
      teaser: $teaser
      externalLink: $externalLink
      platform: $platform
      hashtagIds: $hashtagIds
      content: $content
      image: $image
    ) {
      id
      title
      teaser
      externalLink
      platform
      hashtags {
        id
        name
      }
      content
      imageUrls
    }
  }
`;
export const DELETE_WELLTRAVEL_NEWS = gql`
  mutation deleteWelltravelNews($id: ID!) {
    deleteWelltravelNews(id: $id) {
      id
    }
  }
`;

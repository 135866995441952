import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';

import Button from '@bit/mno-wtag.welltravel.button';
import TextArea from '@bit/mno-wtag.welltravel.text-area';
import SelectBox from '@bit/mno-wtag.welltravel.select-box';

import { getVisaProcurementCategory } from '../../graphql/queries/visaProcurementCategory';
import { GET_VISA_CATEGORIES } from '../../graphql/queries/visaProcurementCategory';
import { GET_VISA_PROCUREMENT_CATEGORY } from '../../graphql/queries/visaProcurementCategory';
import { EDIT_VISA_PROCUREMENT_CATEGORY } from '../../graphql/mutations/visaProcurementCategory';

import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import { debounce } from 'lodash';

const VisaProcurementCategoryForm = props => {
  const { t } = useTranslation();
  let { id } = useParams();
  const isNewRecord = !id;

  const callbackDataOnCreate = null;

  const [editVisaProcurementCategory, callbackDataOnEdit] = useMutation(
    EDIT_VISA_PROCUREMENT_CATEGORY,
  );
  const visaProcurementCategoryFromId = useQuery(GET_VISA_PROCUREMENT_CATEGORY, {
    variables: { id },
    skip: isNewRecord,
  });

  const { loading: visaCategoryListLoading, data: visaCategoryListData } = useQuery(
    GET_VISA_CATEGORIES,
  );

  const [name, setName] = useState('');
  const [visaCategory, setCategory] = useState('');

  const onCategoryChange = value => {
    setCategory(value);
  };

  useEffect(() => {
    if (
      visaProcurementCategoryFromId &&
      visaProcurementCategoryFromId.data &&
      !visaProcurementCategoryFromId.loading
    ) {
      setName(visaProcurementCategoryFromId.data.visaProcurementCategory.name);
      setCategory(visaProcurementCategoryFromId.data.visaProcurementCategory.visaCategory);
    }
  }, [visaProcurementCategoryFromId]);

  const submitToBackEnd = debounce(() => {
    props.resetError();

    if (!isNewRecord) {
      editVisaProcurementCategory({
        variables: {
          id,
          visaCategory,
        },
        refetchQueries: [getVisaProcurementCategory(id)],
      })
        .then(({ data }) => {
          props.history.push(
            `/admin/visa_procurement_categories/${data.editVisaProcurementCategory.id}`,
          );
        })
        .catch(() => props.onError());
    }
  }, 500);

  let visaCategoryOptions = [];
  if (!visaCategoryListLoading) {
    visaCategoryOptions = visaCategoryListData.visaCategories.map(visaCategory => ({
      value: visaCategory.value,
      label: t(`visaProcurementCategories.visaCategories.${visaCategory.value}`),
    }));
  }

  return (
    <div className="common-form">
      <div className="common-form__title">
        {isNewRecord ?
          t('shared.new', { entityName: t('visaProcurementCategories.show.title') })
          : t('shared.editTitle', { entityName: t('visaProcurementCategories.show.title') })}
      </div>
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit, callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-6">
            <div className="common-form__field visaProcurementCategory-form__name">
              <TextArea
                label={t('visaProcurementCategories.attributes.name')}
                value={name}
                readOnly={true}
              />
              <div className="common-form__field visaProcurementCategory-form__publish-description">
                <SelectBox
                  label={t('visaProcurementCategories.attributes.visaCategory')}
                  value={visaCategory}
                  options={visaCategoryOptions}
                  onChange={onCategoryChange}
                />
              </div>
            </div>
            <div className="common-form__submit-button">
              <Button
                label={isNewRecord ? t('shared.create') : t('shared.update')}
                onClick={submitToBackEnd}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithErrorHandler(VisaProcurementCategoryForm);

import React from 'react';
import { useTranslation } from 'react-i18next';

import { GET_GLOSSARIES, GET_CURSORS_FOR_GLOSSARIES } from '../../graphql/queries/glossary';
import { DELETE_GLOSSARY } from '../../graphql/mutations/glossary';

import Keyword from '../keyword';
import List from '../common/List';

const GlossaryList = () => {
  const { t } = useTranslation();

  return (
    <List
      getItems={GET_GLOSSARIES}
      getCursorsForItems={GET_CURSORS_FOR_GLOSSARIES}
      deleteItem={DELETE_GLOSSARY}
      listAttributes={['term', 'subCategory']}
      itemType="glossaries"
      title="nav.glossaries"
      modelName="Glossary"
      linkTo="/admin/glossaries/new"
      buttonLabel="shared.addNew"
      showTabs={true}
      tabTitles={[
        t('categories.ecosystem'),
        t('categories.agent'),
        t('categories.online_booking_tool'),
        t('categories.channel'),
        t('categories.support'),
      ]}
    >
      <div className="keyword-wrapper">
        <Keyword />
      </div>
    </List>
  );
};

export default GlossaryList;

import React from 'react';
import {
  GET_PLACE_SYNONYMS,
  GET_CURSORS_FOR_PLACE_SYNONYMS,
} from '../../graphql/queries/placeSynonym';
import { DELETE_PLACE_SYNONYM } from '../../graphql/mutations/placeSynonym';
import List from '../common/List';

const PlaceSynonymList = () => {
  return (
    <List
      getItems={GET_PLACE_SYNONYMS}
      getCursorsForItems={GET_CURSORS_FOR_PLACE_SYNONYMS}
      deleteItem={DELETE_PLACE_SYNONYM}
      listAttributes={['name', 'geonameIdentifier', 'locale']}
      itemType="placeSynonyms"
      modelName="PlaceSynonym"
      title="nav.placeSynonyms"
      linkTo="/admin/place_synonyms/new"
      buttonLabel="shared.addNew"
    />
  );
};

export default PlaceSynonymList;

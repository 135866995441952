import gql from 'graphql-tag';

export const CREATE_CAR_RENTAL_COMPANY = gql`
  mutation createCarRentalCompany(
    $name: String!
    $code: String!
    $image: Upload
  ) {
    createCarRentalCompany(
      name: $name
      code: $code
      image: $image
    ) {
      id
      name
      code
    }
  }
`;

export const EDIT_CAR_RENTAL_COMPANY = gql`
  mutation editCarRentalCompany(
    $id: ID!
    $name: String
    $code: String
    $image: Upload
  ) {
    editCarRentalCompany(
      id: $id
      name: $name
      code: $code
      image: $image
    ) {
      id
      code
      name
      imageUrls
    }
  }
`;

export const DELETE_CAR_RENTAL_COMPANY = gql`
  mutation deleteCarRentalCompany($id: ID!) {
    deleteCarRentalCompany(id: $id) {
      code
    }
  }
`;

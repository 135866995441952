import React from 'react';
import { Query } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCountry } from '../../graphql/queries/country';
import Header from '../common/Header';
import renderTranslations from '../../helpers/renderTranslations';
import RenderImage from '../common/RenderImage';
import Pictures from '../unsplashImageSearch/Pictures';

const Country = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  return (
    <div className="list">
      <Header
        title="countries.show.title"
        linkTo={`/admin/countries/${id}/edit`}
        buttonLabel="shared.edit"
      />
      <Query {...getCountry(id)}>
        {({ loading, data }) => {
          if (loading) return <div className="lds-dual-ring" />;
          if (data) {
            const { country } = data;

            return (
              <div className="common-show">
                <div className="grid">
                  <div className="col-12">
                    <div className="common-show__infos">
                      <div className="col-12">
                        <div className="common-show__infos--field">
                          {t('countries.attributes.name')}
                        </div>
                        <div className="common-show__infos--value">
                          {renderTranslations(
                            country.translatedNames,
                            t('shared.notAvailable'),
                            'name',
                          )}
                        </div>
                        <div className="common-show__infos--field">
                          {t('countries.attributes.code')}
                        </div>
                        <div className="common-show__infos--value">{country.code}</div>
                        <div className="common-show__infos--field">
                          {t('countries.attributes.phoneCode')}
                        </div>
                        <div className="common-show__infos--value">{country.phoneCode}</div>
                        <div className="common-show__infos--field">
                          {t('countries.attributes.nationality')}
                        </div>
                        <div className="common-show__infos--value">
                          {renderTranslations(
                            country.translatedNames,
                            t('shared.notAvailable'),
                            'nationality',
                          )}
                        </div>
                        <div className="common-show__infos--field">
                          {t('countries.attributes.description')}
                        </div>
                        <div className="common-show__infos--value">
                          {country.description || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('countries.attributes.publishDescription')}
                        </div>
                        <div className="common-show__infos--value">
                          {country.publishDescription.toString()}
                        </div>
                        <div className="common-show__infos--field">
                          {t('countries.attributes.continent')}
                        </div>
                        <div className="common-show__infos--value">{country.continent.name}</div>
                        <div className="common-show__infos--field">
                          {t('countries.attributes.apisDataRequired')}
                        </div>
                        <div className="common-show__infos--value">{country.apisDataRequired}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="common-show__images">
                  <div className="common-show__infos--field">{t('countries.attributes.image')}</div>
                  <RenderImage url={country.imageUrls.large} />
                  <div className="common-show__infos--field">
                    {t('unsplashImageSearch.labels.unsplashImage')}
                  </div>
                  <Pictures imageableType={'Country'} imageableId={id} />
                </div>
              </div>
            );
          }
          return null;
        }}
      </Query>
    </div>
  );
};

export default Country;

import React from 'react';
import { Query } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFareBrand } from '../../graphql/queries/fareBrand';
import Header from '../common/Header';
import SupplierBrandName from './SupplierBrandName';

const FareBrand = () => {
  let { airline_id, id } = useParams();
  const { t } = useTranslation();

  const translatedRefundableState = state => {
    if (state === 'refundable') {
      return t('shared.yes');
    } else if (state === 'not_refundable') {
      return t('shared.no');
    }

    return t('shared.noInformation');
  };

  const translatedChangeableState = state => {
    if (state === 'changeable') {
      return t('shared.yes');
    } else if (state === 'not_changeable') {
      return t('shared.no');
    }

    return t('shared.noInformation');
  };

  const translatedAvailableState = (state, availableKey, notAvailableKey) => {
    if (state === availableKey) {
      return t('fareBrands.attributes.available');
    } else if (state === notAvailableKey) {
      return t('fareBrands.attributes.notAvailable');
    }

    return t('shared.noInformation');
  };

  const translatedSupportState = (state, supportedKey, notSupportedKey) => {
    if (state === supportedKey) {
      return t('fareBrands.attributes.supported');
    } else if (state === notSupportedKey) {
      return t('fareBrands.attributes.notSupported');
    }

    return t('shared.noInformation');
  };

  const translatedInclusionState = (state, includedKey, notIncludedKey) => {
    if (state === includedKey) {
      return t('fareBrands.attributes.included');
    } else if (state === notIncludedKey) {
      return t('fareBrands.attributes.notIncluded');
    }

    return t('shared.noInformation');
  };

  return (
    <div className="list">
      <Header
        title="fareBrands.show.title"
        linkTo={`/admin/airlines/${airline_id}/fare_brands/${id}/edit`}
        buttonLabel="shared.edit"
      />
      <Query {...getFareBrand(id)}>
        {({ loading, data }) => {
          if (loading) return <div className="lds-dual-ring" />;
          if (data) {
            const { fareBrand } = data;
            return (
              <div className="col-grid col-bleed fare-brand-show">
                <div className="col-12 col-bleed fare-brand-show__segment">
                  <div className="fare-brand-show__segment-title">
                    {t('fareBrands.show.airlineInformation')}
                  </div>
                  <div className="grid">
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('airlines.show.title')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {`${fareBrand.cabinClass.airline.name} (${fareBrand.cabinClass.airline.code})`}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.cabinClass')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {t(`fareBrands.attributes.${fareBrand.cabinClass.name}`)}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.bookingClass')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {fareBrand.bookingClass.join(', ')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-bleed fare-brand-show__segment">
                  <div className="fare-brand-show__segment-title">
                    {t('fareBrands.show.basicInformation')}
                  </div>
                  <div className="grid">
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.name')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {fareBrand.name}
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      {fareBrand.supplierFareBrands.map(supplierBrandName => (
                        <SupplierBrandName item={supplierBrandName} isEdit={false} />
                      ))}
                    </div>
                  </div>
                  <div className="grid">
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.refundable')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {translatedRefundableState(fareBrand.refundable)}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.cancellationPenalty')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {fareBrand.cancelPenalty || t('shared.noInformation')}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.cancellationPenaltyCurrency')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {fareBrand.cancelPenaltyCurrency || t('shared.noInformation')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid">
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.changeable')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {translatedChangeableState(fareBrand.changeable)}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.changePenalty')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {fareBrand.changePenalty || t('shared.noInformation')}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.changePenaltyCurrency')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {fareBrand.changePenaltyCurrency || t('shared.noInformation')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid">
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.noShow')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {translatedSupportState(
                            fareBrand.noShow,
                            'no_show_supported',
                            'no_show_not_supported',
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.noShowPenalty')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {fareBrand.noShowPenalty || t('shared.noInformation')}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.noShowPenaltyCurrency')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {fareBrand.noShowPenaltyCurrency || t('shared.noInformation')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid">
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.handLuggage')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {translatedSupportState(
                            fareBrand.handLuggage,
                            'hand_luggage_supported',
                            'hand_luggage_not_supported',
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.handLuggageCount')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {fareBrand.handLuggageCount || t('shared.noInformation')}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.handLuggageWeight')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {fareBrand.handLuggageWeight || t('shared.noInformation')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid">
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.fareBrandNameChange')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {translatedSupportState(
                            fareBrand.fareBrandNameChange,
                            'fare_brand_name_change_supported',
                            'fare_brand_name_change_not_supported',
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.seatReservation')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {translatedInclusionState(
                            fareBrand.seatReservation,
                            'seat_reservation_included',
                            'seat_reservation_not_included',
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.co2Compensation')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {translatedAvailableState(
                            fareBrand.co2Compensation,
                            'co2_compensation_available',
                            'co2_compensation_not_available',
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-bleed fare-brand-show__segment">
                  <div className="fare-brand-show__segment-title">
                    {t('fareBrands.show.airportFacilities')}
                  </div>
                  <div className="grid">
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.loungeAccess')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {translatedAvailableState(
                            fareBrand.loungeAccess,
                            'lounge_access_available',
                            'lounge_access_not_available',
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.priorityBoarding')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {translatedAvailableState(
                            fareBrand.priorityBoarding,
                            'priority_boarding_available',
                            'priority_boarding_not_available',
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.priorityLuggage')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {translatedAvailableState(
                            fareBrand.priorityLuggage,
                            'priority_luggage_available',
                            'priority_luggage_not_available',
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.exclusiveCheckIn')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {translatedSupportState(
                            fareBrand.exclusiveCheckIn,
                            'exclusive_check_in_supported',
                            'exclusive_check_in_not_supported',
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.fastTrackSecurity')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {translatedAvailableState(
                            fareBrand.fastTrackSecurity,
                            'fast_track_security_available',
                            'fast_track_security_not_available',
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.mileUpgrade')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {translatedSupportState(
                            fareBrand.mileUpgrade,
                            'mile_upgrade_supported',
                            'mile_upgrade_not_supported',
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-bleed fare-brand-show__segment">
                  <div className="fare-brand-show__segment-title">
                    {t('fareBrands.show.inFlightFacilities')}
                  </div>
                  <div className="grid">
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.catering')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {translatedInclusionState(
                            fareBrand.catering,
                            'catering_included',
                            'catering_not_included',
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.welcomeDrink')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {translatedAvailableState(
                            fareBrand.welcomeDrink,
                            'welcome_drink_available',
                            'welcome_drink_not_available',
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.digitalMagazines')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {translatedAvailableState(
                            fareBrand.digitalMagazines,
                            'digital_magazines_available',
                            'digital_magazines_not_available',
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.flatbed')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {translatedAvailableState(
                            fareBrand.flatbed,
                            'flatbed_available',
                            'flatbed_not_available',
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-bleed fare-brand-show__segment">
                  <div className="fare-brand-show__segment-title">
                    {t('fareBrands.show.loyaltyProgram')}
                  </div>
                  <div className="grid">
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.milesAward')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {translatedAvailableState(
                            fareBrand.milesAward,
                            'miles_award_available',
                            'miles_award_not_available',
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.milesAwardAmount')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {fareBrand.milesAwardAmount || t('shared.noInformation')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid">
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.milesStatus')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {translatedAvailableState(
                            fareBrand.milesStatus,
                            'miles_status_available',
                            'miles_status_not_available',
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fare-brand-show__segment-infos">
                        <div className="fare-brand-show__segment-infos--field">
                          {t('fareBrands.attributes.milesStatusAmount')}
                        </div>
                        <div className="fare-brand-show__segment-infos--value">
                          {fareBrand.milesStatusAmount || t('shared.noInformation')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return null;
        }}
      </Query>
    </div>
  );
};

export default FareBrand;

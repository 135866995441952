import React from 'react';
import { GET_AIRPORTS, GET_CURSORS_FOR_AIRPORTS } from '../../graphql/queries/airport';
import { DELETE_AIRPORT } from '../../graphql/mutations/airport';
import translatedObjOfDefaultLocale from '../../helpers/translatedObjOfDefaultLocale';
import List from '../common/List';

const AirportList = () => {
  const renderAirportCityName = city => {
    if (city) {
      const translatedEnglishCityName = translatedObjOfDefaultLocale(city.translatedNames);

      return `${translatedEnglishCityName.name}(${translatedEnglishCityName.locale})`;
    }

    return null;
  };
  return (
    <List
      getItems={GET_AIRPORTS}
      getCursorsForItems={GET_CURSORS_FOR_AIRPORTS}
      deleteItem={DELETE_AIRPORT}
      listAttributes={['name', 'code', 'city']}
      itemType="airports"
      modelName="Airport"
      title="nav.airports"
      linkTo="/admin/airports/new"
      buttonLabel="shared.addNew"
      conditionalAttributes={['city']}
      customRenderForConditionalAttributes={renderAirportCityName}
    />
  );
};

export default AirportList;

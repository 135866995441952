import React from 'react';
import { Query } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getRawHotel } from '../../graphql/queries/rawHotel';
import { useQuery } from '@apollo/react-hooks';
import { GET_GOOGLE_MAPS_PUBlIC_API_KEY } from '../../graphql/queries/google_maps_public_api_key';
import Header from '../common/Header';
import RawJSON from '../common/RawJSON';
import WrappedMap from '../common/WrappedMap';

const RawHotel = () => {
  let { id } = useParams();
  const { t } = useTranslation();
  const { data: mapKey } = useQuery(GET_GOOGLE_MAPS_PUBlIC_API_KEY);
  let renderMap;

  return (
    <div className="list">
      <Header
        title={'rawHotels.show.title'}
        linkTo={`/admin/raw_hotels/${id}/edit`}
        buttonLabel={'shared.edit'}
      />
      <Query {...getRawHotel(id)}>
        {({ loading, data }) => {
          if (loading) return <div className="lds-dual-ring" />;
          if (data) {
            const { rawHotel } = data;
            renderMap = mapKey && rawHotel.latitude && rawHotel.longitude;

            return (
              <div className="common-show">
                <div className="grid col-12">
                  <div className="col-12">
                    <div className="common-show__infos">
                      <div className="col-12">
                        <div className="common-show__infos--field">
                          {t('rawHotels.attributes.name')}
                        </div>
                        <div className="common-show__infos--value">
                          {rawHotel.name || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('rawHotels.attributes.code')}
                        </div>
                        <div className="common-show__infos--value">
                          {rawHotel.code || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('rawHotels.attributes.welltravelCode')}
                        </div>
                        <div className="common-show__infos--value">
                          {rawHotel.welltravelCode || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('rawHotels.attributes.provider')}
                        </div>
                        <div className="common-show__infos--value">
                          {rawHotel.provider || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('rawHotels.attributes.iso31661')}
                        </div>
                        <div className="common-show__infos--value">
                          {rawHotel.iso31661 || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('rawHotels.attributes.iso31662')}
                        </div>
                        <div className="common-show__infos--value">
                          {rawHotel.iso31662 || t('shared.notAvailable')}
                        </div>

                        <div className="common-show__infos--field">
                          {t('rawHotels.attributes.latitude')}
                        </div>
                        <div className="common-show__infos--value">
                          {rawHotel.latitude || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('rawHotels.attributes.longitude')}
                        </div>
                        <div className="common-show__infos--value">
                          {rawHotel.longitude || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__map">
                          {renderMap && (
                            <WrappedMap
                              googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${mapKey.googleMapsPublicApiKey}`}
                              latLong={{ lat: rawHotel.latitude, lng: rawHotel.longitude }}
                              infoWindowContent={rawHotel.name}
                            />
                          )}
                        </div>
                        <div className="common-show__infos--field">
                          {t('rawHotels.attributes.description')}
                        </div>
                        <div className="common-show__infos--value">
                          {rawHotel.description || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('rawHotels.attributes.imageUrls')}
                        </div>
                        <RawJSON data={rawHotel.imageUrls} />
                        <div className="common-show__infos--field">
                          {t('rawHotels.attributes.additionalContents')}
                        </div>
                        <RawJSON data={rawHotel.additionalContents} />
                        <div className="common-show__infos--field">
                          {t('rawHotels.attributes.supplierCodes')}
                        </div>
                        <RawJSON data={rawHotel.supplierCodes} />
                        <div className="common-show__infos--field">
                          {t('rawHotels.attributes.rawData')}
                        </div>
                        <RawJSON data={rawHotel.rawData} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return null;
        }}
      </Query>
    </div>
  );
};

export default RawHotel;

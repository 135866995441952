import gql from 'graphql-tag';

export const CREATE_AIRLINE = gql`
  mutation createAirline(
    $name: String!
    $code: String!
    $cabinClassNames: [String!]
    $countryId: ID!
    $url: String
    $facebook: String
    $twitter: String
    $instagram: String
    $description: String
    $publishDescription: Boolean!
    $twitterHandle: String
    $checkInUrl: String
    $legalName: String
    $icaoCode: String
    $addressLine: String
    $postalCode: String
    $city: String
    $state: String
    $deactivated: Boolean!
    $termsAndConditions: String
    $image: Upload
    $logo: Upload
  ) {
    createAirline(
      name: $name
      code: $code
      countryId: $countryId
      url: $url
      facebook: $facebook
      twitter: $twitter
      instagram: $instagram
      description: $description
      publishDescription: $publishDescription
      twitterHandle: $twitterHandle
      checkInUrl: $checkInUrl
      legalName: $legalName
      icaoCode: $icaoCode
      addressLine: $addressLine
      postalCode: $postalCode
      cabinClassNames: $cabinClassNames
      city: $city
      state: $state
      deactivated: $deactivated
      termsAndConditions: $termsAndConditions
      image: $image
      logo: $logo
    ) {
      id
      name
      code
    }
  }
`;

export const EDIT_AIRLINE = gql`
  mutation editAirline(
    $id: ID!
    $name: String
    $code: String
    $cabinClassNames: [String!]
    $countryId: ID
    $url: String
    $facebook: String
    $twitter: String
    $instagram: String
    $description: String
    $publishDescription: Boolean
    $twitterHandle: String
    $checkInUrl: String
    $legalName: String
    $icaoCode: String
    $addressLine: String
    $postalCode: String
    $city: String
    $state: String
    $deactivated: Boolean
    $termsAndConditions: String
    $image: Upload
    $logo: Upload
  ) {
    editAirline(
      id: $id
      name: $name
      code: $code
      countryId: $countryId
      url: $url
      facebook: $facebook
      twitter: $twitter
      instagram: $instagram
      description: $description
      publishDescription: $publishDescription
      twitterHandle: $twitterHandle
      checkInUrl: $checkInUrl
      legalName: $legalName
      icaoCode: $icaoCode
      addressLine: $addressLine
      postalCode: $postalCode
      city: $city
      state: $state
      deactivated: $deactivated
      cabinClassNames: $cabinClassNames
      termsAndConditions: $termsAndConditions
      image: $image
      logo: $logo
    ) {
      id
      code
      name
      logoUrl
      url
      facebook
      twitter
      instagram
      description
      publishDescription
      imageUrls
      checkInUrl
      twitterHandle
      legalName
      icaoCode
      addressLine
      postalCode
      city
      state
      deactivated
      termsAndConditions
      country {
        id
        name
      }
    }
  }
`;

export const DELETE_AIRLINE = gql`
  mutation deleteAirline($id: ID!) {
    deleteAirline(id: $id) {
      code
    }
  }
`;

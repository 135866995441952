import React, { useEffect, useState, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { humanVerifiedHotelsPerPage, maxPageNumberForFullLoad } from '../../variables';
import {
  GET_UNMATCHED_HUMAN_VERIFIED_HOTELS,
  GET_CURSORS_FOR_UNMATCHED_HUMAN_VERIFIED_HOTELS,
} from '../../graphql/queries/humanVerifiedHotel';
import { useTranslation } from 'react-i18next';
import Button from '@bit/mno-wtag.welltravel.button';
import Header from '../common/Header';
import Paginate from '../common/Paginate';
import EmptyContentPlaceholder from '../common/EmptyContentPlaceholder';
import Input from '@bit/mno-wtag.welltravel.input';
import GET_COUNT_OF_TOTAL_CURSORS from '../../graphql/queries/totalCursorsCount';
import { getRawHotelByWelltravelCode } from '../../graphql/queries/rawHotel';
import { EDIT_HUMAN_VERIFIED_HOTEL } from '../../graphql/mutations/humanVerifiedHotel';
import { GET_TOTAL_HUMAN_VERIFIED_HOTELS } from '../../graphql/queries/humanVerifiedHotel';
import { debounce } from 'lodash';
import { Query } from 'react-apollo';
import HotelInformationCard from './HotelInformationCard';
import { all } from '../../graphql/queries/prepareQueryParams';
import CreateNewHotel from './CreateNewHotel';
import isEntityAvailable from '../../helpers/isEntityAvailable';

const UnmatchedHumanVerifiableHotels = () => {
  const { t } = useTranslation();

  const [cursorState, setCursorState] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(3);
  const [currentCursor, setCurrentCursor] = useState(null);
  const [searchParam, setSearchParam] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [cursors, setCursors] = useState([]);
  const [cursorFetchNumber, setCursorFetchNumber] = useState(humanVerifiedHotelsPerPage * 4);
  const [requiredLenghToSearch, setRequiredLengthToSearch] = useState(3);
  const [editHumanVerifiedHotel] = useMutation(EDIT_HUMAN_VERIFIED_HOTEL);

  const {
    loading: totalHotelsQueryLoading,
    data: totalUnmatchedHotels,
    refetch: refetchTotalUnmatchedHotels,
  } = useQuery(GET_TOTAL_HUMAN_VERIFIED_HOTELS, {
    variables: { type: 'unmatched' },
    fetchPolicy: 'network-only',
  });
  const cursorData = useQuery(GET_CURSORS_FOR_UNMATCHED_HUMAN_VERIFIED_HOTELS, {
    variables: { first: cursorFetchNumber, after: currentCursor, query: searchParam },
  });
  const cursorCountData = useQuery(GET_COUNT_OF_TOTAL_CURSORS, {
    variables: { modelName: 'humanVerifiedHotels', query: searchParam },
  });
  const { loading, data } = useQuery(GET_UNMATCHED_HUMAN_VERIFIED_HOTELS, {
    variables: { first: humanVerifiedHotelsPerPage, after: currentCursor, query: searchParam },
  });

  const callSearchParam = useRef(
    debounce(async inputParam => {
      setSearchParam(inputParam);
    }, 1000),
  );

  const forcePage = currentPage - 1;

  useEffect(() => {
    if (cursorCountData && cursorCountData.data && !cursorCountData.loading) {
      const count = cursorCountData.data.totalCursorsCount / humanVerifiedHotelsPerPage;
      if (count <= maxPageNumberForFullLoad) {
        setRequiredLengthToSearch(2);
        setCursorFetchNumber(null);
      } else {
        setRequiredLengthToSearch(3);
        setCursorFetchNumber(humanVerifiedHotelsPerPage * 4);
      }
      setPageCount(Math.ceil(count));
    }
    if (cursorData && cursorData.data && !cursorData.loading && !cursorState) {
      setCursorState(true);
      const oldCursors = Object.assign([], cursors);
      const newCursors = cursorData.data['unmatchedHumanVerifiedHotels'].edges.map(
        edge => edge.cursor,
      );
      const finalCursors = oldCursors.concat(
        newCursors.filter(newCursor => oldCursors.indexOf(newCursor) < 0),
      );
      setCursors(finalCursors);
    }
  }, [cursorCountData, cursorData, cursors, cursorState]);

  const changePage = value => {
    setCurrentPage(value.selected + 1);
    const cursorIndexFromCurrentPage = value.selected * humanVerifiedHotelsPerPage - 1;
    if (cursorIndexFromCurrentPage < 0) {
      setCurrentCursor(null);
    } else {
      setCurrentCursor(cursors[cursorIndexFromCurrentPage]);
    }
  };

  const onSearchParamChange = event => {
    setSearchValue(event.target.value);
    if (event.target.value === '' || event.target.value.length >= requiredLenghToSearch) {
      callSearchParam.current(event.target.value);
      setCurrentCursor(null);
    }
  };

  const submitDiscardToBackEnd = id => {
    const wontDo = true;

    window.confirm(t('humanVerifiedHotels.index.confirmDiscard')) &&
      editHumanVerifiedHotel({
        variables: { id, wontDo },
        refetchQueries: [
          all(
            GET_UNMATCHED_HUMAN_VERIFIED_HOTELS,
            currentCursor,
            searchParam,
            humanVerifiedHotelsPerPage,
          ),
          all(GET_UNMATCHED_HUMAN_VERIFIED_HOTELS, currentCursor, '', humanVerifiedHotelsPerPage),
        ],
      }).then(() => refetchTotalUnmatchedHotels());
  };

  return (
    <div className="list">
      <Header title={t('humanVerifiedHotels.show.unmatchedTitle')}>
        {!totalHotelsQueryLoading && (
          <div className="header__children">{`${t('humanVerifiedHotels.show.unmatchedTotal')}: ${
            totalUnmatchedHotels.totalHumanVerifiedHotels
          }`}</div>
        )}
      </Header>
      <div>
        <Input
          label={t('list.search.label')}
          value={searchValue}
          onChange={onSearchParamChange}
          placeholder={t('humanVerifiedHotels.index.unmatchedSearchPlaceholder')}
        />
      </div>
      {loading && <div className="lds-dual-ring" />}
      {!loading && (
        <React.Fragment>
          {isEntityAvailable(data, 'unmatchedHumanVerifiedHotels') ? (
            <>
              <div className="list__pagination">
                <Paginate
                  pageCount={pageCount}
                  onPageChange={changePage}
                  forcePage={forcePage}
                  marginPagesDisplayed={cursorFetchNumber ? 0 : 1}
                />
              </div>

              <div className="list__table">
                {data['unmatchedHumanVerifiedHotels'].edges.map(verifiableHotel => (
                  <div className="list__data" key={verifiableHotel.node.id}>
                    <div className="unmatched-human-verifiable-hotel">
                      <div className="unmatched-human-verifiable-hotel__container">
                        <p> {t('humanVerifiedHotels.show.unmatchedHotel')}</p>
                        <Query {...getRawHotelByWelltravelCode(verifiableHotel.node.mainSource)}>
                          {({ loading, data }) => {
                            if (loading) return <div className="lds-dual-ring" />;
                            if (data) {
                              const { rawHotel } = data;
                              return (
                                <React.Fragment>
                                  <div
                                    className="unmatched-human-verifiable-hotel__container__hotel-card"
                                    key={rawHotel.id}
                                  >
                                    <HotelInformationCard
                                      id={rawHotel.id}
                                      welltravelCode={rawHotel.welltravelCode}
                                      latitude={rawHotel.latitude}
                                      longitude={rawHotel.longitude}
                                      iso31661={rawHotel.iso31661}
                                      iso31662={rawHotel.iso31662}
                                      supplierCodes={rawHotel.supplierCodes}
                                      name={rawHotel.name}
                                      additionalContents={rawHotel.additionalContents}
                                      itemType="rawHotels"
                                      description={rawHotel.description}
                                      edit={true}
                                    />
                                  </div>
                                  <div className="create-new-hotel__wrapper">
                                    <CreateNewHotel
                                      rawHotel={rawHotel}
                                      humanVerifiedHotelId={verifiableHotel.node.id}
                                      currentCursor={currentCursor}
                                      getHotels={GET_UNMATCHED_HUMAN_VERIFIED_HOTELS}
                                      refetchTotalVerifiableHotels={refetchTotalUnmatchedHotels}
                                    />
                                  </div>
                                </React.Fragment>
                              );
                            }
                          }}
                        </Query>
                        <Button
                          type="darkOverImage"
                          label={t('humanVerifiedHotels.buttons.discard')}
                          onClick={() => submitDiscardToBackEnd(verifiableHotel.node.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="list__pagination">
                <Paginate
                  pageCount={pageCount}
                  onPageChange={changePage}
                  forcePage={forcePage}
                  marginPagesDisplayed={cursorFetchNumber ? 0 : 1}
                />
              </div>
            </>
          ) : (
            <EmptyContentPlaceholder
              entityName={t('nav.unmatchedHumanVerifiedHotels').toLowerCase()}
              modelName={'HumanVerifiedHotel'}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default UnmatchedHumanVerifiableHotels;

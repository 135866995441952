import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../icon/Icon';
import { Link } from 'react-router-dom';

const DashboardCard = props => {
  const { t } = useTranslation();

  return (
    <Link to={props.url} className="dashboard-card">
      <div className="dashboard-card__left">
        <Icon name={props.modelName} />
      </div>

      <div className="dashboard-card__middle">
        <span className="dashboard-card__middle--model-name">{t(`dashboard.models.${props.modelName}`)}</span>

        <span className="dashboard-card__middle--recent-entries">
          <span className="dashboard-card__middle--recent-entries--value">
            +{props.recentEntries}
          </span>
          <span>{t('dashboard.recentEntry')}</span>
        </span>

        <span className="dashboard-card__middle--last-modified-at">
          <span>{t('dashboard.lastModified')}</span>
          <span className="dashboard-card__middle--last-modified-at--value">
            {props.lastModifiedAt || t('shared.notAvailable')}
          </span>
        </span>
      </div>

      <div className="dashboard-card__right">
        <span className="dashboard-card__right--total-entries">
          <span className="dashboard-card__right--total-entries--value">{props.totalEntries}</span>
          <span>{t('dashboard.entries')}</span>
        </span>
      </div>
    </Link>
  );
};

export default DashboardCard;

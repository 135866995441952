import React from 'react';
import { Query } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getAirport } from '../../graphql/queries/airport';
import Header from '../common/Header';
import RenderImage from '../common/RenderImage';
import Pictures from '../unsplashImageSearch/Pictures';

const Airport = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  const renderTranslatedCityNames = city => {
    if (city) {
      return city.translatedNames
        .map(translatedCity => `${translatedCity.name}(${translatedCity.locale})`)
        .join(', ');
    }
    return t('shared.notAvailable');
  };

  return (
    <div className="list">
      <Header
        title="airports.show.title"
        linkTo={`/admin/airports/${id}/edit`}
        buttonLabel="shared.edit"
      />
      <Query {...getAirport(id)}>
        {({ loading, data }) => {
          if (loading) return <div className="lds-dual-ring" />;
          if (data) {
            const { airport } = data;

            return (
              <div className="common-show">
                <div className="grid">
                  <div className="col-12">
                    <div className="common-show__infos">
                      <div className="col-12">
                        <div className="common-show__infos--field">
                          {t('airports.attributes.name')}
                        </div>
                        <div className="common-show__infos--value">{airport.name}</div>
                        <div className="common-show__infos--field">
                          {t('airports.attributes.code')}
                        </div>
                        <div className="common-show__infos--value">{airport.code}</div>
                        <div className="common-show__infos--field">
                          {t('airports.attributes.city')}
                        </div>
                        <div className="common-show__infos--value">
                          {renderTranslatedCityNames(airport.city)}
                        </div>
                        <div className="common-show__infos--field">
                          {t('airports.attributes.place')}
                        </div>
                        <div className="common-show__infos--value">{airport.place.name}</div>
                        <div className="common-show__infos--field">
                          {t('airports.attributes.twitterHandle')}
                        </div>
                        <div className="common-show__infos--value">
                          {airport.twitterHandle || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('airports.attributes.description')}
                        </div>
                        <div className="common-show__infos--value">
                          {airport.description || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('airports.attributes.publishDescription')}
                        </div>
                        <div className="common-show__infos--value">
                          {airport.publishDescription.toString()}
                        </div>
                        <div className="common-show__infos--field">
                          {t('airports.attributes.deactivated')}
                        </div>
                        <div className="common-show__infos--value">
                          {airport.deactivated ? t('shared.true') : t('shared.false')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="common-show__images">
                  <div className="common-show__infos--field">{t('airports.attributes.image')}</div>
                  <RenderImage url={airport.imageUrls.large} />
                  <div className="common-show__infos--field">
                    {t('unsplashImageSearch.labels.unsplashImage')}
                  </div>
                  <Pictures imageableType={'Airport'} imageableId={id} />
                </div>
              </div>
            );
          }
          return null;
        }}
      </Query>
    </div>
  );
};

export default Airport;

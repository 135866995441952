import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_FLIGHTS = gql`
  query flights($first: Int, $after: String, $query: String) {
    flights(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_FLIGHTS = gql`
  query flights($first: Int, $after: String, $query: String) {
    flights(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          flightNumber
          onTimePercentage
          wifiAvailability
          airline {
            id
            name
          }
        }
      }
    }
  }
`;

export const getFlights = after => all(GET_FLIGHTS, after);

export const GET_FLIGHT = gql`
  query flight($id: ID!) {
    flight(id: $id) {
      flightNumber
      onTimePercentage
      wifiAvailability
      airline {
        id
        name
      }
    }
  }
`;

export const getFlight = id => one(GET_FLIGHT, id);

import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_KEYWORDS = gql`
  query keywords($first: Int, $after: String, $query: String) {
    keywords(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_KEYWORDS = gql`
  query keywords($first: Int, $after: String, $query: String) {
    keywords(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

export const getKeywords = after => all(GET_KEYWORDS, after);

export const GET_KEYWORD = gql`
  query keyword($id: ID!) {
    keyword(id: $id) {
      id
      name
    }
  }
`;

export const getKeyword = id => one(GET_KEYWORD, id);

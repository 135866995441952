import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import Button from '@bit/mno-wtag.welltravel.button';
import Input from '@bit/mno-wtag.welltravel.input';

import BaseModal from '../rclComponents/BaseModal';

import { GET_KEYWORDS } from '../../graphql/queries/keyword';
import { CREATE_KEYWORD } from '../../graphql/mutations/keyword';

import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import { debounce } from 'lodash';

const Keyword = props => {
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState('');
  const { client, loading, data } = useQuery(GET_KEYWORDS);
  const [createKeyword, callbackDataOnCreate] = useMutation(CREATE_KEYWORD);

  const submitToBackEnd = debounce(() => {
    props.resetError();

    createKeyword({
      variables: { name: keyword },
    })
      .then(() => {
        client.resetStore();
        setKeyword('');
      })
      .catch(() => props.onError());
  }, 500);

  return (
    <BaseModal
      linkText={t('glossaries.attributes.keywords')}
      linkClassName="button"
      defaultPadding={true}
      header={t('glossaries.attributes.keywords')}
    >
      <div className="keyword-content">
        <span className="hashtag-content__header">{t('glossaries.index.currentKeywords')}</span>
        <div className="keyword-content__list">
          {!loading && data.keywords.edges.map(edge => edge.node.name).join(', ')}
        </div>

        <div className="keyword-content__add-new">
          <Input
            label={t('shared.addNew')}
            value={keyword}
            onChange={event => setKeyword(event.target.value)}
          />
        </div>
        {props.hasError && <ErrorComponent error={{ callbackDataOnCreate }} />}
        <div className="keyword-content__submit">
          <Button label={t('shared.create')} onClick={submitToBackEnd} disabled={!keyword} />
        </div>
      </div>
    </BaseModal>
  );
};

export default WithErrorHandler(Keyword);

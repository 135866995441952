import React from 'react';
import {
  GET_VISA_PROCUREMENT_CATEGORIES,
  GET_CURSORS_FOR_VISA_PROCUREMENT_CATEGORIES,
} from '../../graphql/queries/visaProcurementCategory';
import { DELETE_VISA_PROCUREMENT_CATEGORY } from '../../graphql/mutations/visaProcurementCategory';
import List from '../common/List';

const VisaProcurementCategoryList = () => {
  return (
    <List
      getItems={GET_VISA_PROCUREMENT_CATEGORIES}
      getCursorsForItems={GET_CURSORS_FOR_VISA_PROCUREMENT_CATEGORIES}
      deleteItem={DELETE_VISA_PROCUREMENT_CATEGORY}
      disableDeletion={true}
      listAttributes={['name', 'visaCategory']}
      itemType="visaProcurementCategories"
      modelName="VisaProcurementCategory"
      title="nav.visaProcurementCategories"
    />
  );
};

export default VisaProcurementCategoryList;

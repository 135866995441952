import gql from 'graphql-tag';

export const CREATE_ANCILLARY_CATEGORY = gql`
  mutation createAncillaryCategory($identifier: String!) {
    createAncillaryCategory(identifier: $identifier) {
      id
      identifier
    }
  }
`;

export const EDIT_ANCILLARY_CATEGORY = gql`
  mutation editAncillaryCategory($id: ID!, $identifier: String!) {
    editAncillaryCategory(id: $id, identifier: $identifier) {
      id
      identifier
    }
  }
`;

export const DELETE_ANCILLARY_CATEGORY = gql`
  mutation deleteAncillaryCategory($id: ID!) {
    deleteAncillaryCategory(id: $id) {
      identifier
    }
  }
`;

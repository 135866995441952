import React from 'react';
import { GET_FEATURES, GET_CURSORS_FOR_FEATURES } from '../../graphql/queries/feature';
import { DELETE_FEATURE } from '../../graphql/mutations/feature';
import List from '../common/List';

const FeatureList = () => {
  return (
    <List
      getItems={GET_FEATURES}
      getCursorsForItems={GET_CURSORS_FOR_FEATURES}
      deleteItem={DELETE_FEATURE}
      listAttributes={['name', 'icon']}
      itemType="features"
      modelName="Feature"
      title="nav.features"
      linkTo="/admin/features/new"
      buttonLabel="shared.addNew"
    />
  );
};

export default FeatureList;

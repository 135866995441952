import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_CITIES = gql`
  query cities($first: Int, $after: String, $query: String) {
    cities(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_CITIES = gql`
  query cities($first: Int, $after: String, $query: String) {
    cities(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          translatedNames {
            id
            name
            locale
          }
        }
      }
    }
  }
`;

export const getCities = after => all(GET_CITIES, after);

export const GET_CITY = gql`
  query city($id: ID!) {
    city(id: $id) {
      id
      translatedNames {
        id
        name
        locale
      }
    }
  }
`;

export const getCity = id => one(GET_CITY, id);

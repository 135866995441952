import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_PLACE_SYNONYMS = gql`
  query placeSynonyms($first: Int, $after: String, $query: String) {
    placeSynonyms(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_PLACE_SYNONYMS = gql`
  query placeSynonyms($first: Int, $after: String, $query: String) {
    placeSynonyms(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          name
          geonameIdentifier
          locale
          place {
            id
            name
          }
        }
      }
    }
  }
`;

export const getPlaceSynonyms = after => all(GET_PLACE_SYNONYMS, after);

export const GET_PLACE_SYNONYM = gql`
  query placeSynonym($id: ID!) {
    placeSynonym(id: $id) {
      name
      geonameIdentifier
      locale
      place {
        id
        name
      }
    }
  }
`;

export const getPlaceSynonym = id => one(GET_PLACE_SYNONYM, id);

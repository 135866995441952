// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !! COPIED FROM RCL as it's not available in Meta, yet !!
// !! Please don't modify                                !!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import {
  Tab as ReactTab,
  Tabs as ReactTabs,
  TabList as ReactTabList,
  TabPanel as ReactTabPanel,
} from 'react-tabs';

const tabSize = size => {
  switch (size) {
    case 'large':
      return 'r-tabs--large';
    case 'small':
      return 'r-tabs--small';
    default:
      return null;
  }
};

const Tabs = props => {
  const { children, dark, size, ...others } = props;
  return (
    <ReactTabs
      className={classNames('r-tabs', tabSize(size), {
        'r-tabs-dark': dark,
      })}
      selectedTabPanelClassName="r-tabs__tab-panel--selected"
      {...others}
    >
      {children}
    </ReactTabs>
  );
};

Tabs.tabsRole = 'Tabs';
const Tab = props => {
  const { children, ...others } = props;

  return (
    <ReactTab
      className="r-tabs__tab"
      selectedClassName="r-tabs__tab--selected"
      disabledClassName="r-tabs__tab--disabled"
      {...others}
    >
      {children}
    </ReactTab>
  );
};
Tab.tabsRole = 'Tab';

const TabList = props => {
  const { children, ...others } = props;

  return (
    <ReactTabList className="r-tabs__tab-list" {...others}>
      {children}
    </ReactTabList>
  );
};
TabList.tabsRole = 'TabList';

const TabPanel = props => {
  const { children, ...others } = props;

  return (
    <ReactTabPanel className="r-tabs__tab-panel" {...others}>
      {children}
    </ReactTabPanel>
  );
};
TabPanel.tabsRole = 'TabPanel';

Tabs.defaultProps = {
  dark: false,
  size: null,
};

Tabs.propTypes = {
  dark: PropTypes.bool,
  size: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Tab.propTypes = {
  children: PropTypes.node.isRequired,
};

TabList.propTypes = {
  children: PropTypes.node.isRequired,
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Tabs, Tab, TabList, TabPanel };

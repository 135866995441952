// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !! COPIED FROM RCL as it's not available in Meta, yet !!
// !! Please don't modify                                !!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Badge extends React.Component {
  badgeType = type => {
    switch (type) {
      case 'inactive':
        return 'badge--inactive';
      case 'active':
        return 'badge--active';
      case 'new':
        return 'badge--new';
      default:
        return null;
    }
  };

  badgeSize = size => {
    switch (size) {
      case 'small':
        return 'badge--small';
      case 'normal':
        return 'badge--normal';
      default:
        return null;
    }
  };

  getTheNumber = label => {
    return label > 99 ? `${99}+` : label;
  };

  render() {
    const { type, size, classNameForBadge, label } = this.props;

    return (
      <div
        className={classNames('badge', `badge--${type}`, `badge--${size}`, classNameForBadge)}
        role="presentation"
      >
        {label && <span className="badge--number">{this.getTheNumber(label)}</span>}
      </div>
    );
  }
}

Badge.defaultProps = {
  classNameForBadge: null,
  label: null,
  type: 'new',
  size: 'small',
};

Badge.propTypes = {
  classNameForBadge: PropTypes.string,
  label: PropTypes.number,
  type: PropTypes.oneOf(['inactive', 'active', 'new']),
  size: PropTypes.oneOf(['small', 'normal']),
};

export default Badge;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Button from '@bit/mno-wtag.welltravel.button';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GET_CITY } from '../../graphql/queries/city';
import { CREATE_CITY, EDIT_CITY } from '../../graphql/mutations/city';
import clearCache from '../../helpers/clearCache';
import normalizeMultiFormAttributes from '../../helpers/normalizeMultiFormAttributes';
import ErrorComponent from '../common/ErrorComponent';
import NameTranslationForm from '../common/NameTranslationForm';
import WithErrorHandler from '../common/WithErrorHandler';
import { getCity } from '../../graphql/queries/city';
import { DEFAULT_LOCALE } from '../../variables';

const CityForm = props => {
  const { t } = useTranslation();

  let { id } = useParams();
  const isNewRecord = !id;

  const [createCity, callbackDataOnCreate] = useMutation(CREATE_CITY, {
    update: cache => clearCache(cache, /City/),
  });
  const [editCity, callbackDataOnEdit] = useMutation(EDIT_CITY);
  const cityFromId = useQuery(GET_CITY, {
    variables: { id },
    skip: isNewRecord,
  });

  const [translationsAttributes, setTranslationsAttributes] = useState([
    { id: uuidv4(), locale: DEFAULT_LOCALE, name: '' },
  ]);

  useEffect(() => {
    if (cityFromId && cityFromId.data && !cityFromId.loading) {
      setTranslationsAttributes(cityFromId.data.city.translatedNames);
    }
  }, [cityFromId]);

  const submitToBackEnd = debounce(() => {
    props.resetError();

    if (isNewRecord) {
      createCity({
        variables: {
          translationsAttributes: normalizeMultiFormAttributes(translationsAttributes),
        },
      })
        .then(({ data }) => {
          props.history.push(`/admin/cities/${data.createCity.id}`);
        })
        .catch(() => props.onError());
    } else {
      editCity({
        variables: {
          id,
          translationsAttributes: normalizeMultiFormAttributes(translationsAttributes),
        },
        refetchQueries: [getCity(id)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/cities/${data.editCity.id}`);
        })
        .catch(() => props.onError());
    }
  }, 500);

  return (
    <div className="common-form">
      <div className="common-form__title">
        {isNewRecord
          ? t('shared.new', { entityName: t('cities.show.title') })
          : t('shared.editTitle', { entityName: t('cities.show.title') })}
      </div>
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit, callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-9 col-bleed">
            {translationsAttributes &&
              translationsAttributes.map(
                (translationsAttribute, index) =>
                  !translationsAttribute._destroy && (
                    <NameTranslationForm
                      key={translationsAttribute.id}
                      translationsAttribute={translationsAttribute}
                      formIndex={index}
                      translationsAttributes={translationsAttributes}
                      setTranslationsAttributes={setTranslationsAttributes}
                    />
                  ),
              )}
          </div>
          <div className="common-form__submit-button">
            <div className="col-2">
              <Button
                label={isNewRecord ? t('shared.create') : t('shared.update')}
                onClick={submitToBackEnd}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithErrorHandler(CityForm);

import React from 'react';
import { Query } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getAirline } from '../../graphql/queries/airline';
import Header from '../common/Header';
import RenderImage from '../common/RenderImage';
import Pictures from '../unsplashImageSearch/Pictures';

const Airline = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  return (
    <div className="list">
      <Header
        title="airlines.show.title"
        linkTo={`/admin/airlines/${id}/edit`}
        buttonLabel="shared.edit"
      />
      <Query {...getAirline(id)}>
        {({ loading, data }) => {
          if (loading) return <div className="lds-dual-ring" />;
          if (data) {
            const { airline } = data;

            const translatedCabinClasses = airline.cabinClasses.map(cabinClass =>
              t(`fareBrands.attributes.${cabinClass.name}`),
            );

            return (
              <div className="common-show">
                <div className="grid">
                  <div className="col-12">
                    <div className="common-show__infos">
                      <div className="col-12">
                        <div className="common-show__infos--field">
                          {t('airlines.attributes.name')}
                        </div>
                        <div className="common-show__infos--value">{airline.name}</div>
                        <div className="common-show__infos--field">
                          {t('airlines.attributes.code')}
                        </div>
                        <div className="common-show__infos--value">{airline.code}</div>
                        <div className="common-show__infos--field">
                          {t('airlines.attributes.icaoCode')}
                        </div>
                        <div className="common-show__infos--value">
                          {airline.icaoCode || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('airlines.attributes.url')}
                        </div>
                        <div className="common-show__infos--value">
                          {airline.url || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('airlines.attributes.twitter')}
                        </div>
                        <div className="common-show__infos--value">
                          {airline.twitter || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('fareBrands.attributes.cabinClass')}
                        </div>
                        <div className="common-show__infos--value">
                          {translatedCabinClasses.length > 0
                            ? translatedCabinClasses.join(', ')
                            : t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('airlines.attributes.legalName')}
                        </div>
                        <div className="common-show__infos--value">
                          {airline.legalName || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('airlines.attributes.addressLine')}
                        </div>
                        <div className="common-show__infos--value">
                          {airline.addressLine || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('airlines.attributes.postalCode')}
                        </div>
                        <div className="common-show__infos--value">
                          {airline.postalCode || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('airlines.attributes.city')}
                        </div>
                        <div className="common-show__infos--value">
                          {airline.city || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('airlines.attributes.state')}
                        </div>
                        <div className="common-show__infos--value">
                          {airline.state || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('airlines.attributes.country')}
                        </div>
                        <div className="common-show__infos--value">{airline.country.name}</div>
                        <div className="common-show__infos--field">
                          {t('airlines.attributes.description')}
                        </div>
                        <div className="common-show__infos--value">
                          {airline.description || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('airlines.attributes.publishDescription')}
                        </div>
                        <div className="common-show__infos--value">
                          {airline.publishDescription.toString()}
                        </div>
                        <div className="common-show__infos--field">
                          {t('airlines.attributes.deactivated')}
                        </div>
                        <div className="common-show__infos--value">
                          {airline.deactivated.toString()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="common-show__images">
                  <div className="common-show__infos--field">{t('airlines.attributes.logo')}</div>
                  <RenderImage url={airline.logoUrl} />
                  <div className="common-show__infos--field">{t('airlines.attributes.image')}</div>
                  <RenderImage url={airline.imageUrls.large} />

                  <div className="common-show__infos--field">
                    {t('unsplashImageSearch.labels.unsplashImage')}
                  </div>
                  <Pictures imageableType={'Airline'} imageableId={id} />
                </div>
              </div>
            );
          }
          return null;
        }}
      </Query>
    </div>
  );
};

export default Airline;

import React from 'react';
import { GET_RAW_HOTELS, GET_CURSORS_FOR_RAW_HOTELS } from '../../graphql/queries/rawHotel';
import List from '../common/List';

const RawHotelList = () => {
  return (
    <List
      getItems={GET_RAW_HOTELS}
      getCursorsForItems={GET_CURSORS_FOR_RAW_HOTELS}
      listAttributes={['name', 'code', 'welltravelCode']}
      disableDeletion={true}
      itemType="rawHotels"
      modelName="RawHotel"
      title="nav.rawHotels"
      showSearchAttributes={true}
      searchAttributes={['name', 'code', 'welltravelCode', 'provider', 'iso', 'place', 'supplierCodes']}
      minLengthToSearch={2}
    />
  );
};

export default RawHotelList;

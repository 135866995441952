import React from 'react';
import { Query } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../common/Header';
import SanitizedHTML from '../sanitizedHTML';
import { getTravelNews } from '../../graphql/queries/travelNews';
import RenderImage from '../common/RenderImage';
import isQuillEmpty from '../../helpers/isQuillEmpty';

const TravelNews = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  return (
    <div className="list">
      <Header
        title="travelNews.show.title"
        linkTo={`/admin/travel_news/${id}/edit`}
        buttonLabel="shared.edit"
      />
      <Query {...getTravelNews(id)}>
        {({ loading, data }) => {
          if (loading) return <div className="lds-dual-ring" />;
          if (data) {
            const { travelNewsOne: travelNews } = data;
            const hashtags = travelNews.hashtags.map(keyword => keyword.name).join(', ');

            return (
              <div className="common-show">
                <div className="grid">
                  <div className="col-12">
                    <div className="common-show__infos">
                      <div className="col-12">
                        <div className="common-show__infos--field">
                          {t('travelNews.attributes.title')}
                        </div>
                        <div className="common-show__infos--value">
                          {travelNews.title || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('travelNews.attributes.teaser')}
                        </div>
                        <div className="common-show__infos--value">
                          {travelNews.teaser || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('travelNews.attributes.source')}
                        </div>
                        <div className="common-show__infos--value">
                          {travelNews.source || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('travelNews.attributes.externalLink')}
                        </div>
                        <div className="common-show__infos--value">
                          {travelNews.externalLink || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('travelNews.attributes.author')}
                        </div>
                        <div className="common-show__infos--value">
                          {travelNews.author || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('travelNews.attributes.platform')}
                        </div>
                        <div className="common-show__infos--value">
                          {travelNews.platform
                            ? t(`categories.${travelNews.platform}`)
                            : t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('travelNews.attributes.city')}
                        </div>
                        <div className="common-show__infos--value">
                          {travelNews.place ? travelNews.place.name : t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('travelNews.attributes.country')}
                        </div>
                        <div className="common-show__infos--value">
                          {travelNews.country ? travelNews.country.name : t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('travelNews.attributes.hashtags')}
                        </div>
                        <div className="common-show__infos--value">
                          {hashtags || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('travelNews.attributes.content')}
                        </div>
                        <div className="common-show__infos--value">
                          {isQuillEmpty(travelNews.content) ? (
                            t('shared.notAvailable')
                          ) : (
                            <SanitizedHTML html={travelNews.content} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="common-show__images">
                  <div className="common-show__infos--field">{t('travelNews.attributes.image')}</div>
                  <RenderImage url={travelNews.imageUrls.large} />
                </div>
              </div>
            );
          }
          return null;
        }}
      </Query>
    </div>
  );
};

export default TravelNews;

import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_ANCILLARY_CATEGORIES = gql`
  query ancillaryCategories($first: Int,$after: String, $query: String) {
    ancillaryCategories(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_ANCILLARY_CATEGORIES = gql`
  query ancillaryCategories($first: Int, $after: String, $query: String) {
    ancillaryCategories(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          identifier
        }
      }
    }
  }
`;

export const getAncillaryCategories = after => all(GET_ANCILLARY_CATEGORIES, after);

export const GET_ANCILLARY_CATEGORY = gql`
  query ancillaryCategory($id: ID!) {
    ancillaryCategory(id: $id) {
      identifier
    }
  }
`;

export const getAncillaryCategory = id => one(GET_ANCILLARY_CATEGORY, id);

import React from 'react';
import { Query } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getContinent } from '../../graphql/queries/continent';
import Header from '../common/Header';

const Continent = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  return (
    <div className="list">
      <Header
        title="continents.show.title"
        linkTo={`/admin/continents/${id}/edit`}
        buttonLabel="shared.edit"
      />
      <Query {...getContinent(id)}>
        {({ loading, data }) => {
          if (loading) return <div className="lds-dual-ring" />;
          if (data) {
            const { continent } = data;

            return (
              <div className="common-show">
                <div className="grid">
                  <div className="col-12">
                    <div className="common-show__infos">
                      <div className="col-12">
                        <div className="common-show__infos--field">
                          {t('continents.attributes.name')}
                        </div>
                        <div className="common-show__infos--value">{continent.name}</div>
                        <div className="common-show__infos--field">
                          {t('continents.attributes.code')}
                        </div>
                        <div className="common-show__infos--value">{continent.code}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return null;
        }}
      </Query>
    </div>
  );
};

export default Continent;

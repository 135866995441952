import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';

import Button from '@bit/mno-wtag.welltravel.button';
import Input from '@bit/mno-wtag.welltravel.input';
import SelectBox from '@bit/mno-wtag.welltravel.select-box';
import MultiSelectBox from '../rclComponents/selectBox';
import TextEditor from '../common/TextEditor';

import {
  GET_GLOSSARY,
  GET_GLOSSARY_CATEGORIES,
  GET_GLOSSARY_SUBCATEGORIES,
} from '../../graphql/queries/glossary';

import { GET_KEYWORDS } from '../../graphql/queries/keyword';
import { CREATE_GLOSSARY, EDIT_GLOSSARY } from '../../graphql/mutations/glossary';
import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import { getGlossary } from '../../graphql/queries/glossary';

import clearCache from '../../helpers/clearCache';
import returnEmptyStringWhenNull from '../../helpers/returnEmptyStringWhenNull';
import NoResultsFound from '../common/NoResultsFound';
import { debounce } from 'lodash';

const GlossaryForm = props => {
  const { t } = useTranslation();
  let { id } = useParams();
  const isNewRecord = !id;

  const [createGlossary, callbackDataOnCreate] = useMutation(CREATE_GLOSSARY, {
    update: cache => clearCache(cache, /Glossary/),
  });

  const [editGlossary, callbackDataOnEdit] = useMutation(EDIT_GLOSSARY);

  const glossaryFromId = useQuery(GET_GLOSSARY, {
    variables: { id },
    skip: isNewRecord,
  });

  const { loading: categoryListLoading, data: categoryListData } = useQuery(
    GET_GLOSSARY_CATEGORIES,
  );

  const { loading: subCategoryListLoading, data: subCategoryListData } = useQuery(
    GET_GLOSSARY_SUBCATEGORIES,
  );

  const { loading: keywordListLoading, data: keywordList } = useQuery(GET_KEYWORDS);

  const [term, setTerm] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [definition, setDefinition] = useState('');
  const [keywords, setKeywords] = useState([]);

  const onTermChange = event => {
    setTerm(event.target.value);
  };

  const onCategoryChange = value => {
    setCategory(value);
  };

  const onSubCategoryChange = value => {
    setSubCategory(value);
  };

  const onKeywordsChange = value => {
    setKeywords(value);
  };

  const onDefinitionChange = value => {
    setDefinition(value);
  };

  useEffect(() => {
    if (glossaryFromId && glossaryFromId.data && !glossaryFromId.loading) {
      setTerm(glossaryFromId.data.glossary.term);
      setCategory(returnEmptyStringWhenNull(glossaryFromId.data.glossary.category));
      setSubCategory(glossaryFromId.data.glossary.subCategory);
      setKeywords(
        glossaryFromId.data.glossary.keywords.map(keyword => ({
          value: keyword.id,
          label: keyword.name,
        })),
      );
      setDefinition(glossaryFromId.data.glossary.definition);
    }
  }, [glossaryFromId]);

  const submitToBackEnd = debounce(() => {
    props.resetError();
    const keywordIds = keywords && keywords.map(keyword => keyword.value);
    if (isNewRecord) {
      createGlossary({
        variables: { term, category, subCategory, keywordIds, definition },
      })
        .then(({ data }) => {
          props.history.push(`/admin/glossaries/${data.createGlossary.id}`);
        })
        .catch(() => props.onError());
    } else {
      editGlossary({
        variables: { id, term, category, subCategory, keywordIds, definition },
        refetchQueries: [getGlossary(id)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/glossaries/${data.editGlossary.id}`);
        })
        .catch(() => props.onError());
    }
  }, 500);

  let categoryOptions = [];
  if (!categoryListLoading) {
    categoryOptions = categoryListData.glossaryCategories.map(category => ({
      value: category.value,
      label: t(`categories.${category.value}`),
    }));
  }

  let subCategoryOptions = [];
  if (!subCategoryListLoading) {
    subCategoryOptions = subCategoryListData.glossarySubCategories.map(subCategory => ({
      value: subCategory.value,
      label: t(`subCategories.${subCategory.value}`),
    }));
  }

  let keywordOptions = [];
  if (!keywordListLoading) {
    keywordOptions = keywordList.keywords.edges.map(keyword => ({
      value: keyword.node.id,
      label: keyword.node.name,
    }));
  }

  return (
    <div className="common-form">
      <div className="common-form__title">
        {isNewRecord ?
          t('shared.new', { entityName: t('glossaries.show.title') })
          : t('shared.editTitle', { entityName: t('glossaries.show.title') })}
      </div>
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit, callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-lg-8 col-md-10 col-sm-10 col-xs-10">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-bleed-y">
              <div className="common-form__field glossary-form__term">
                <Input label={t('glossaries.attributes.term')} value={term} onChange={onTermChange} />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field glossary-form__category">
                <SelectBox
                  label={t('glossaries.attributes.category')}
                  value={category}
                  options={categoryOptions}
                  onChange={onCategoryChange}
                  renderNoResultsFound={() => <NoResultsFound />}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field glossary-form__subCategory">
                <SelectBox
                  label={t('glossaries.attributes.subCategory')}
                  value={subCategory}
                  options={subCategoryOptions}
                  onChange={onSubCategoryChange}
                  renderNoResultsFound={() => <NoResultsFound />}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field glossary-form__keywords">
                <MultiSelectBox
                  isMulti={true}
                  label={t('glossaries.attributes.keywords')}
                  value={keywords}
                  options={keywordOptions}
                  onChange={onKeywordsChange}
                />
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-bleed-y">
              <div className="common-form__field glossary-form__definition">
                <h2>{t('glossaries.attributes.definition')}</h2>
                <TextEditor customModule={true} value={definition} onChange={onDefinitionChange} />
              </div>
              <div className="common-form__submit-button">
                <Button
                  label={isNewRecord ? t('shared.create') : t('shared.update')}
                  onClick={submitToBackEnd}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithErrorHandler(GlossaryForm);

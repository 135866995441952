import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_STATIC_MESSAGES = gql`
  query staticMessages($first: Int, $after: String, $query: String) {
    staticMessages(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_STATIC_MESSAGES = gql`
  query staticMessages($first: Int, $after: String, $query: String) {
    staticMessages(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          key
          message
        }
      }
    }
  }
`;

export const getStaticMessages = after => all(GET_STATIC_MESSAGES, after);

export const GET_STATIC_MESSAGE = gql`
  query staticMessage($id: ID!) {
    staticMessage(id: $id) {
      key
      message
    }
  }
`;

export const getStaticMessage = id => one(GET_STATIC_MESSAGE, id);

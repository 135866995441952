import React from 'react';
import Button from '@bit/mno-wtag.welltravel.button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = props => {
  const { t } = useTranslation();

  return (
    <div className="header">
      <div className="header__title">{t(props.title)}</div>
      <div className="header__actions">
        {props.linkTo && (
          <div>
            <Link to={props.linkTo}>
              <Button label={t(props.buttonLabel)} />
            </Link>
          </div>
        )}
        {props.children}
      </div>
    </div>
  );
};

export default Header;

import { itemsPerPage } from '../../variables';

export const all = (query, after, searchQuery, numberOfItems = itemsPerPage) => {
  return { query, ...queryVariables(after, searchQuery, numberOfItems) };
};

export const one = (query, id) => {
  return { query, variables: { id } };
};

export const oneWithUserDefinedKey = (query, key, value) => {
  return { query, variables: { [key]: value } };
};

export const queryVariables = (after = null, searchQuery = '', numberOfItems = itemsPerPage) => {
  return {
    variables: { first: numberOfItems, after, query: searchQuery },
  };
};

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';

import Button from '@bit/mno-wtag.welltravel.button';
import Input from '@bit/mno-wtag.welltravel.input';
import SelectBox from '@bit/mno-wtag.welltravel.select-box';
import MultiSelectBox from '../rclComponents/selectBox';
import TextEditor from '../common/TextEditor';

import { GET_FAQ, GET_FAQ_CATEGORIES, GET_FAQS } from '../../graphql/queries/faq';

import { GET_HASHTAGS } from '../../graphql/queries/hashtag';
import { CREATE_FAQ, EDIT_FAQ } from '../../graphql/mutations/faq';
import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import { getFaq } from '../../graphql/queries/faq';

import clearCache from '../../helpers/clearCache';

import PropTypes from 'prop-types';
import { all } from '../../graphql/queries/prepareQueryParams';
import NoResultsFound from '../common/NoResultsFound';
import { debounce } from 'lodash';

const FaqForm = props => {
  const { t } = useTranslation();
  let { id } = useParams();
  const isNewRecord = !id;

  const userId = props.userId;

  const [createFaq, callbackDataOnCreate] = useMutation(CREATE_FAQ, {
    update: cache => clearCache(cache, /Faq/),
  });

  const [editFaq, callbackDataOnEdit] = useMutation(EDIT_FAQ);

  const faqFromId = useQuery(GET_FAQ, {
    variables: { id },
    skip: isNewRecord,
  });

  const { loading: faqListLoading, data: faqListData } = useQuery(GET_FAQ_CATEGORIES, {
    variables: { id },
  });

  const { loading: hashtagListLoading, data: hashtagList } = useQuery(GET_HASHTAGS);

  const [title, setTitle] = useState('');
  const [thumbsUp, setThumbsUp] = useState(0);
  const [thumbsDown, setThumbsDown] = useState(0);
  const [description, setDescription] = useState('');
  const [parentId, setParentId] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [updatedBy, setUpddatedBy] = useState('');
  const [hashtags, setHashtags] = useState([]);

  const onTitleChange = event => {
    setTitle(event.target.value);
  };

  const onFaqLabelChange = value => {
    setParentId(value);
  };

  const onHashtagsChange = value => {
    setHashtags(value);
  };

  const onDescriptionChange = value => {
    setDescription(value);
  };

  useEffect(() => {
    if (faqFromId && faqFromId.data && !faqFromId.loading) {
      const faqData = faqFromId.data.faq;
      setTitle(faqData.title);
      setHashtags(
        faqFromId.data.faq.hashtags.map(hashtag => ({
          value: hashtag.id,
          label: hashtag.name,
        })),
      );
      setDescription(faqData.description);
      setThumbsUp(faqData.thumbsUp);
      setThumbsDown(faqData.thumbsDown);
      setParentId(faqData.parentId);

      setUpdatedAt(faqData.updatedAt);
      setUpddatedBy(faqData.updatedBy && faqData.updatedBy.name);
    }
  }, [faqFromId]);

  const resetRating = () => {
    setThumbsDown(0);
    setThumbsUp(0);
  };

  const submitToBackEnd = debounce(() => {
    props.resetError();
    const hashtagIds = hashtags && hashtags.map(hashtag => hashtag.value);
    if (isNewRecord) {
      createFaq({
        variables: { title, description, parentId, userId, hashtagIds },
        refetchQueries: [all(GET_FAQ_CATEGORIES)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/faqs/${data.createFaq.id}`);
        })
        .catch(() => props.onError());
    } else {
      editFaq({
        variables: { id, title, description, thumbsUp, thumbsDown, parentId, hashtagIds, userId },
        refetchQueries: [all(GET_FAQS, null, ''), getFaq(parentId), getFaq(id)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/faqs/${data.editFaq.id}`);
        })
        .catch(() => props.onError());
    }
  }, 500);

  let faqLabelOptions = [];
  if (!faqListLoading) {
    faqLabelOptions = faqListData.faqCategories.map(faq => ({
      value: faq.value,
      label: faq.label,
    }));
  }

  let hashtagOptions = [];
  if (!hashtagListLoading) {
    hashtagOptions = hashtagList.hashtags.edges.map(hashtag => ({
      value: hashtag.node.id,
      label: hashtag.node.name,
    }));
  }

  return (
    <div className="common-form">
      <div className="common-form__title">
        {isNewRecord ?
          t('shared.new', { entityName: t('faqs.show.title') })
          : t('shared.editTitle', { entityName: t('faqs.show.title') })}
      </div>
      {!isNewRecord && (
        <div className="common-form__header">
          {t('faqs.attributes.lastModified')}: {updatedAt}({updatedBy})
        </div>
      )}
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit, callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-lg-8 col-md-10 col-sm-10 col-xs-10">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-bleed-y">
              <div className="common-form__field faq-form__title">
                <Input label={t('faqs.attributes.title')} value={title} onChange={onTitleChange} />
              </div>
              <div className="common-form__field faq-form__faq-label">
                <SelectBox
                  width="large"
                  label={t('faqs.attributes.label')}
                  value={parentId}
                  options={faqLabelOptions}
                  onChange={onFaqLabelChange}
                  renderNoResultsFound={() => <NoResultsFound />}
                />
              </div>
              <div className="common-form__field faq-form__description">
                <h2>{t('faqs.attributes.description')}</h2>
                <TextEditor customModule={true} value={description} onChange={onDescriptionChange} />
              </div>
              <div className="common-form__field-multi-select-box faq-form__hashtags">
                <MultiSelectBox
                  width="large"
                  isMulti={true}
                  label={t('faqs.attributes.keywords')}
                  value={hashtags}
                  options={hashtagOptions}
                  onChange={onHashtagsChange}
                />
              </div>
              {!isNewRecord && (
                <div className="common-show__value faq-form__rating">
                  <h2>
                    {t('faqs.attributes.rating')}: {thumbsUp}/{thumbsDown}
                  </h2>
                  <Button label={t('faqs.buttons.resetRating')} onClick={resetRating} />
                </div>
              )}
              <div className="common-form__submit-button">
                <Button
                  label={isNewRecord ? t('shared.create') : t('shared.update')}
                  onClick={submitToBackEnd}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

FaqForm.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default WithErrorHandler(FaqForm);

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !! COPIED FROM RCL as it's not available in Meta, yet !!
// !! Please don't modify                                !!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../icon/Icon';

class BaseModal extends React.Component {
  constructor(props) {
    super(props);
    this.modalId = `modal-${Math.random()}`;
    this.state = {
      open: props.initialOpenValue,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open) {
      this.changeModal(this.props.open);
    }
  }

  changeModal = value => {
    this.setState({ open: value });
  };

  modalSize = size => {
    switch (size) {
      case 'large':
        return 'base-modal--large';
      case 'huge':
        return 'base-modal--huge';
      case 'small':
        return 'base-modal--small';
      default:
        return null;
    }
  };

  toggleModal = () => {
    this.setState(
      prevState => ({ open: !prevState.open }),
      () => {
        if (this.state.open) {
          this.props.onModalOpen();
        } else {
          this.props.onModalClose();
        }
      },
    );
  };

  render() {
    const {
      children,
      linkClassName,
      linkText,
      title,
      size,
      dark,
      hugeModal,
      fullModal,
      split,
      header,
      defaultPadding,
    } = this.props;

    const opener = linkText || title;

    return (
      <div className="base-modal__wrapper">
        {opener && (
          <label className={classNames('base-modal__link', linkClassName)} htmlFor={this.modalId}>
            {opener}
          </label>
        )}
        <input
          className="base-modal__state"
          type="checkbox"
          id={this.modalId}
          onChange={this.toggleModal}
          checked={this.state.open}
          disabled={this.props.disabled}
        />
        {this.state.open && (
          <div
            className={classNames('base-modal', this.modalSize(size), {
              'base-modal--dark': dark,
              'base-modal--square': hugeModal,
              'base-modal--full': fullModal,
              'base-modal--split': split,
            })}
          >
            <label htmlFor={this.modalId} className="base-modal__background" />
            <div className="base-modal__container">
              <label className="base-modal__close" htmlFor={this.modalId}>
                <Icon name="close" />
              </label>
              <div className="base-modal__content">
                {header && <div className="base-modal__header">{header}</div>}
                <div
                  className={classNames('base-modal__body', {
                    'base-modal--default-padding': defaultPadding,
                  })}
                >
                  {children}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

BaseModal.defaultProps = {
  children: null,
  initialOpenValue: false,
  linkText: null,
  linkClassName: null,
  dark: false,
  hugeModal: false,
  fullModal: false,
  split: false,
  size: null,
  header: null,
  onModalOpen: () => {},
  onModalClose: () => {},
  defaultPadding: false,
  open: false,
  title: null,
  disabled: false,
};

BaseModal.propTypes = {
  children: PropTypes.node,
  initialOpenValue: PropTypes.bool,
  linkText: PropTypes.node,
  linkClassName: PropTypes.string,
  title: PropTypes.node,
  dark: PropTypes.bool,
  hugeModal: PropTypes.bool,
  fullModal: PropTypes.bool,
  split: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'huge', 'small']),
  header: PropTypes.node,
  onModalOpen: PropTypes.func,
  onModalClose: PropTypes.func,
  defaultPadding: PropTypes.bool,
  open: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default BaseModal;

import gql from 'graphql-tag';

export const CREATE_PICTURE = gql`
  mutation createPicture($imageableId: ID!, $imageableType: String!, $unsplashPhotoId: String!) {
    createPicture(
      imageableId: $imageableId
      imageableType: $imageableType
      unsplashPhotoId: $unsplashPhotoId
    ) {
      id
    }
  }
`;

export const DELETE_PICTURE = gql`
  mutation deletePicture($Id: ID!) {
    deletePicture(id: $Id) {
      id
    }
  }
`;

import gql from 'graphql-tag';

export const EDIT_RAW_HOTEL = gql`
  mutation editRawHotel(
    $id: ID!
    $name: String
    $code: String
    $welltravelCode: String
    $provider: String
    $iso31661: String
    $iso31662: String
    $imageUrls: JSON
    $description: String
    $latitude: Float
    $longitude: Float
    $additionalContents: JSON
    $supplierCodes: JSON
    $rawData: JSON
  ) {
    editRawHotel(
      id: $id
      name: $name
      code: $code
      provider: $provider
      welltravelCode: $welltravelCode
      iso31661: $iso31661
      iso31662: $iso31662
      imageUrls: $imageUrls
      description: $description
      latitude: $latitude
      longitude: $longitude
      additionalContents: $additionalContents
      supplierCodes: $supplierCodes
      rawData: $rawData
    ) {
      id
      name
      code
      provider
      welltravelCode
      iso31661
      iso31662
      imageUrls
      description
      latitude
      longitude
      additionalContents
      supplierCodes
      rawData
    }
  }
`;

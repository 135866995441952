import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

import Button from '@bit/mno-wtag.welltravel.button';
import Input from '@bit/mno-wtag.welltravel.input';
import SelectBox from '@bit/mno-wtag.welltravel.select-box';
import TextArea from '@bit/mno-wtag.welltravel.text-area';

import { GET_AIRPORT } from '../../graphql/queries/airport';
import { GET_PLACES } from '../../graphql/queries/place';
import { GET_CITIES } from '../../graphql/queries/city';
import { CREATE_AIRPORT, EDIT_AIRPORT } from '../../graphql/mutations/airport';
import { getAirport } from '../../graphql/queries/airport';
import translatedObjOfDefaultLocale from '../../helpers/translatedObjOfDefaultLocale';

import imageUrl from '../../helpers/imageUrl';
import clearCache from '../../helpers/clearCache';
import FileUpload from '../common/FileUpload';
import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import returnEmptyStringWhenNull from '../../helpers/returnEmptyStringWhenNull';
import UnsplashImageSearch from '../unsplashImageSearch';
import NoResultsFound from '../common/NoResultsFound';

const AirportForm = props => {
  const { t } = useTranslation();
  let { id } = useParams();
  const isNewRecord = !id;

  const MIN_LENGTH_TO_SEARCH = 2;
  const MAX_ITEM_TO_FETCH = 10;
  const DEBOUNCE_DELAY = 1000;

  const [createAirport, callbackDataOnCreate] = useMutation(CREATE_AIRPORT, {
    update: cache => clearCache(cache, /Airport/),
  });
  const [editAirport, callbackDataOnEdit] = useMutation(EDIT_AIRPORT);
  const airportFromId = useQuery(GET_AIRPORT, {
    variables: { id },
    skip: isNewRecord,
  });

  const [placeQuery, setPlaceQuery] = useState('');
  const [skipPlaceSearch, setSkipPlaceSearch] = useState(true);
  const [places, setPlaces] = useState(null);
  const [citySearchQuery, setCitySearchQuery] = useState('');
  const [skipCitySearch, setSkipCitySearch] = useState(true);
  const [cities, setCities] = useState(null);

  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [placeId, setPlaceId] = useState('');
  const [cityId, setCityId] = useState('');
  const [description, setDescription] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [image, setImage] = useState(null);
  const [previousImage, setPreviousImage] = useState(null);
  const [publishDescription, setPublishDescription] = useState(false);
  const [deactivated, setDeactivated] = useState(false);

  const callPlaceSearchParam = useRef(
    debounce(async inputParam => {
      setPlaceQuery(inputParam);
      setSkipPlaceSearch(false);
    }, DEBOUNCE_DELAY),
  );

  const callCitySearchParam = useRef(
    debounce(async inputParam => {
      setCitySearchQuery(inputParam);
      setSkipCitySearch(false);
    }, DEBOUNCE_DELAY),
  );

  const { loading: placeListLoading, data: placeList } = useQuery(GET_PLACES, {
    variables: { query: placeQuery, first: MAX_ITEM_TO_FETCH },
    skip: skipPlaceSearch,
  });

  const { loading: cityListLoading, data: cityList } = useQuery(GET_CITIES, {
    variables: { query: citySearchQuery, first: MAX_ITEM_TO_FETCH },
    skip: skipCitySearch,
  });

  const onNameChange = event => {
    setName(event.target.value);
  };

  const onCodeChange = event => {
    setCode(event.target.value);
  };

  const onPlaceIdChange = value => {
    setPlaceId(value);
  };

  const onCityChange = value => {
    setCityId(value);
  };

  const onDescriptionChange = event => {
    setDescription(event.target.value);
  };

  const onPublishDescriptionChange = value => {
    setPublishDescription(value);
  };

  const onDeactivatedChange = value => {
    setDeactivated(value);
  };

  const onTwitterHandleChange = event => {
    setTwitterHandle(event.target.value);
  };

  const onPlaceSearch = value => {
    if (value.length < MIN_LENGTH_TO_SEARCH) {
      return;
    }

    callPlaceSearchParam.current(value);
  };

  const onCitySearch = value => {
    if (value.length < MIN_LENGTH_TO_SEARCH) {
      return;
    }

    callCitySearchParam.current(value);
  };

  useEffect(() => {
    if (airportFromId && airportFromId.data && !airportFromId.loading) {
      const airport = airportFromId.data.airport;
      setName(airport.name);
      setCode(airport.code);
      setPlaceId(airport.place.id);
      setCityId(airport.city && airport.city.id);
      setDescription(returnEmptyStringWhenNull(airport.description));
      setPublishDescription(airport.publishDescription);
      setTwitterHandle(returnEmptyStringWhenNull(airport.twitterHandle));
      setPreviousImage(airport.imageUrls.large);
      setDeactivated(airport.deactivated);
    }

    if (!placeListLoading && placeList && placeList.places) {
      setPlaces(placeList.places);
    }

    if (!cityListLoading && cityList && cityList.cities) {
      setCities(cityList.cities);
    }
  }, [airportFromId, placeList, placeListLoading, cityList, cityListLoading]);

  const submitToBackEnd = debounce(() => {
    props.resetError();

    if (isNewRecord) {
      createAirport({
        variables: {
          name,
          code,
          placeId,
          cityId,
          description,
          publishDescription,
          twitterHandle,
          image,
          deactivated,
        },
      })
        .then(({ data }) => {
          props.history.push(`/admin/airports/${data.createAirport.id}`);
        })
        .catch(() => props.onError());
    } else {
      editAirport({
        variables: {
          id,
          name,
          code,
          placeId,
          cityId,
          description,
          publishDescription,
          twitterHandle,
          image,
          deactivated,
        },
        refetchQueries: [getAirport(id)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/airports/${data.editAirport.id}`);
        })
        .catch(() => props.onError());
    }
  }, 500);

  const trueAndFalseOption = [
    { value: true, label: t('shared.true') },
    { value: false, label: t('shared.false') },
  ];

  const editDataLoaded =
    !isNewRecord && airportFromId && airportFromId.data && !airportFromId.loading;

  let placeOptions;

  if (!places) {
    if (editDataLoaded) {
      placeOptions = [
        {
          value: airportFromId.data.airport.place.id,
          label: airportFromId.data.airport.place.name,
        },
      ];
    } else {
      placeOptions = [];
    }
  } else {
    placeOptions =
      places &&
      places.edges.map(place => ({
        value: place.node.id.toString(),
        label: place.node.name,
      }));
  }

  let cityOptions;

  if (!cities) {
    const savedCity = editDataLoaded && airportFromId.data.airport.city;
    if (savedCity) {
      cityOptions = [
        {
          value: savedCity.id,
          label: translatedObjOfDefaultLocale(savedCity.translatedNames).name,
        },
      ];
    } else {
      cityOptions = [];
    }
  } else {
    cityOptions =
      cities &&
      cities.edges.map(city => ({
        value: city.node.id.toString(),
        label: translatedObjOfDefaultLocale(city.node.translatedNames).name,
      }));
  }

  const onFileUpload = files => {
    setImage(files[0]);
  };
  return (
    <div className="common-form">
      <div className="common-form__title">
        {isNewRecord
          ? t('shared.new', { entityName: t('airports.show.title') })
          : t('shared.editTitle', { entityName: t('airports.show.title') })}
      </div>
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit, callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-lg-6 col-md-10 col-sm-10 col-xs-10">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field airport-form__name">
                <Input label={t('airports.attributes.name')} value={name} onChange={onNameChange} />
              </div>
              <div className="common-form__field airport-form__code">
                <Input label={t('airports.attributes.code')} value={code} onChange={onCodeChange} />
              </div>
              <div className="common-form__field airport-form__cityName">
                <SelectBox
                  label={t('airports.attributes.city')}
                  value={cityId}
                  options={cityOptions}
                  onChange={onCityChange}
                  onSearchChange={value => onCitySearch(value)}
                  renderNoResultsFound={() => <NoResultsFound />}
                />
              </div>
              <div className="common-form__field airport-form__deactivate">
                <SelectBox
                  label={t('airports.attributes.deactivated')}
                  value={deactivated}
                  options={trueAndFalseOption}
                  onChange={onDeactivatedChange}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field airport-form__place">
                <SelectBox
                  label={t('airports.attributes.place')}
                  value={placeId}
                  options={placeOptions}
                  onChange={onPlaceIdChange}
                  onSearchChange={value => onPlaceSearch(value)}
                  renderNoResultsFound={() => <NoResultsFound />}
                />
              </div>
              <div className="common-form__field airport-form__twitterHandle">
                <Input
                  label={t('airports.attributes.twitterHandle')}
                  value={twitterHandle}
                  onChange={onTwitterHandleChange}
                />
              </div>
            </div>
            <div className="col-12 col-bleed-y">
              <div className="common-form__field airport-form__description">
                <TextArea
                  label={t('airports.attributes.description')}
                  value={description}
                  onChange={onDescriptionChange}
                />
              </div>
              <div className="common-form__field airport-form__publish-description">
                <SelectBox
                  label={t('airports.attributes.publishDescription')}
                  value={publishDescription}
                  options={trueAndFalseOption}
                  onChange={onPublishDescriptionChange}
                />
              </div>
              <div className="common-form__submit-button">
                <Button
                  label={isNewRecord ? t('shared.create') : t('shared.update')}
                  onClick={submitToBackEnd}
                />
              </div>
            </div>
          </div>
          <div className="col-6 col-bleed-y">
            <h2>{t('airports.attributes.image')}</h2>
            {previousImage && (
              <div className="common-form__previous-image">
                <img src={imageUrl(previousImage)} alt="preview" />
              </div>
            )}
            <FileUpload onFileUpload={onFileUpload} multiple={false} />
            {!isNewRecord && (
              <UnsplashImageSearch
                setPreviousImage={setPreviousImage}
                imageableType={'Airport'}
                imageableId={id}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithErrorHandler(AirportForm);

import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_PLACES = gql`
  query places($first: Int,$after: String, $query: String) {
    places(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_PLACES = gql`
  query places($first: Int, $after: String, $query: String) {
    places(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          name
          description
          publishDescription
          twitterHashtag
          geonameIdentifier
          admin1Code
          admin2Code
          admin3Code
          admin4Code
          featureCode
          latitude
          longitude
          timezone
          imageUrls
          country {
            id
            name
          }
        }
      }
    }
  }
`;

export const getPlaces = after => all(GET_PLACES, after);

export const GET_PLACE = gql`
  query place($id: ID!) {
    place(id: $id) {
      name
      description
      publishDescription
      twitterHashtag
      geonameIdentifier
      admin1Code
      admin2Code
      admin3Code
      admin4Code
      featureCode
      latitude
      longitude
      timezone
      imageUrls
      country {
        id
        name
      }
    }
  }
`;

export const getPlace = id => one(GET_PLACE, id);

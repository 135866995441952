import gql from 'graphql-tag';

export const CREATE_AIRCRAFT_TYPE = gql`
  mutation createAircraftType(
    $code: String!
    $name: String!
    $image: Upload
  ) {
    createAircraftType(
      code: $code
      name: $name
      image: $image
    ) {
      id
      code
      name
    }
  }
`;

export const EDIT_AIRCRAFT_TYPE = gql`
  mutation editAircraftType(
    $id: ID!
    $code: String
    $name: String
    $image: Upload
  ) {
    editAircraftType(
      id: $id
      code: $code
      name: $name
      image: $image
    ) {
      id
      code
      name
      imageUrls
    }
  }
`;

export const DELETE_AIRCRAFT_TYPE = gql`
  mutation deleteAircraftType($id: ID!) {
    deleteAircraftType(id: $id) {
      code
      name
    }
  }
`;

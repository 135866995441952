import gql from 'graphql-tag';

export const EDIT_VISA_PROCUREMENT_CATEGORY = gql`
  mutation editVisaProcurementCategory($id: ID!, $visaCategory: String) {
    editVisaProcurementCategory(id: $id, visaCategory: $visaCategory) {
      id
      visaCategory
    }
  }
`;

export const DELETE_VISA_PROCUREMENT_CATEGORY = gql`
  mutation null {
    null
  }
`;

import gql from 'graphql-tag';

const GET_DASHBOARD_ATTRIBUTES = gql`
  query dashboardAttributes {
    dashboardAttributes {
      modelName
      url
      totalEntries
      recentEntries
      lastModifiedAt
    }
  }
`;

export default GET_DASHBOARD_ATTRIBUTES;

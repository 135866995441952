import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@bit/mno-wtag.welltravel.button';
import Input from '@bit/mno-wtag.welltravel.input';

import BaseModal from '../rclComponents/BaseModal';

import { GET_HASHTAGS } from '../../graphql/queries/hashtag';
import { CREATE_HASHTAG } from '../../graphql/mutations/hashtag';

import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import { debounce } from 'lodash';

const Hashtag = props => {
  const { t } = useTranslation();
  const [hashtag, setHashtag] = useState('');
  const [open, setOpen] = useState(false)
  const { client, loading, data } = useQuery(GET_HASHTAGS);
  const [createHashtag, callbackDataOnCreate] = useMutation(CREATE_HASHTAG);

  const submitToBackEnd = debounce(() => {
    props.resetError();

    createHashtag({
      variables: { name: hashtag },
    })
      .then(() => {
        client.resetStore();
        setHashtag('');
        setOpen(false);
      })
      .catch(() => props.onError());
  }, 500);

  const onModalOpen = () => {
    setOpen(true);
  }

  const onModalClose = () => {
    setOpen(false);
  }

  return (
    <BaseModal
      linkText={t(props.headerLabel)}
      linkClassName="button"
      defaultPadding={true}
      header={t(props.headerLabel)}
      open={open}
      onModalOpen={onModalOpen}
      onModalClose={onModalClose}
    >
      <div className="hashtag-content">
        <span className="hashtag-content__header">{t(props.currentContentLabel)}</span>
        <div className="hashtag-content__list">
          {!loading && data.hashtags.edges.map(edge => edge.node.name).join(', ')}
        </div>

        <div className="hashtag-content__add-new">
          <Input
            label={t('shared.addNew')}
            value={hashtag}
            onChange={event => setHashtag(event.target.value)}
          />
        </div>
        {props.hasError && <ErrorComponent error={{ callbackDataOnCreate }} />}
        <div className="hashtag-content__submit">
          <Button label={t('shared.create')} onClick={submitToBackEnd} disabled={!hashtag} />
        </div>
      </div>
    </BaseModal>
  );
};

Hashtag.defaultProps = {
  headerLabel: 'hashtags.title',
  currentContentLabel: 'hashtags.current'
};

Hashtag.propTypes = {
  headerLabel: PropTypes.string,
  currentContentLabel: PropTypes.string,
};

export default WithErrorHandler(Hashtag);

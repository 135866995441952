import gql from 'graphql-tag';

export const CREATE_FLIGHT = gql`
  mutation createFlight(
    $flightNumber: String!
    $onTimePercentage: String!
    $wifiAvailability: Boolean
    $airlineId: ID!
  ) {
    createFlight(
      flightNumber: $flightNumber
      onTimePercentage: $onTimePercentage
      wifiAvailability: $wifiAvailability
      airlineId: $airlineId
    ) {
      id
      flightNumber
      onTimePercentage
      wifiAvailability
      airline {
        id
        name
      }
    }
  }
`;

export const EDIT_FLIGHT = gql`
  mutation editFlight(
    $id: ID!
    $flightNumber: String!
    $onTimePercentage: String!
    $wifiAvailability: Boolean
    $airlineId: ID!
  ) {
    editFlight(
      id: $id
      flightNumber: $flightNumber
      onTimePercentage: $onTimePercentage
      wifiAvailability: $wifiAvailability
      airlineId: $airlineId
    ) {
      id
      flightNumber
      onTimePercentage
      wifiAvailability
      airline {
        id
        name
      }
    }
  }
`;

export const DELETE_FLIGHT = gql`
  mutation deleteFlight($id: ID!) {
    deleteFlight(id: $id) {
      flightNumber
    }
  }
`;

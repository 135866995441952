import React from 'react';
import { Query } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCity } from '../../graphql/queries/city';
import Header from '../common/Header';
import renderTranslations from '../../helpers/renderTranslations';

const City = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  return (
    <div className="list">
      <Header
        title="cities.show.title"
        linkTo={`/admin/cities/${id}/edit`}
        buttonLabel="shared.edit"
      />
      <Query {...getCity(id)}>
        {({ loading, data }) => {
          if (loading) return <div className="lds-dual-ring" />;
          if (data) {
            const { city } = data;

            return (
              <div className="common-show">
                <div className="grid">
                  <div className="col-12">
                    <div className="common-show__infos">
                      <div className="col-12">
                        <div className="common-show__infos--field">{t('cities.attributes.id')}</div>
                        <div className="common-show__infos--value">{city.id}</div>
                        <div className="common-show__infos--field">
                          {t('cities.attributes.nameWithLocale')}
                        </div>

                        <div className="common-show__infos--value">
                          {renderTranslations(city.translatedNames, t('shared.notAvailable'), 'name')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return null;
        }}
      </Query>
    </div>
  );
};

export default City;

import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_PLATFORM_FEATURES = gql`
  query platformFeatures($first: Int, $after: String, $query: String) {
    platformFeatures(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_PLATFORM_FEATURES = gql`
  query platformFeatures($first: Int, $after: String, $query: String) {
    platformFeatures(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          title
          order
          parentId
          nestedPlatformFeatures {
            id
            title
            order
          }
        }
      }
    }
  }
`;

export const getPlatformFeatures = after => all(GET_PLATFORM_FEATURES, after);

export const GET_PLATFORM_FEATURE = gql`
  query platformFeature($id: ID!) {
    platformFeature(id: $id) {
      id
      title
      description
      order
      updatedAt
      updatedBy {
        name
        id
      }
      hashtags {
        id
        name
      }
      nestedPlatformFeatures {
        id
        title
        order
        parentId
        nestedPlatformFeatures {
          id
          title
        }
      }
      parentId
    }
  }
`;

export const getPlatformFeature = id => one(GET_PLATFORM_FEATURE, id);

export const GET_PLATFORM_FEATURE_CATEGORIES = gql`
  query platformFeatureCategories($id: ID) {
    platformFeatureCategories(id: $id) {
      label
      value
    }
  }
`;

import React from 'react';

import IconMapping from './registry';

const Icon = ({ name }) => {
  const MappedIcon = IconMapping[name];
  return <MappedIcon />;
};

export default Icon;

import gql from 'graphql-tag';

export const CREATE_BANNER = gql`
  mutation createBanner(
    $description: String!
    $publishDescription: Boolean!
    $image: Upload!
  ) {
    createBanner(
      description: $description
      image: $image
      publishDescription: $publishDescription
    ) {
      id
      description
      publishDescription
      imageUrls
    }
  }
`;

export const EDIT_BANNER = gql`
  mutation editBanner(
    $id: ID!
    $description: String
    $publishDescription: Boolean!
    $image: Upload
  ) {
    editBanner(
      id: $id
      description: $description
      publishDescription: $publishDescription
      image: $image
    ) {
      id
      description
      publishDescription
      imageUrls
    }
  }
`;

export const DELETE_BANNER = gql`
  mutation deleteBanner($id: ID!) {
    deleteBanner(id: $id) {
      id
    }
  }
`;

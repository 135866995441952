import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_CAR_RENTAL_COMPANIES = gql`
  query carRentalCompanies($first: Int,$after: String, $query: String) {
    carRentalCompanies(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_CAR_RENTAL_COMPANIES = gql`
  query carRentalCompanies($first: Int, $after: String, $query: String) {
    carRentalCompanies(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          name
          code
          imageUrls
        }
      }
    }
  }
`;

export const getCarRentalCompanies = after => all(GET_CAR_RENTAL_COMPANIES, after);

export const GET_CAR_RENTAL_COMPANY = gql`
  query carRentalCompany($id: ID!) {
    carRentalCompany(id: $id) {
      name
      code
      imageUrls
    }
  }
`;

export const getCarRentalCompany = id => one(GET_CAR_RENTAL_COMPANY, id);

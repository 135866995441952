import React from 'react';

import { GET_WELLTRAVEL_NEWS, GET_CURSORS_FOR_WELLTRAVEL_NEWS } from '../../graphql/queries/welltravelNews';
import { DELETE_WELLTRAVEL_NEWS } from '../../graphql/mutations/welltravelNews';

import Hashtag from '../hashtag';
import List from '../common/List';

const WelltravelNewsList = () => {
  return (
    <List
      getItems={ GET_WELLTRAVEL_NEWS }
      getCursorsForItems={ GET_CURSORS_FOR_WELLTRAVEL_NEWS }
      deleteItem={ DELETE_WELLTRAVEL_NEWS }
      listAttributes={ ['title', 'teaser', 'platform'] }
      itemType="welltravelNews"
      modelName="WelltravelNews"
      title="nav.welltravelNews"
      linkTo="/admin/welltravel_news/new"
      buttonLabel="shared.addNew"
    >
      <div className="hashtag-wrapper">
        <Hashtag />
      </div>
    </List>
  );
};

export default WelltravelNewsList;

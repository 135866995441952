import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_WELLTRAVEL_NEWS = gql`
  query welltravelNews($first: Int, $after: String, $query: String) {
    welltravelNews(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_WELLTRAVEL_NEWS = gql`
  query welltravelNews($first: Int, $after: String, $query: String) {
    welltravelNews(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          title
          teaser
          externalLink
          platform
          hashtags {
            id
            name
          }
          content
          imageUrls
        }
      }
    }
  }
`;

export const getWelltravelNews = after => all(GET_WELLTRAVEL_NEWS, after);

export const GET_WELLTRAVEL_NEWS_ONE = gql`
  query welltravelNewsOne($id: ID!) {
    welltravelNewsOne(id: $id) {
      id
      title
      teaser
      externalLink
      platform
      hashtags {
        id
        name
      }
      content
      imageUrls
    }
  }
`;

export const getWelltravelNewsOne = id => one(GET_WELLTRAVEL_NEWS_ONE, id);

export const GET_WELLTRAVEL_NEWS_PLATFORMS = gql`
  query welltravelNewsPlatforms {
    welltravelNewsPlatforms {
      label
      value
    }
  }
`;

import React from 'react';
import { GET_BANNERS, GET_CURSORS_FOR_BANNERS } from '../../graphql/queries/banner';
import { DELETE_BANNER } from '../../graphql/mutations/banner';
import List from '../common/List';

const BannerList = () => {
  return (
    <List
      getItems={GET_BANNERS}
      getCursorsForItems={GET_CURSORS_FOR_BANNERS}
      deleteItem={DELETE_BANNER}
      listAttributes={['description']}
      itemType="banners"
      modelName="Banner"
      title="nav.banners"
      linkTo="/admin/banners/new"
      buttonLabel="shared.addNew"
    />
  );
};

export default BannerList;
import React from 'react';
import { GET_CAR_RENTAL_COMPANIES, GET_CURSORS_FOR_CAR_RENTAL_COMPANIES } from '../../graphql/queries/carRentalCompany';
import { DELETE_CAR_RENTAL_COMPANY } from '../../graphql/mutations/carRentalCompany';
import List from '../common/List';

const CarRentalCompanyList = () => {
  return (
    <List
      getItems={GET_CAR_RENTAL_COMPANIES}
      getCursorsForItems={GET_CURSORS_FOR_CAR_RENTAL_COMPANIES}
      deleteItem={DELETE_CAR_RENTAL_COMPANY}
      listAttributes={['name', 'code']}
      itemType="carRentalCompanies"
      modelName="CarRentalCompany"
      title="nav.carRentalCompanies"
      linkTo="/admin/car_rental_companies/new"
      buttonLabel="shared.addNew"
      minLengthToSearch={ 2 }
    />
  );
};

export default CarRentalCompanyList;

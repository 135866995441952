import gql from 'graphql-tag';

export const CREATE_FARE_BRAND = gql`
  mutation createFareBrand(
    $cabinClassName: String!
    $airlineId: ID!
    $name: String!
    $bookingClass: JSON!
    $refundable: String
    $cancelPenalty: Float
    $cancelPenaltyCurrency: String
    $changeable: String
    $changePenalty: Float
    $changePenaltyCurrency: String
    $noShow: String
    $noShowPenalty: Float
    $noShowPenaltyCurrency: String
    $handLuggage: String
    $handLuggageCount: Int
    $handLuggageWeight: Float
    $fareBrandNameChange: String
    $seatReservation: String
    $co2Compensation: String
    $loungeAccess: String
    $priorityBoarding: String
    $priorityLuggage: String
    $exclusiveCheckIn: String
    $fastTrackSecurity: String
    $mileUpgrade: String
    $catering: String
    $welcomeDrink: String
    $digitalMagazines: String
    $flatbed: String
    $milesAward: String
    $milesAwardAmount: Float
    $milesStatus: String
    $milesStatusAmount: Float
    $supplierFareBrandsAttributes: [SupplierFareBrandsAttributes!]
    $unverifiedFareBrandId: ID
  ) {
    createFareBrand(
      cabinClassName: $cabinClassName
      airlineId: $airlineId
      name: $name
      bookingClass: $bookingClass
      refundable: $refundable
      cancelPenalty: $cancelPenalty
      cancelPenaltyCurrency: $cancelPenaltyCurrency
      changeable: $changeable
      changePenalty: $changePenalty
      changePenaltyCurrency: $changePenaltyCurrency
      noShow: $noShow
      noShowPenalty: $noShowPenalty
      noShowPenaltyCurrency: $noShowPenaltyCurrency
      handLuggage: $handLuggage
      handLuggageCount: $handLuggageCount
      handLuggageWeight: $handLuggageWeight
      fareBrandNameChange: $fareBrandNameChange
      seatReservation: $seatReservation
      co2Compensation: $co2Compensation
      loungeAccess: $loungeAccess
      priorityBoarding: $priorityBoarding
      priorityLuggage: $priorityLuggage
      exclusiveCheckIn: $exclusiveCheckIn
      fastTrackSecurity: $fastTrackSecurity
      mileUpgrade: $mileUpgrade
      catering: $catering
      welcomeDrink: $welcomeDrink
      digitalMagazines: $digitalMagazines
      flatbed: $flatbed
      milesAward: $milesAward
      milesAwardAmount: $milesAwardAmount
      milesStatus: $milesStatus
      milesStatusAmount: $milesStatusAmount
      supplierFareBrandsAttributes: $supplierFareBrandsAttributes
      unverifiedFareBrandId: $unverifiedFareBrandId
    ) {
      id
      name
    }
  }
`;

export const EDIT_FARE_BRAND = gql`
  mutation editFareBrand(
    $id: ID!
    $cabinClassName: String!
    $airlineId: ID!
    $name: String!
    $bookingClass: JSON!
    $refundable: String
    $cancelPenalty: Float
    $cancelPenaltyCurrency: String
    $changeable: String
    $changePenalty: Float
    $changePenaltyCurrency: String
    $noShow: String
    $noShowPenalty: Float
    $noShowPenaltyCurrency: String
    $handLuggage: String
    $handLuggageCount: Int
    $handLuggageWeight: Float
    $fareBrandNameChange: String
    $seatReservation: String
    $co2Compensation: String
    $loungeAccess: String
    $priorityBoarding: String
    $priorityLuggage: String
    $exclusiveCheckIn: String
    $fastTrackSecurity: String
    $mileUpgrade: String
    $catering: String
    $welcomeDrink: String
    $digitalMagazines: String
    $flatbed: String
    $milesAward: String
    $milesAwardAmount: Float
    $milesStatus: String
    $milesStatusAmount: Float
    $supplierFareBrandsAttributes: [SupplierFareBrandsAttributes!]
  ) {
    editFareBrand(
      id: $id
      cabinClassName: $cabinClassName
      airlineId: $airlineId
      name: $name
      bookingClass: $bookingClass
      refundable: $refundable
      cancelPenalty: $cancelPenalty
      cancelPenaltyCurrency: $cancelPenaltyCurrency
      changeable: $changeable
      changePenalty: $changePenalty
      changePenaltyCurrency: $changePenaltyCurrency
      noShow: $noShow
      noShowPenalty: $noShowPenalty
      noShowPenaltyCurrency: $noShowPenaltyCurrency
      handLuggage: $handLuggage
      handLuggageCount: $handLuggageCount
      handLuggageWeight: $handLuggageWeight
      fareBrandNameChange: $fareBrandNameChange
      seatReservation: $seatReservation
      co2Compensation: $co2Compensation
      loungeAccess: $loungeAccess
      priorityBoarding: $priorityBoarding
      priorityLuggage: $priorityLuggage
      exclusiveCheckIn: $exclusiveCheckIn
      fastTrackSecurity: $fastTrackSecurity
      mileUpgrade: $mileUpgrade
      catering: $catering
      welcomeDrink: $welcomeDrink
      digitalMagazines: $digitalMagazines
      flatbed: $flatbed
      milesAward: $milesAward
      milesAwardAmount: $milesAwardAmount
      milesStatus: $milesStatus
      milesStatusAmount: $milesStatusAmount
      supplierFareBrandsAttributes: $supplierFareBrandsAttributes
    ) {
      id
      name
    }
  }
`;

export const DELETE_FARE_BRAND = gql`
  mutation deleteFareBrand($id: ID!) {
    deleteFareBrand(id: $id) {
      id
    }
  }
`;

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_PICTURES } from '../../graphql/queries/picture';
import imageUrl from '../../helpers/imageUrl';
import Icon from '@wtag/rcl-icon';
import './styles.scss';

const Pictures = ({ imageableType, imageableId }) => {
  const [pictures, setPictures] = useState([]);

  const [fetchPictures] = useLazyQuery(GET_PICTURES, {
    variables: {
      imageableType: imageableType,
      imageableId: imageableId,
    },
    fetchPolicy: 'network-only',
    onCompleted: savedPictures => {
      setPictures(savedPictures.pictures.edges);
    },
  });

  useEffect(() => {
    fetchPictures();
  }, [fetchPictures]);

  return (
    <div
      className={classNames(
        'unsplash-images__list',
        {
          'unsplash-images--without-images': !pictures.length > 0,
        },
        { 'unsplash-images__list-medium__list': pictures.length > 8 },
        { 'unsplash-images__list-small__list': pictures.length < 5 },
      )}
    >
      {pictures && pictures.length > 0 ? (
        pictures.map(image => (
          <span
            className={classNames('unsplash-images__list-item')}
            role="presentation"
            id={image.node.id}
            key={image.node.id}
          >
            <img src={imageUrl(image.node.imageUrls.small)} alt="preview" />
            <div className="unsplash-images__list-item-overlay">
              <Icon name="delete" />
            </div>
          </span>
        ))
      ) : (
        <p key="no-image-found">{'No Images'}</p>
      )}
    </div>
  );
};

export default Pictures;

import gql from 'graphql-tag';

export const CREATE_COUNTRY = gql`
  mutation createCountry(
    $translationsAttributes: [CountryTranslationAttributes!]!
    $code: String!
    $continentId: ID!
    $phoneCode: String
    $apisDataRequired: String!
    $description: String
    $publishDescription: Boolean!
    $image: Upload
  ) {
    createCountry(
      translationsAttributes: $translationsAttributes
      code: $code
      continentId: $continentId
      phoneCode: $phoneCode
      apisDataRequired: $apisDataRequired
      description: $description
      publishDescription: $publishDescription
      image: $image
    ) {
      id
      name
      code
      nationality
    }
  }
`;

export const EDIT_COUNTRY = gql`
  mutation editCountry(
    $id: ID!
    $translationsAttributes: [CountryTranslationAttributes!]!
    $code: String!
    $continentId: ID!
    $phoneCode: String
    $apisDataRequired: String!
    $description: String
    $publishDescription: Boolean!
    $image: Upload
  ) {
    editCountry(
      id: $id
      translationsAttributes: $translationsAttributes
      code: $code
      continentId: $continentId
      phoneCode: $phoneCode
      apisDataRequired: $apisDataRequired
      description: $description
      publishDescription: $publishDescription
      image: $image
    ) {
      id
      name
      code
      nationality
      continent {
        id
      }
      apisDataRequired
      description
      publishDescription
      imageUrls
    }
  }
`;

export const DELETE_COUNTRY = gql`
  mutation deleteCountry($id: ID!) {
    deleteCountry(id: $id) {
      code
    }
  }
`;

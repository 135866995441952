import gql from 'graphql-tag';

export const CREATE_AIRPORT = gql`
  mutation createAirport(
    $name: String!
    $code: String!
    $placeId: ID!
    $description: String
    $publishDescription: Boolean!
    $twitterHandle: String
    $cityId: ID!
    $image: Upload
    $deactivated: Boolean
  ) {
    createAirport(
      name: $name
      code: $code
      placeId: $placeId
      description: $description
      publishDescription: $publishDescription
      twitterHandle: $twitterHandle
      cityId: $cityId
      image: $image
      deactivated: $deactivated
    ) {
      id
      name
      code
      city {
        id
        translatedNames {
          id
          name
          locale
        }
      }
      description
      publishDescription
      twitterHandle
    }
  }
`;

export const EDIT_AIRPORT = gql`
  mutation editAirport(
    $id: ID!
    $name: String
    $code: String
    $placeId: ID!
    $description: String
    $publishDescription: Boolean
    $twitterHandle: String
    $cityId: ID
    $image: Upload
    $deactivated: Boolean
  ) {
    editAirport(
      id: $id
      name: $name
      code: $code
      placeId: $placeId
      description: $description
      publishDescription: $publishDescription
      twitterHandle: $twitterHandle
      cityId: $cityId
      image: $image
      deactivated: $deactivated
    ) {
      id
      name
      code
      description
      publishDescription
      twitterHandle
      city {
        id
        translatedNames {
          id
          name
          locale
        }
      }
      imageUrls
      place {
        id
        name
      }
      deactivated
    }
  }
`;

export const DELETE_AIRPORT = gql`
  mutation deleteAirport($id: ID!) {
    deleteAirport(id: $id) {
      code
    }
  }
`;

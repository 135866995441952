import React from 'react';
import { Query } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getVisaProcurementCategory } from '../../graphql/queries/visaProcurementCategory';
import Header from '../common/Header';

const VisaProcurementCategory = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  return (
    <div className="list">
      <Header
        title={'visaProcurementCategories.show.title'}
        linkTo={`/admin/visa_procurement_categories/${id}/edit`}
        buttonLabel={'shared.edit'}
      />
      <Query {...getVisaProcurementCategory(id)}>
        {({ loading, data }) => {
          if (loading) return <div className="lds-dual-ring" />;
          if (data) {
            const { visaProcurementCategory } = data;

            return (
              <div className="common-show">
                <div className="grid">
                  <div className="col-12">
                    <div className="common-show__infos">
                      <div className="col-12">
                        <div className="common-show__infos--field">
                          {t('visaProcurementCategories.attributes.name')}
                        </div>
                        <div className="common-show__infos--value">
                          {visaProcurementCategory.name}
                        </div>
                        <div className="common-show__infos--field">
                          {t('visaProcurementCategories.attributes.visaCategory')}
                        </div>
                        <div className="common-show__infos--value">
                          {t(
                            `visaProcurementCategories.visaCategories.${visaProcurementCategory.visaCategory}`,
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return null;
        }}
      </Query>
    </div>
  );
};

export default VisaProcurementCategory;

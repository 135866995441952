export const supplierKeys = [
    'amadeus',
    'galileo',
    'sabre',
    'lh_group',
    'british_airways',
    'american_airlines',
    'pyton',
    'aero_crs',
    'singapore_airlines',
    'fly_safair',
    'mango',
    'travelfusion',
    'united_airlines',
    'aer_ticket',
    'duffel',
    'emirates',
];

export default supplierKeys;

import gql from 'graphql-tag';

export const MAP_UNVERIFIED_SUPPLIER_FARE_BRAND = gql`
  mutation mapUnverifiedSupplierFareBrand($id: ID!, $fareBrandId: ID!) {
    mapUnverifiedSupplierFareBrand(id: $id, fareBrandId: $fareBrandId) {
      id
    }
  }
`;

export const DELETE_UNVERIFIED_SUPPLIER_FARE_BRAND = gql`
  mutation deleteUnverifiedSupplierFareBrand($id: ID!) {
    deleteUnverifiedSupplierFareBrand(id: $id) {
      id
    }
  }
`;

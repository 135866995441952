import React from 'react';
import imageUrl from '../../helpers/imageUrl';
import { useTranslation } from 'react-i18next';

const RenderImage = props => {
  const { t } = useTranslation();
  let content;
  if (props.url) {
    content = (
      <div className="render-image__preview">
        <img src={imageUrl(props.url)} alt="preview" />
      </div>
    );
  } else {
    content = <div className="render-image__label">{t('shared.notAvailable')}</div>;
  }

  return (
    <React.Fragment>
      {content}
    </React.Fragment>
  );
};

export default RenderImage;

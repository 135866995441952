import gql from 'graphql-tag';

export const CREATE_CITY = gql`
  mutation createCity($translationsAttributes: [NameTranslationAttributes!]!) {
    createCity(translationsAttributes: $translationsAttributes) {
      id
      translatedNames {
        id
        name
        locale
      }
    }
  }
`;

export const EDIT_CITY = gql`
  mutation editCity($id: ID!, $translationsAttributes: [NameTranslationAttributes!]!) {
    editCity(id: $id, translationsAttributes: $translationsAttributes) {
      id
      translatedNames {
        id
        name
        locale
      }
    }
  }
`;

export const DELETE_CITY = gql`
  mutation deleteCity($id: ID!) {
    deleteCity(id: $id) {
      id
    }
  }
`;

import React from 'react';
import { Query } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getHotelCombinedDatabase } from '../../graphql/queries/hotelCombinedDatabase';
import { useQuery } from '@apollo/react-hooks';
import { GET_GOOGLE_MAPS_PUBlIC_API_KEY } from '../../graphql/queries/google_maps_public_api_key';
import Header from '../common/Header';
import RawJSON from '../common/RawJSON';
import WrappedMap from '../common/WrappedMap';

const HotelCombinedDatabase = () => {
  let { id } = useParams();
  const { t } = useTranslation();
  const { data: mapKey } = useQuery(GET_GOOGLE_MAPS_PUBlIC_API_KEY);
  let renderMap;

  return (
    <div className="list">
      <Header
        title={'hotelCombinedDatabases.show.title'}
        linkTo={`/admin/hotel_combined_databases/${id}/edit`}
        buttonLabel={'shared.edit'}
      />
      <Query {...getHotelCombinedDatabase(id)}>
        {({ loading, data }) => {
          if (loading) return <div className="lds-dual-ring" />;
          if (data) {
            const { hotelCombinedDatabase } = data;
            renderMap = mapKey && hotelCombinedDatabase.latitude && hotelCombinedDatabase.longitude;

            return (
              <div className="common-show">
                <div className="grid col-12">
                  <div className="col-12">
                    <div className="common-show__infos">
                      <div className="col-12">
                        <div className="common-show__infos--field">
                          {t('hotelCombinedDatabases.attributes.name')}
                        </div>
                        <div className="common-show__infos--value">
                          {hotelCombinedDatabase.name}
                        </div>
                        <div className="common-show__infos--field">
                          {t('hotelCombinedDatabases.attributes.mainSource')}
                        </div>
                        <div className="common-show__infos--value">
                          {hotelCombinedDatabase.mainSource || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('hotelCombinedDatabases.attributes.uniqueId')}
                        </div>
                        <div className="common-show__infos--value">
                          {hotelCombinedDatabase.uniqueId || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('hotelCombinedDatabases.attributes.iso31661')}
                        </div>
                        <div className="common-show__infos--value">
                          {hotelCombinedDatabase.iso31661 || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('hotelCombinedDatabases.attributes.iso31662')}
                        </div>
                        <div className="common-show__infos--value">
                          {hotelCombinedDatabase.iso31662 || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('hotelCombinedDatabases.attributes.multipleSource')}
                        </div>
                        <div className="common-show__infos--value">
                          {hotelCombinedDatabase.multipleSource &&
                          hotelCombinedDatabase.multipleSource === true
                            ? t('shared.true')
                            : t('shared.false')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('hotelCombinedDatabases.attributes.proximityPercentage')}
                        </div>
                        <div className="common-show__infos--value">
                          {hotelCombinedDatabase.proximityPercentage || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('hotelCombinedDatabases.attributes.description')}
                        </div>
                        <div className="common-show__infos--value">
                          {hotelCombinedDatabase.description || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('hotelCombinedDatabases.attributes.alternateNames')}
                        </div>
                        <RawJSON data={hotelCombinedDatabase.alternateNames} />
                        <div className="common-show__infos--field">
                          {t('hotelCombinedDatabases.attributes.welltravelCodes')}
                        </div>
                        <RawJSON data={hotelCombinedDatabase.welltravelCodes} />
                        <div className="common-show__infos--field">
                          {t('hotelCombinedDatabases.attributes.imageUrls')}
                        </div>
                        <RawJSON data={hotelCombinedDatabase.imageUrls} />
                        <div className="common-show__infos--field">
                          {t('hotelCombinedDatabases.attributes.additionalContents')}
                        </div>
                        <RawJSON data={hotelCombinedDatabase.additionalContents} />
                        <div className="common-show__infos--field">
                          {t('hotelCombinedDatabases.attributes.supplierCodes')}
                        </div>
                        <RawJSON data={hotelCombinedDatabase.supplierCodes} />
                        <div className="common-show__infos--field">
                          {t('hotelCombinedDatabases.attributes.otherSources')}
                        </div>
                        <RawJSON data={hotelCombinedDatabase.otherSources} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="common-show__map">
                    {renderMap && (
                      <WrappedMap
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${mapKey.googleMapsPublicApiKey}`}
                        latLong={{
                          lat: hotelCombinedDatabase.latitude,
                          lng: hotelCombinedDatabase.longitude,
                        }}
                        infoWindowContent={hotelCombinedDatabase.name}
                      />
                    )}
                  </div>
                  <div className="common-show__infos--field">
                    {t('hotelCombinedDatabases.attributes.latitude')}
                  </div>
                  <div className="common-show__infos--value">{hotelCombinedDatabase.latitude}</div>
                  <div className="common-show__infos--field">
                    {t('hotelCombinedDatabases.attributes.longitude')}
                  </div>
                  <div className="common-show__infos--value">{hotelCombinedDatabase.longitude}</div>
                </div>
              </div>
            );
          }
          return null;
        }}
      </Query>
    </div>
  );
};

export default HotelCombinedDatabase;

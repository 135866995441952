import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@bit/mno-wtag.welltravel.button';
import Input from '@bit/mno-wtag.welltravel.input';
import SelectBox from '@bit/mno-wtag.welltravel.select-box';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_PLACE_SYNONYM } from '../../graphql/queries/placeSynonym';
import { GET_PLACES } from '../../graphql/queries/place';
import { CREATE_PLACE_SYNONYM, EDIT_PLACE_SYNONYM } from '../../graphql/mutations/placeSynonym';
import { useTranslation } from 'react-i18next';
import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import { getPlaceSynonym } from '../../graphql/queries/placeSynonym';
import clearCache from '../../helpers/clearCache';
import returnEmptyStringWhenNull from '../../helpers/returnEmptyStringWhenNull';
import NoResultsFound from '../common/NoResultsFound';
import { debounce } from 'lodash';

const PlaceSynonymForm = props => {
  const { t } = useTranslation();
  let { id } = useParams();
  const isNewRecord = !id;

  const [createPlaceSynonym, callbackDataOnCreate] = useMutation(CREATE_PLACE_SYNONYM, {
    update: cache => clearCache(cache, /PlaceSynonym/),
  });
  const [editPlaceSynonym, callbackDataOnEdit] = useMutation(EDIT_PLACE_SYNONYM);
  const placeSynonymFromId = useQuery(GET_PLACE_SYNONYM, {
    variables: { id },
    skip: isNewRecord,
  });

  const [placeQuery, setPlaceQuery] = useState('');
  const [skipPlaceSearch, setskipPlaceSearch] = useState(true);
  const [places, setPlaces] = useState(null);
  const [placeId, setPlaceId] = useState('');
  const [name, setName] = useState('');
  const [geonameIdentifier, setGeonameIdentifier] = useState('');
  const [locale, setLocale] = useState('');

  const { data } = useQuery(GET_PLACES, {
    variables: { query: placeQuery, first: 20 },
    skip: skipPlaceSearch,
  });

  const onNameChange = event => {
    setName(event.target.value);
  };

  const onGeonameIdentifierChange = event => {
    setGeonameIdentifier(event.target.value);
  };

  const onLocaleChange = event => {
    setLocale(event.target.value);
  };

  const onPlaceIdChange = value => {
    setPlaceId(value);
  };

  const onPlaceSearch = value => {
    if (value.length < 3) {
      return;
    }
    setPlaceQuery(value);
    setskipPlaceSearch(false);
  };

  useEffect(() => {
    if (placeSynonymFromId && placeSynonymFromId.data && !placeSynonymFromId.loading) {
      setName(placeSynonymFromId.data.placeSynonym.name);
      setPlaceId(placeSynonymFromId.data.placeSynonym.place.id);
      setGeonameIdentifier(
        returnEmptyStringWhenNull(placeSynonymFromId.data.placeSynonym.geonameIdentifier),
      );
      setLocale(returnEmptyStringWhenNull(placeSynonymFromId.data.placeSynonym.locale));
    }

    if (data && data.places) {
      setPlaces(data.places);
    }
  }, [placeSynonymFromId, data]);

  const submitToBackEnd = debounce(() => {
    props.resetError();

    if (isNewRecord) {
      createPlaceSynonym({
        variables: { name, geonameIdentifier, locale, placeId },
      })
        .then(({ data }) => {
          props.history.push(`/admin/place_synonyms/${data.createPlaceSynonym.id}`);
        })
        .catch(() => props.onError());
    } else {
      editPlaceSynonym({
        variables: { id, name, geonameIdentifier, locale, placeId },
        refetchQueries: [getPlaceSynonym(id)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/place_synonyms/${data.editPlaceSynonym.id}`);
        })
        .catch(() => props.onError());
    }
  }, 500);

  let placeOptions;

  if (!places) {
    if (
      !isNewRecord &&
      placeSynonymFromId &&
      placeSynonymFromId.data &&
      !placeSynonymFromId.loading
    ) {
      placeOptions = [
        {
          value: placeSynonymFromId.data.placeSynonym.place.id,
          label: placeSynonymFromId.data.placeSynonym.place.name,
        },
      ];
    } else {
      placeOptions = [];
    }
  } else {
    placeOptions =
      places &&
      places.edges.map(place => ({
        value: place.node.id,
        label: place.node.name,
      }));
  }

  return (
    <div className="common-form">
      <div className="common-form__title">
        {isNewRecord ?
          t('shared.new', { entityName: t('placeSynonyms.show.title') })
          : t('shared.editTitle', { entityName: t('placeSynonyms.show.title') })}
      </div>
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit, callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-lg-8 col-md-10 col-sm-10 col-xs-10">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field placeSynonym-form__name">
                <Input
                  label={t('placeSynonyms.attributes.name')}
                  value={name}
                  onChange={onNameChange}
                />
              </div>
              <div className="common-form__field placeSynonym-form__geonameIdentifier">
                <Input
                  label={t('placeSynonyms.attributes.geonameIdentifier')}
                  value={geonameIdentifier}
                  onChange={onGeonameIdentifierChange}
                />
              </div>
              <div className="common-form__submit-button">
                <Button
                  label={isNewRecord ? t('shared.create') : t('shared.update')}
                  onClick={submitToBackEnd}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field placeSynonym-form__place">
                <SelectBox
                  label={t('placeSynonyms.attributes.place')}
                  value={placeId}
                  options={placeOptions}
                  onChange={onPlaceIdChange}
                  onSearchChange={value => onPlaceSearch(value)}
                  renderNoResultsFound={() => <NoResultsFound />}
                />
              </div>
              <div className="common-form__field placeSynonym-form__locale">
                <Input
                  label={t('placeSynonyms.attributes.locale')}
                  value={locale}
                  onChange={onLocaleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithErrorHandler(PlaceSynonymForm);

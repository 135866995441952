import React from 'react';
import { Query } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../common/Header';
import SanitizedHTML from '../sanitizedHTML';
import { getGlossary } from '../../graphql/queries/glossary';
import isQuillEmpty from '../../helpers/isQuillEmpty';

const Glossary = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  return (
    <div className="list">
      <Header
        title="glossaries.show.title"
        linkTo={`/admin/glossaries/${id}/edit`}
        buttonLabel="shared.edit"
      />
      <Query {...getGlossary(id)}>
        {({ loading, data }) => {
          if (loading) return <div className="lds-dual-ring" />;
          if (data) {
            const { glossary } = data;
            const keywords = glossary.keywords.map(keyword => keyword.name).join(', ');

            return (
              <div className="common-show">
                <div className="grid">
                  <div className="col-12">
                    <div className="common-show__infos">
                      <div className="col-12">
                        <div className="common-show__infos--field">
                          {t('glossaries.attributes.term')}
                        </div>
                        <div className="common-show__infos--value">
                          {glossary.term || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('glossaries.attributes.category')}
                        </div>
                        <div className="common-show__infos--value">
                          {glossary.category
                            ? t(`categories.${glossary.category}`)
                            : t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('glossaries.attributes.subCategory')}
                        </div>
                        <div className="common-show__infos--value">
                          {glossary.subCategory
                            ? t(`subCategories.${glossary.subCategory}`)
                            : t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('glossaries.attributes.keywords')}
                        </div>
                        <div className="common-show__infos--value">
                          {keywords || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('glossaries.attributes.definition')}
                        </div>
                        <div className="common-show__infos--value">
                          {isQuillEmpty(glossary.definition) ? (
                            t('shared.notAvailable')
                          ) : (
                            <SanitizedHTML html={glossary.definition} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return null;
        }}
      </Query>
    </div>
  );
};

export default Glossary;

import gql from 'graphql-tag';

export const CREATE_PLACE = gql`
  mutation createPlace(
    $name: String!
    $countryId: ID!
    $description: String
    $publishDescription: Boolean
    $twitterHashtag: String
    $geonameIdentifier: String
    $admin1Code: String
    $admin2Code: String
    $admin3Code: String
    $admin4Code: String
    $featureCode: String
    $latitude: String!
    $longitude: String!
    $timezone: String!
    $image: Upload
  ) {
    createPlace(
      name: $name
      countryId: $countryId
      description: $description
      publishDescription: $publishDescription
      twitterHashtag: $twitterHashtag
      geonameIdentifier: $geonameIdentifier
      admin1Code: $admin1Code
      admin2Code: $admin2Code
      admin3Code: $admin3Code
      admin4Code: $admin4Code
      featureCode: $featureCode
      latitude: $latitude
      longitude: $longitude
      timezone: $timezone
      image: $image
    ) {
      id
      name
      latitude
      longitude
    }
  }
`;

export const EDIT_PLACE = gql`
  mutation editPlace(
    $id: ID!
    $name: String!
    $countryId: ID!
    $description: String
    $publishDescription: Boolean!
    $twitterHashtag: String
    $geonameIdentifier: String
    $admin1Code: String
    $admin2Code: String
    $admin3Code: String
    $admin4Code: String
    $featureCode: String
    $latitude: String!
    $longitude: String!
    $timezone: String!
    $image: Upload
  ) {
    editPlace(
      id: $id
      name: $name
      countryId: $countryId
      description: $description
      publishDescription: $publishDescription
      twitterHashtag: $twitterHashtag
      geonameIdentifier: $geonameIdentifier
      admin1Code: $admin1Code
      admin2Code: $admin2Code
      admin3Code: $admin3Code
      admin4Code: $admin4Code
      featureCode: $featureCode
      latitude: $latitude
      longitude: $longitude
      timezone: $timezone
      image: $image
    ) {
      id
      name
      description
      publishDescription
      twitterHashtag
      geonameIdentifier
      admin1Code
      admin2Code
      admin3Code
      admin4Code
      featureCode
      latitude
      longitude
      timezone
      imageUrls
      country {
        id
        name
      }
    }
  }
`;

export const DELETE_PLACE = gql`
  mutation deletePlace($id: ID!) {
    deletePlace(id: $id) {
      name
    }
  }
`;

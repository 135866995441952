import gql from 'graphql-tag';
import { all, one } from './prepareQueryParams';

export const GET_CURSORS_FOR_GET_HUMAN_VERIFIED_HOTELS = gql`
  query humanVerifiedHotels($first: Int, $after: String, $query: String) {
    humanVerifiedHotels(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_HUMAN_VERIFIED_HOTELS = gql`
  query humanVerifiedHotels($first: Int, $after: String, $query: String) {
    humanVerifiedHotels(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          mainSource
          bestMatch
          matchedSources
        }
      }
    }
  }
`;

export const getHumanVerifiedHotels = after => all(GET_HUMAN_VERIFIED_HOTELS, after);

export const GET_HUMAN_VERIFIED_HOTEL = gql`
  query humanVerifiedHotel($id: ID!) {
    humanVerifiedHotel(id: $id) {
      id
      mainSource
      bestMatch
      matchedSources
    }
  }
`;

export const getHumanVerifiedHotel = id => one(GET_HUMAN_VERIFIED_HOTEL, id);

export const GET_CURSORS_FOR_UNMATCHED_HUMAN_VERIFIED_HOTELS = gql`
  query unmatchedHumanVerifiedHotels($first: Int, $after: String, $query: String) {
    unmatchedHumanVerifiedHotels(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_UNMATCHED_HUMAN_VERIFIED_HOTELS = gql`
  query unmatchedHumanVerifiedHotels($first: Int, $after: String, $query: String) {
    unmatchedHumanVerifiedHotels(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          mainSource
          bestMatch
        }
      }
    }
  }
`;

export const GET_TOTAL_HUMAN_VERIFIED_HOTELS = gql`
  query totalHumanVerifiedHotels($type: String) {
    totalHumanVerifiedHotels(type: $type)
  }
`;

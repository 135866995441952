import React, { useEffect, useState, useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { itemsPerPage, maxPageNumberForFullLoad } from '../../variables';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@bit/mno-wtag.welltravel.button';
import Header from '../common/Header';
import Paginate from '../common/Paginate';
import EmptyContentPlaceholder from '../common/EmptyContentPlaceholder';
import Input from '@bit/mno-wtag.welltravel.input';
import GET_COUNT_OF_TOTAL_CURSORS from '../../graphql/queries/totalCursorsCount';
import { GET_PLATFORM_FEATURES, GET_CURSORS_FOR_PLATFORM_FEATURES} from '../../graphql/queries/platformFeature';
import { debounce } from 'lodash';
import PlatformFeatureData from './PlatformFeatureData';
import PropTypes from 'prop-types';
import SpinnerIcon from '../../assets/icons/spinner.svg';
import IconButton from '../rclComponents/IconButton';
import Icon from '../icon/Icon';
import Hashtag from '../hashtag';
import isEntityAvailable from '../../helpers/isEntityAvailable';

const PlatformFeatureList = props => {
  const { t } = useTranslation();

  const [cursorState, setCursorState] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(3);
  const [currentCursor, setCurrentCursor] = useState(null);
  const [searchParam, setSearchParam] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [cursors, setCursors] = useState([]);
  const [cursorFetchNumber, setCursorFetchNumber] = useState(itemsPerPage * 4);
  const [requiredLenghToSearch, setRequiredLengthToSearch] = useState(3);

  const cursorData = useQuery(GET_CURSORS_FOR_PLATFORM_FEATURES, {
    variables: { first: cursorFetchNumber, after: currentCursor, query: searchParam },
  });
  const cursorCountData = useQuery(GET_COUNT_OF_TOTAL_CURSORS, {
    variables: { modelName: 'platformFeatures', query: searchParam, currentTab: 0 },
  });
  const { loading, data } = useQuery(GET_PLATFORM_FEATURES, {
    variables: { first: itemsPerPage, after: currentCursor, query: searchParam },
  });

  const callSearchParam = useRef(
    debounce(async inputParam => {
      setSearchParam(inputParam);
    }, 1000),
  );

  const resetSearch = () => {
    setSearchParam('');
    setSearchValue('');
  };

  const postIcon = () => {
    if (loading) {
      return <img className="list__search-post-icon" src={SpinnerIcon} alt="spinner" />;
    }
    return (
      <IconButton
        size="large"
        className="list__search-post-icon"
        isIconOnly={true}
        icon={<Icon name="close" />}
        onClick={resetSearch}
      />
    );
  };

  const forcePage = currentPage - 1;

  useEffect(() => {
    if (cursorCountData && cursorCountData.data && !cursorCountData.loading) {
      const count = cursorCountData.data.totalCursorsCount / itemsPerPage;
      if (count <= maxPageNumberForFullLoad) {
        setRequiredLengthToSearch(2);
        setCursorFetchNumber(null);
      } else {
        setRequiredLengthToSearch(3);
        setCursorFetchNumber(itemsPerPage * 4);
      }
      setPageCount(Math.ceil(count));
    }
    if (cursorData && cursorData.data && !cursorData.loading && !cursorState) {
      setCursorState(true);
      const oldCursors = Object.assign([], cursors);
      const newCursors = cursorData.data['platformFeatures'].edges.map(edge => edge.cursor);
      const finalCursors = oldCursors.concat(
        newCursors.filter(newCursor => oldCursors.indexOf(newCursor) < 0),
      );
      setCursors(finalCursors);
    }
  }, [cursorCountData, cursorData, cursors, cursorState]);

  const changePage = value => {
    setCurrentPage(value.selected + 1);
    const cursorIndexFromCurrentPage = value.selected * itemsPerPage - 1;
    if (cursorIndexFromCurrentPage < 0) {
      setCurrentCursor(null);
    } else {
      setCurrentCursor(cursors[cursorIndexFromCurrentPage]);
    }
  };

  const onSearchParamChange = event => {
    setSearchValue(event.target.value);
    if (event.target.value === '' || event.target.value.length >= requiredLenghToSearch) {
      callSearchParam.current(event.target.value);
    }
  };

  return (
    <div className="platform-feature-list">
      <Header
        title="nav.platformFeatures"
        linkTo="/admin/platform_features/new"
        buttonLabel="platformFeatures.buttons.addNew"
      >
        <div className="header__children">
          <Link to={`/admin/platform_feature_labels/new`}>
            <Button label={t('platformFeatures.buttons.addNewLabel')} />
          </Link>
          <div className="hashtag-wrapper">
            <Hashtag
              headerLabel="platformFeatures.attributes.keywords"
              currentContentLabel="platformFeatures.index.currentKeywords"
            />
          </div>
        </div>
      </Header>
      <div>
        <Input
          label={t('list.search.label')}
          value={searchValue}
          onChange={onSearchParamChange}
          placeholder={t('platformFeatures.index.searchPlaceholder')}
          postIcon={postIcon()}
        />
      </div>
      {loading && <div className="lds-dual-ring" />}
      {!loading && (
        <React.Fragment>
          {isEntityAvailable(data, 'platformFeatures') ? (
            <>
              <div className="platform-feature-list__pagination">
                <Paginate
                  pageCount={pageCount}
                  onPageChange={changePage}
                  forcePage={forcePage}
                  marginPagesDisplayed={cursorFetchNumber ? 0 : 1}
                />
              </div>
              <div className="platform-feature-list__header">
                <div className="platform-feature-list__value-topic platform-feature-list__value-title" key={'label_topic'}>
                  <p>{t('platformFeatures.attributes.labelTopic')}</p>
                </div>

                <div className="platform-feature-list__value platform-feature-list__value-title" key={'order'}>
                  <p>{t(`platformFeatures.attributes.order`)}</p>
                </div>

                <div className="platform-feature-list__value platform-feature-list__value--actions">
                  <p>{t('list.header.actions')}</p>
                </div>
              </div>

              {data['platformFeatures'] && (
                <div className="platform-feature-list__table">
                  {data['platformFeatures'].edges.map(data => (
                    <React.Fragment key={data.node.id}>
                      <PlatformFeatureData
                        data={data.node}
                        searchParam={searchParam}
                        currentCursor={currentCursor}
                        userId={props.userId}
                        depth={1}
                      />
                    </React.Fragment>
                  ))}
                </div>
              )}
              <div className="platform-feature-list__pagination">
                <Paginate
                  pageCount={pageCount}
                  onPageChange={changePage}
                  forcePage={forcePage}
                  marginPagesDisplayed={cursorFetchNumber ? 0 : 1}
                />
              </div>
            </>
          ) : (
            <EmptyContentPlaceholder entityName={t('nav.platformFeatures').toLowerCase()} modelName={'PlatformFeature'} />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

PlatformFeatureList.propTypes = {
  userId: PropTypes.string.isRequired,
};
export default PlatformFeatureList;

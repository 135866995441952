import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@bit/mno-wtag.welltravel.button';

import BaseModal from '../rclComponents/BaseModal';
import { useMutation } from '@apollo/react-hooks';
import ErrorComponent from '../common/ErrorComponent';
import { EDIT_HOTEL_COMBINED_DATABASE } from '../../graphql/mutations/hotelCombinedDatabase';
import PropTypes from 'prop-types';
import HotelInformationCard from './HotelInformationCard';
import { getHotelCombinedDatabase } from '../../graphql/queries/hotelCombinedDatabase';
import { EDIT_HUMAN_VERIFIED_HOTEL } from '../../graphql/mutations/humanVerifiedHotel';
import { all } from '../../graphql/queries/prepareQueryParams';
import { GET_HUMAN_VERIFIED_HOTELS } from '../../graphql/queries/humanVerifiedHotel';
import { humanVerifiedHotelsPerPage } from '../../variables';

const CombineHotelView = props => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const {
    name,
    iso31661,
    iso31662,
    description,
    latitude,
    longitude,
    welltravelCodes,
    additionalContents,
    supplierCodes,
    refetchTotalVerifiableHotels,
  } = props;

  const id = props.combinedHotelId;
  const [editHotelCombinedDatabase, callbackDataOnEdit] = useMutation(EDIT_HOTEL_COMBINED_DATABASE);
  const [editHumanVerifiedHotel] = useMutation(EDIT_HUMAN_VERIFIED_HOTEL);

  const submitOnCreateToBackEnd = () => {
    const verified = true;
    setErrorOccurred(false);
    editHotelCombinedDatabase({
      variables: {
        id,
        name,
        welltravelCodes,
        iso31661,
        iso31662,
        additionalContents,
        supplierCodes,
        latitude,
        longitude,
        description,
      },
      refetchQueries: [getHotelCombinedDatabase(id)],
    })
      .then(() => {
        editHumanVerifiedHotel({
          variables: { id: props.humanVerifiedHotelId, verified },
          refetchQueries: [
            all(
              GET_HUMAN_VERIFIED_HOTELS,
              props.currentCursor,
              props.searchParam,
              humanVerifiedHotelsPerPage,
            ),
            all(GET_HUMAN_VERIFIED_HOTELS, props.currentCursor, '', humanVerifiedHotelsPerPage),
          ],
        }).then(() => {
          props.closeModal();
          setModalOpen(false);
          return refetchTotalVerifiableHotels();
        });
      })
      .catch(() => {
        setErrorOccurred(true);
      });
  };

  return (
    <BaseModal
      linkText={t('shared.continue')}
      linkClassName="button"
      defaultPadding={true}
      header={t('humanVerifiedHotels.show.verify')}
      open={modalOpen}
      onModalClose={() => {
        setModalOpen(false);
      }}
      onModalOpen={() => {
        setModalOpen(true);
      }}
      size="huge"
    >
      <div className="combine-hotel-view-content">
        <HotelInformationCard
          id={id}
          latitude={latitude}
          longitude={longitude}
          name={name}
          iso31661={iso31661}
          iso31662={iso31662}
          supplierCodes={supplierCodes}
          itemType="hotelCombinedDatabases"
          welltravelCodes={welltravelCodes}
          additionalContents={additionalContents}
          description={description}
        />

        {errorOccurred && <ErrorComponent error={{ callbackDataOnEdit }} />}
        <div className="combine-hotel-view-content__submit">
          <Button
            label={t('humanVerifiedHotels.buttons.combine')}
            onClick={submitOnCreateToBackEnd}
          />
        </div>
        <div className="combine-hotel-view-content__cancel">
          <Button
            type={'danger'}
            label={t('shared.back')}
            onClick={() => {
              setModalOpen(false);
            }}
          />
        </div>
      </div>
    </BaseModal>
  );
};

CombineHotelView.propTypes = {
  currentCursor: PropTypes.string,
  searchParam: PropTypes.string,
  combinedHotelId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  iso31661: PropTypes.string,
  iso31662: PropTypes.string,
  description: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  welltravelCodes: PropTypes.arrayOf(PropTypes.string),
  additionalContents: PropTypes.object,
  supplierCodes: PropTypes.object,
  closeModal: PropTypes.func,
  humanVerifiedHotelId: PropTypes.string.isRequired,
  refetchTotalVerifiableHotels: PropTypes.func.isRequired,
};

export default CombineHotelView;

import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_ALL_TRAVEL_NEWS = gql`
  query travelNews($first: Int, $after: String, $query: String) {
    travelNews(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_ALL_TRAVEL_NEWS = gql`
  query travelNews($first: Int, $after: String, $query: String) {
    travelNews(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          title
          teaser
          source
          externalLink
          author
          platform
          content
          imageUrls
          place {
            id
            name
          }
          country {
            id
            name
          }
          hashtags {
            id
            name
          }
        }
      }
    }
  }
`;

export const getAllTravelNews = after => all(GET_ALL_TRAVEL_NEWS, after);

export const GET_TRAVEL_NEWS = gql`
  query travelNewsOne($id: ID!) {
    travelNewsOne(id: $id) {
      id
      title
      teaser
      source
      externalLink
      author
      platform
      content
      imageUrls
      place {
        id
        name
      }
      country {
        id
        name
      }
      hashtags {
        id
        name
      }
    }
  }
`;

export const getTravelNews = id => one(GET_TRAVEL_NEWS, id);

export const GET_TRAVEL_NEWS_PLATFORMS = gql`
  query travelNewsPlatforms {
    travelNewsPlatforms {
      label
      value
    }
  }
`;

import React, { useState } from 'react';
import { GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow } from 'react-google-maps';
import PropTypes from 'prop-types';

const Map = props => {
  const [selected, setSelected] = useState(false);
  const onMarkerDragEnd = event => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    props.getLatLong({ lat, lng });
  };

  return (
    <GoogleMap defaultZoom={16} defaultCenter={{ lat: props.latLong.lat, lng: props.latLong.lng }}>
      <Marker
        key={1}
        position={{ lat: props.latLong.lat, lng: props.latLong.lng }}
        onClick={() => {
          setSelected(true);
        }}
        draggable={!!props.getLatLong}
        onDragEnd={event => onMarkerDragEnd(event)}
      />
      {selected && (
        <InfoWindow
          position={{ lat: props.latLong.lat, lng: props.latLong.lng }}
          onCloseClick={() => {
            setSelected(false);
          }}
        >
          <div>{props.infoWindowContent}</div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

const WrappedMap = withScriptjs(withGoogleMap(Map));

WrappedMap.defaultProps = {
  loadingElement: <div style={{ height: '100%' }} />,
  containerElement: <div style={{ height: '100%' }} />,
  mapElement: <div style={{ height: '100%' }} />,
  infoWindowContent: '',
};

WrappedMap.propTypes = {
  googleMapURL: PropTypes.string.isRequired,
  loadingElement: PropTypes.node,
  containerElement: PropTypes.node,
  mapElement: PropTypes.node,
  latLong: PropTypes.object.isRequired,
  infoWindowContent: PropTypes.string,
  getLatLong: PropTypes.func,
};

export default WrappedMap;

import React from 'react';
import Button from '@bit/mno-wtag.welltravel.button';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';
import Input from '@bit/mno-wtag.welltravel.input';
import SelectBox from '@bit/mno-wtag.welltravel.select-box';
import availableLanguageOptions from '../../helpers/availableLanguageOptions';
import { DEFAULT_LOCALE } from '../../variables';

const NameTranslationsForm = ({
  formIndex,
  translationsAttribute,
  setTranslationsAttributes,
  translationsAttributes,
}) => {
  const { t } = useTranslation();
  const availableTranslatedNameCount = translationsAttributes.length;
  const MAX_NEW_FORM_LENGTH = 4;
  const nameTranslationId = translationsAttribute.id;
  const disableDeleteButton = translationsAttribute.locale === DEFAULT_LOCALE;
  const lastLanguageOption = formIndex === MAX_NEW_FORM_LENGTH;
  const showAddFormButton = !lastLanguageOption && availableTranslatedNameCount === formIndex + 1;

  const languageOptions = selectedLocale => {
    // extracting available selectable language options including current selected locale
    return availableLanguageOptions.filter(
      ({ value: optionLocale }) =>
        !translationsAttributes.some(
          ({ locale: assignedLocale }) =>
            selectedLocale !== optionLocale && optionLocale === assignedLocale,
        ),
    );
  };

  const translationsAttributeHasNationality = translationsAttribute.hasOwnProperty('nationality');

  const addTranslationAttributeForm = () => {
    const newFromAttributes = { id: uuidv4(), name: '', locale: '' };

    if (translationsAttributeHasNationality) {
      newFromAttributes.nationality = '';
    }
    setTranslationsAttributes(prevAttributes => [...prevAttributes, newFromAttributes]);
  };

  const updateTranslatedNames = (changedLocale, changedName, id) => {
    setTranslationsAttributes(prevAttributes =>
      prevAttributes.map(item => {
        if (item.id === id) {
          return { ...item, locale: changedLocale, name: changedName };
        }
        return item;
      }),
    );
  };

  const updateTranslatedNationalities = (changedLocale, changedNationality, id) => {
    setTranslationsAttributes(prevAttributes =>
      prevAttributes.map(item => {
        if (item.id === id) {
          return { ...item, locale: changedLocale, nationality: changedNationality };
        }
        return item;
      }),
    );
  };

  const deleteTranslatedNameAttribute = id => {
    setTranslationsAttributes(prevAttributes => {
      const toBeDeletedItemIndex = prevAttributes.findIndex(item => item.id === id);
      const toBeDeletedItem = prevAttributes[toBeDeletedItemIndex];
      prevAttributes.splice(toBeDeletedItemIndex, 1);

      if (uuidValidate(id)) {
        return [...prevAttributes];
      } else {
        return [...prevAttributes, { ...toBeDeletedItem, _destroy: true }];
      }
    });
  };

  const onNameChange = name => {
    updateTranslatedNames(translationsAttribute.locale, name, nameTranslationId);
  };

  const onNationalityChange = nationality => {
    updateTranslatedNationalities(translationsAttribute.locale, nationality, nameTranslationId);
  };

  const onLocaleChange = locale => {
    updateTranslatedNames(locale, translationsAttribute.name, nameTranslationId);
  };

  return (
    <React.Fragment>
      <div className="name-translation-form name-translation-form__container">
        <div className="col-4 name-translation-form__container-segment-field">
          <SelectBox
            value={translationsAttribute.locale}
            onChange={selectedLocale => onLocaleChange(selectedLocale)}
            options={languageOptions(translationsAttribute.locale)}
            required={true}
            disabled={disableDeleteButton}
            width="full"
            label={t('cities.attributes.locale')}
          />
        </div>
        <div className="col-4 name-translation-form__container-segment-field">
          <Input
            label={t('cities.attributes.name')}
            value={translationsAttribute.name}
            required={true}
            onChange={event => onNameChange(event.target.value)}
          />
        </div>

        {translationsAttributeHasNationality && (
          <div className="col-4 name-translation-form__container-segment-field">
            <Input
              label={t('countries.attributes.nationality')}
              value={translationsAttribute.nationality || ''}
              required={true}
              onChange={event => onNationalityChange(event.target.value)}
            />
          </div>
        )}

        <div className="name-translation-form__submit-button">
          <Button
            label={t('shared.delete')}
            onClick={() => deleteTranslatedNameAttribute(nameTranslationId)}
            disabled={disableDeleteButton}
            type="darkOverImage"
          />
          {showAddFormButton && (
            <Button label={t('shared.addNew')} onClick={() => addTranslationAttributeForm()} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

NameTranslationsForm.propTypes = {
  formIndex: PropTypes.number.isRequired,
  translationsAttributes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      locale: PropTypes.string,
      nationality: PropTypes.string,
    }),
  ).isRequired,
  setTranslationsAttributes: PropTypes.func.isRequired,
  translationsAttribute: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    locale: PropTypes.string,
    nationality: PropTypes.string,
  }).isRequired,
};

export default NameTranslationsForm;

import React from 'react';

const ErrorComponent = props => {
  const { error } = props;
  const { callbackDataOnEdit, callbackDataOnCreate } = error;

  let graphQLErrors;
  let networkError;

  if (callbackDataOnCreate && callbackDataOnCreate.called){
    graphQLErrors = callbackDataOnCreate.error.graphQLErrors;
    networkError = callbackDataOnCreate.error.networkError;
  } else {
    graphQLErrors = callbackDataOnEdit.error.graphQLErrors;
    networkError = callbackDataOnEdit.error.networkError;
  }

  return (
    <div className="error-component">
      {graphQLErrors && graphQLErrors.map(({ message }, i) => (
        <span key={i}>{message}</span>
      ))}
      {networkError && networkError.map(({ message }, i) => (
        <span key={i}>{message}</span>
      ))}
    </div>
  );
};
export default ErrorComponent;

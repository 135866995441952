import React from 'react';

import {
  GET_ALL_TRAVEL_NEWS,
  GET_CURSORS_FOR_ALL_TRAVEL_NEWS,
} from '../../graphql/queries/travelNews';
import { DELETE_TRAVEL_NEWS } from '../../graphql/mutations/travelNews';

import Hashtag from '../hashtag';
import List from '../common/List';

const TravelNewsList = () => {
  return (
    <List
      getItems={GET_ALL_TRAVEL_NEWS}
      getCursorsForItems={GET_CURSORS_FOR_ALL_TRAVEL_NEWS}
      deleteItem={DELETE_TRAVEL_NEWS}
      listAttributes={['title', 'teaser', 'platform']}
      itemType="travelNews"
      modelName="TravelNews"
      title="nav.travelNews"
      linkTo="/admin/travel_news/new"
      buttonLabel="shared.addNew"
    >
      <div className="hashtag-wrapper">
        <Hashtag />
      </div>
    </List>
  );
};

export default TravelNewsList;

import React from 'react';
import PropTypes from 'prop-types';

const RawJSON = props => (
  <pre className={props.className}>{JSON.stringify(props.data, undefined, props.space)}</pre>
);

RawJSON.defaultProps = {
  data: null,
  space: 4,
  className: 'common-show__infos--value common-show__infos--value-json',
};

RawJSON.propTypes = {
  data: PropTypes.object,
  space: PropTypes.number,
  className: PropTypes.string,
};

export default RawJSON;

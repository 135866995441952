import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_AIRLINES = gql`
  query airlines(
    $first: Int
    $after: String
    $query: String
    $currentSortAttribute: String
    $currentSortAttributeDirection: String
    $withUnverifiedFareBrand: Boolean
  ) {
    airlines(
      first: $first
      after: $after
      query: $query
      currentSortAttribute: $currentSortAttribute
      currentSortAttributeDirection: $currentSortAttributeDirection
      withUnverifiedFareBrand: $withUnverifiedFareBrand
    ) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_AIRLINES = gql`
  query airlines(
    $first: Int
    $after: String
    $query: String
    $currentSortAttribute: String
    $currentSortAttributeDirection: String
  ) {
    airlines(
      first: $first
      after: $after
      query: $query
      currentSortAttribute: $currentSortAttribute
      currentSortAttributeDirection: $currentSortAttributeDirection
    ) {
      edges {
        cursor
        node {
          id
          code
          name
          logoUrl
          url
          facebook
          twitter
          instagram
          description
          publishDescription
          imageUrls
          twitter
          checkInUrl
          legalName
          icaoCode
          deactivated
          addressLine
          postalCode
          city
          state
          termsAndConditions
          lastUpdatedAt
          fareBrands {
            id
            name
            bookingClass
            cabinClass {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const getAirlines = after => all(GET_AIRLINES, after);

export const GET_AIRLINES_WITH_UNVERIFIED_FARE_BRANDS = gql`
  query airlines(
    $first: Int
    $after: String
    $query: String
    $currentSortAttribute: String
    $currentSortAttributeDirection: String
    $withUnverifiedFareBrand: Boolean
  ) {
    airlines(
      first: $first
      after: $after
      query: $query
      currentSortAttribute: $currentSortAttribute
      currentSortAttributeDirection: $currentSortAttributeDirection
      withUnverifiedFareBrand: $withUnverifiedFareBrand
    ) {
      edges {
        cursor
        node {
          id
          name
          code
          lastUpdatedAt
          unverifiedFareBrands {
            id
            brandName
            supplierName
            cabinClass
            bookingClass
            airlineCode
            matchedFareBrands {
              id
              name
              bookingClass
              cabinClass {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_AIRLINE = gql`
  query airline($id: ID!) {
    airline(id: $id) {
      code
      name
      logoUrl
      url
      facebook
      twitter
      instagram
      description
      publishDescription
      imageUrls
      checkInUrl
      twitterHandle
      legalName
      icaoCode
      deactivated
      addressLine
      postalCode
      city
      state
      termsAndConditions
      lastUpdatedAt
      cabinClasses {
        name
      }
      country {
        id
        name
      }
    }
  }
`;

export const getAirline = id => one(GET_AIRLINE, id);

import gql from 'graphql-tag';

export const CREATE_TRAVEL_NEWS = gql`
  mutation createTravelNews(
    $title: String!
    $teaser: String
    $source: String
    $externalLink: String
    $author: String
    $platform: String
    $content: String
    $image: Upload
    $placeId: ID
    $countryId: ID
    $hashtagIds: [String!]
  ) {
    createTravelNews(
      title: $title
      teaser: $teaser
      source: $source
      externalLink: $externalLink
      author: $author
      platform: $platform
      content: $content
      image: $image
      placeId: $placeId
      countryId: $countryId
      hashtagIds: $hashtagIds
    ) {
      id
      title
      teaser
      source
      externalLink
      author
      platform
      content
      imageUrls
      place {
        id
        name
      }
      country {
        id
        name
      }
      hashtags {
        id
        name
      }
    }
  }
`;

export const EDIT_TRAVEL_NEWS = gql`
  mutation editTravelNews(
    $id: ID!
    $title: String!
    $teaser: String
    $source: String
    $externalLink: String
    $author: String
    $platform: String
    $content: String
    $image: Upload
    $placeId: ID
    $countryId: ID
    $hashtagIds: [String!]
  ) {
    editTravelNews(
      id: $id
      title: $title
      teaser: $teaser
      source: $source
      externalLink: $externalLink
      author: $author
      platform: $platform
      content: $content
      image: $image
      placeId: $placeId
      countryId: $countryId
      hashtagIds: $hashtagIds
    ) {
      id
      title
      teaser
      source
      externalLink
      author
      platform
      content
      imageUrls
      place {
        id
        name
      }
      country {
        id
        name
      }
      hashtags {
        id
        name
      }
    }
  }
`;

export const DELETE_TRAVEL_NEWS = gql`
  mutation deleteTravelNews($id: ID!) {
    deleteTravelNews(id: $id) {
      id
    }
  }
`;

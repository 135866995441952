import React from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Paginate = props => {
  const { t } = useTranslation();
  return (
    <ReactPaginate
      pageCount={props.pageCount}
      pageRangeDisplayed={props.pageRangeDisplayed}
      marginPagesDisplayed={props.marginPagesDisplayed}
      forcePage={props.forcePage}
      onPageChange={props.onPageChange}
      containerClassName="paginate__container"
      pageClassName="paginate__list"
      previousClassName="paginate__previous"
      previousLinkClassName="paginate__previous--link"
      nextClassName="paginate__next"
      nextLinkClassName="paginate__next--link"
      breakClassName="paginate__previous"
      activeClassName="paginate__list--active"
      pageLinkClassName="paginate__list--link"
      nextLabel={t('list.paginate.next')}
      previousLabel={t('list.paginate.previous')}
    />
  );
};
Paginate.defaultProps = {
  pageRangeDisplayed: 3,
  marginPagesDisplayed: 1,
  forcePage: 1,
};

Paginate.propTypes = {
  pageCount: PropTypes.number.isRequired,
  pageRangeDisplayed: PropTypes.number,
  marginPagesDisplayed: PropTypes.number,
  forcePage: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
};

export default Paginate;

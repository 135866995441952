import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_HASHTAGS = gql`
  query hashtags($first: Int, $after: String, $query: String) {
    hashtags(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_HASHTAGS = gql`
  query hashtags($first: Int, $after: String, $query: String) {
    hashtags(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

export const getHashtags = after => all(GET_HASHTAGS, after);

export const GET_HASHTAG = gql`
  query hashtag($id: ID!) {
    hashtag(id: $id) {
      id
      name
    }
  }
`;

export const getHashtag = id => one(GET_HASHTAG, id);

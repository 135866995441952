// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !! COPIED FROM RCL as it's not available in Meta, yet !!
// !! Please don't modify                                !!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

const colors = {
  coolGray20: '#303030',
  coolGray45: '#707070',
  coolGray70: '#ababab',
  coolGray95: '#f1f1f1',
  attractingPeach: 'ff605d',
};

export default colors;

import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_CONTINENTS= gql`
  query continents($first: Int,$after: String, $query: String) {
    continents(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_CONTINENTS = gql`
  query continents($first: Int, $after: String, $query: String) {
    continents(first: $first, after: $after, query: $query)  {
      edges {
        cursor
        node {
          id
          name
          code
        }
      }
    }
  }
`;

export const getContinents = after => all(GET_CONTINENTS, after);

export const GET_CONTINENT = gql`
  query continent($id: ID!){
    continent(id: $id) {
      name
      code
      countries{
        id
        name
      }
    }
  }
`;

export const getContinent = id => one(GET_CONTINENT, id);

import React, { useState, Fragment } from 'react';
import Button from '@bit/mno-wtag.welltravel.button';
import { Link } from 'react-router-dom';
import Icon from '@wtag/rcl-icon';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const CountryTableRow = ({ data, listAttributes, onDelete }) => {
  const { t } = useTranslation();
  const [expand, setExpand] = useState(false);

  const renderValue = (item, value) => {
    const key = `${item}-${value}`;
    if (item === 'name') {
      return (
        <div
          className="country-list__data-value country-list__data-value-clickable"
          key={key}
          role="presentation"
          onClick={() => setExpand(prevState => !prevState)}
        >
          {value}
          <div className="country-list__data-value-clickable-icon">
            {expand ? <Icon name="iconUpChevron" /> : <Icon name="iconDownChevron" />}
          </div>
        </div>
      );
    }
    if (item === 'nationality' && value !== '') {
      return (
        <div
          className="country-list__data-value country-list__data-value-clickable"
          key={key}
          role="presentation"
          onClick={() => setExpand(prevState => !prevState)}
        >
          {value}
          <div className="country-list__data-value-clickable-icon">
            {expand ? <Icon name="iconUpChevron" /> : <Icon name="iconDownChevron" />}
          </div>
        </div>
      );
    }

    return (
      <div className="country-list__data-value" key={key}>
        {value}
      </div>
    );
  };

  return (
    <Fragment key={data.id}>
      <div className="country-list__data" key={data.id}>
        {listAttributes.map(item => renderValue(item, data[item]))}
        <div className="country-list__data-value country-list__data-value--actions">
          <Link to={`/admin/countries/${data.id}`}>
            <Button label={t('shared.show')} />
          </Link>
          <Link to={`/admin/countries/${data.id}/edit`}>
            <Button label={t('shared.edit')} />
          </Link>
          <Button label={t('shared.delete')} onClick={() => onDelete(data.id)} />
        </div>
      </div>

      {expand && (
        <Fragment>
          {data.translatedNames.map(translatedCountry => (
            <div
              className="country-list__data country-list__data--nested"
              key={translatedCountry.id}
            >
              <div className="country-list__data-value">{translatedCountry.name}</div>
              <div className="country-list__data-value">{translatedCountry.locale}</div>
              <div className="country-list__data-value">{translatedCountry.nationality}</div>
            </div>
          ))}
        </Fragment>
      )}
    </Fragment>
  );
};

CountryTableRow.propTypes = {
  data: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  listAttributes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CountryTableRow;

import gql from 'graphql-tag';

export const CREATE_ANCILLARY_SERVICE = gql`
  mutation createAncillaryService(
    $ssrCode: String!
    $serviceType: String!
    $ancillaryCategoryId: ID
    $fullDescription: String
    $description: String!
    $image: Upload
  ) {
    createAncillaryService(
      ssrCode: $ssrCode
      serviceType: $serviceType
      ancillaryCategoryId: $ancillaryCategoryId
      fullDescription: $fullDescription
      description: $description
      image: $image
    ) {
      id
      ssrCode
    }
  }
`;

export const EDIT_ANCILLARY_SERVICE = gql`
  mutation editAncillaryService(
    $id: ID!
    $ssrCode: String!
    $serviceType: String!
    $ancillaryCategoryId: ID
    $fullDescription: String
    $description: String!
    $image: Upload
  ) {
    editAncillaryService(
      id: $id
      ssrCode: $ssrCode
      serviceType: $serviceType
      ancillaryCategoryId: $ancillaryCategoryId
      fullDescription: $fullDescription
      description: $description
      image: $image
    ) {
      id
      ssrCode
      serviceType
      ancillaryCategory {
        id
      }
      fullDescription
      description
      imageUrls
    }
  }
`;

export const DELETE_ANCILLARY_SERVICE = gql`
  mutation deleteAncillaryService($id: ID!) {
    deleteAncillaryService(id: $id) {
      ssrCode
    }
  }
`;

import React, { useState } from 'react';

const WithErrorHandler = WrappedComponent => {
  const WithErrorHandlerComponent = props => {
    const [hasError, setHasError] = useState(false);

    const onError = () => {
      setHasError(true);
    };

    const resetError = () => {
      setHasError(false);
    };

    return (
      <WrappedComponent {...props} onError={onError} resetError={resetError} hasError={hasError} />
    );
  };

  return WithErrorHandlerComponent;
};
export default WithErrorHandler;

import React from 'react'
import Icon from '../icon/Icon';
import Header from '../common/Header';
import { useTranslation } from 'react-i18next';

const ComingSoon = props => {
  const { t } = useTranslation();

  return (
    <div className="list">
      <Header
        title={`${props.title}.show.title`}
      />
      <div className="coming-soon">
        <div className="coming-soon__content">
          <div className="coming-soon__content-icon">
            <Icon name={props.modelName} />
          </div>
          <div className="coming-soon__content-text">{t('shared.comingSoon')}</div>
        </div>
      </div>
    </div>
  )
}

export default ComingSoon;

import gql from 'graphql-tag';

export const CREATE_STATIC_MESSAGE = gql`
  mutation createStaticMessage($key: String!, $message: String!) {
    createStaticMessage(key: $key, message: $message) {
      id
      key
      message
    }
  }
`;

export const EDIT_STATIC_MESSAGE = gql`
  mutation editStaticMessage($id: ID!, $key: String!, $message: String!) {
    editStaticMessage(id: $id, key: $key, message: $message) {
      id
      key
      message
    }
  }
`;

export const DELETE_STATIC_MESSAGE = gql`
  mutation deleteStaticMessage($id: ID!) {
    deleteStaticMessage(id: $id) {
      id
    }
  }
`;

import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_AIRCRAFT_TYPES = gql`
  query aircraftTypes($first: Int, $after: String, $query: String) {
    aircraftTypes(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_AIRCRAFT_TYPES = gql`
  query aircraftTypes($first: Int, $after: String, $query: String) {
    aircraftTypes(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          code
          name
          imageUrls
        }
      }
    }
  }
`;

export const getAircraftTypes = after => all(GET_AIRCRAFT_TYPES, after);

export const GET_AIRCRAFT_TYPE = gql`
  query aircraftType($id: ID!) {
    aircraftType(id: $id) {
      code
      name
      imageUrls
    }
  }
`;

export const getAircraftType = id => one(GET_AIRCRAFT_TYPE, id);

import gql from 'graphql-tag';

export const GET_UNSPLASH_IMAGE_SEARCH_RESULTS = gql`
  query unsplashImageSearch($query: String) {
    unsplashImageSearch(query: $query) {
      smallImageUrl
      thumbImageUrl
      imageId
    }
  }
`;

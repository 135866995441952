import gql from 'graphql-tag';
import {one, all, oneWithUserDefinedKey} from './prepareQueryParams';

export const GET_CURSORS_FOR_RAW_HOTELS = gql`
  query rawHotels($first: Int, $after: String, $query: String, $currentSearchAttribute: String) {
    rawHotels(first: $first, after: $after, query: $query, currentSearchAttribute: $currentSearchAttribute) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_RAW_HOTELS = gql`
  query rawHotels($first: Int, $after: String, $query: String, $currentSearchAttribute: String) {
    rawHotels(first: $first, after: $after, query: $query, currentSearchAttribute: $currentSearchAttribute) {
      edges {
        cursor
        node {
          id
          name
          code
          provider
          welltravelCode
          iso31661
          iso31662
          imageUrls
          description
          latitude
          longitude
          additionalContents
          supplierCodes
          rawData
        }
      }
    }
  }
`;

export const getRawHotels = after => all(GET_RAW_HOTELS, after);

export const GET_RAW_HOTEL = gql`
  query rawHotel($id: ID!) {
    rawHotel(id: $id) {
      id
      name
      code
      provider
      welltravelCode
      iso31661
      iso31662
      imageUrls
      description
      latitude
      longitude
      additionalContents
      supplierCodes
      rawData
    }
  }
`;

export const getRawHotel = id => one(GET_RAW_HOTEL, id);

export const GET_RAW_HOTEL_BY_WELLTRAVEL_CODE = gql`
  query rawHotel($welltravelCode: String) {
    rawHotel(welltravelCode: $welltravelCode) {
      id
      name
      code
      provider
      welltravelCode
      iso31661
      iso31662
      imageUrls
      description
      latitude
      longitude
      additionalContents
      supplierCodes
    }
  }
`;
export const getRawHotelByWelltravelCode = welltravelCode => (
    oneWithUserDefinedKey(GET_RAW_HOTEL_BY_WELLTRAVEL_CODE, 'welltravelCode', welltravelCode) );


import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_COUNTRIES = gql`
  query countries($first: Int, $after: String, $query: String) {
    countries(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_COUNTRIES = gql`
  query countries($first: Int, $after: String, $query: String) {
    countries(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          name
          nationality
          code
          phoneCode
          descriptionStatus
          publishDescription
          apisDataRequired
          translatedNames {
            id
            name
            locale
            nationality
          }
        }
      }
    }
  }
`;

export const getCountries = after => all(GET_COUNTRIES, after);

export const GET_COUNTRY = gql`
  query country($id: ID!) {
    country(id: $id) {
      name
      code
      phoneCode
      nationality
      continent {
        id
        name
      }
      apisDataRequired
      description
      publishDescription
      imageUrls
      translatedNames {
        id
        name
        locale
        nationality
      }
    }
  }
`;

export const getCountry = id => one(GET_COUNTRY, id);

import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import DashboardCard from './DashboardCard';
import GET_DASHBOARD_ATTRIBUTES from '../../graphql/queries/dashboardAttributes';

const Dashboard = () => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(GET_DASHBOARD_ATTRIBUTES);

  return (
    <div className="dashboard">
      <section className="dashboard__header">{t('dashboard.welcome')}</section>
      <section className="dashboard__cards">
        {loading && <div className="lds-dual-ring" />}
        {data &&
          data.dashboardAttributes.map(attribute => (
            <DashboardCard
              key={attribute.modelName}
              modelName={attribute.modelName}
              url={attribute.url}
              totalEntries={attribute.totalEntries}
              recentEntries={attribute.recentEntries}
              lastModifiedAt={attribute.lastModifiedAt}
            />
          ))}
      </section>
    </div>
  );
};

export default Dashboard;

import gql from 'graphql-tag';

export const GET_PICTURES = gql`
  query pictures($imageableType: String, $imageableId: String) {
    pictures(imageableType: $imageableType, imageableId: $imageableId) {
      edges {
        cursor
        node {
          id
          imageUrls
          unsplashPhotoId
        }
      }
    }
  }
`;

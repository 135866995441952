import React from 'react';
import { Query } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getBanner } from '../../graphql/queries/banner';
import Header from '../common/Header';
import RenderImage from '../common/RenderImage';

const Banner = () => {
  let { id } = useParams();
  const { t } = useTranslation();

  return (
    <div className="list">
      <Header
        title="banners.show.title"
        linkTo={`/admin/banners/${id}/edit`}
        buttonLabel="shared.edit"
      />
      <Query {...getBanner(id)}>
        {({ loading, data }) => {
          if (loading) return <div className="lds-dual-ring" />;
          if (data) {
            const { banner } = data;

            return (
              <div className="common-show">
                <div className="grid">
                  <div className="col-12">
                    <div className="common-show__infos">
                      <div className="col-12">
                        <div className="common-show__infos--field">
                          {t('banners.attributes.description')}
                        </div>
                        <div className="common-show__infos--value">
                          {banner.description || t('shared.notAvailable')}
                        </div>
                        <div className="common-show__infos--field">
                          {t('banners.attributes.publishDescription')}
                        </div>
                        <div className="common-show__infos--value">
                          {banner.publishDescription.toString()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="common-show__images">
                  <div className="common-show__infos--field">{t('banners.attributes.image')}</div>
                  <RenderImage url={banner.imageUrls.large} />
                </div>
              </div>
            );
          }
          return null;
        }}
      </Query>
    </div>
  );
};

export default Banner;

import gql from 'graphql-tag';

export const CREATE_HASHTAG = gql`
  mutation createHashtag($name: String!) {
    createHashtag(name: $name) {
      id
      name
    }
  }
`;

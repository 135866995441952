import gql from 'graphql-tag';

export const GET_UNVERIFIED_SUPPLIER_FARE_BRAND = gql`
  query unverifiedSupplierFareBrand($id: ID!) {
    unverifiedSupplierFareBrand(id: $id) {
      id
      brandName
      cabinClass
      bookingClass
      supplierName
    }
  }
`;

export const GET_CURSORS_FOR_UNMATCHED_UNVERIFIED_SUPPLIER_FARE_BRANDS = gql`
  query unmatchedUnverifiedSupplierFareBrands($first: Int, $after: String, $query: String) {
    unmatchedUnverifiedSupplierFareBrands(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_UNMATCHED_UNVERIFIED_SUPPLIER_FARE_BRANDS = gql`
  query unmatchedUnverifiedSupplierFareBrands($first: Int, $after: String, $query: String) {
    unmatchedUnverifiedSupplierFareBrands(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          brandName
          airlineCode
          cabinClass
          bookingClass
          supplierName
        }
      }
    }
  }
`;

export const GET_TOTAL_UNVERIFIED_SUPPLIER_FARE_BRANDS = gql`
  query totalUnverifiedSupplierFareBrands {
    totalUnverifiedSupplierFareBrands
  }
`;

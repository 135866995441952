import React from 'react';
import { GET_HOTEL_COMBINED_DATABASES, GET_CURSORS_FOR_HOTEL_COMBINED_DATABASES } from '../../graphql/queries/hotelCombinedDatabase';
import List from '../common/List';

const HotelCombinedDatabaseList = () => {
  return (
    <List
      getItems={GET_HOTEL_COMBINED_DATABASES}
      getCursorsForItems={GET_CURSORS_FOR_HOTEL_COMBINED_DATABASES}
      listAttributes={['name', 'multipleSource']}
      disableDeletion={true}
      itemType="hotelCombinedDatabases"
      modelName="HotelCombinedDatabase"
      title="nav.hotelCombinedDatabases"
      showSearchAttributes={true}
      searchAttributes={['name', 'alternateNames', 'uniqueId', 'mainSource', 'otherSources', 'welltravelCodes', 'iso', 'place', 'supplierCodes']}
      minLengthToSearch={2}
    />
  );
};

export default HotelCombinedDatabaseList;

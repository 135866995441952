import gql from 'graphql-tag';
import { one, all } from './prepareQueryParams';

export const GET_CURSORS_FOR_ANCILLARY_SERVICES = gql`
  query ancillaryServices($first: Int,$after: String, $query: String) {
    ancillaryServices(first: $first, after: $after, query: $query) {
      edges {
        cursor
      }
    }
  }
`;

export const GET_ANCILLARY_SERVICES = gql`
  query ancillaryServices($first: Int, $after: String, $query: String) {
    ancillaryServices(first: $first, after: $after, query: $query) {
      edges {
        cursor
        node {
          id
          ssrCode
          serviceType
          ancillaryCategory {
            id
            identifier
          }
          description
          fullDescription
        }
      }
    }
  }
`;

export const getAncillaryServices = after => all(GET_ANCILLARY_SERVICES, after);

export const GET_ANCILLARY_SERVICE = gql`
  query ancillaryService($id: ID!) {
    ancillaryService(id: $id) {
      id
      ssrCode
      serviceType
      ancillaryCategory {
        id
        identifier
      }
      fullDescription
      description
      imageUrls
    }
  }
`;

export const getAncillaryService = id => one(GET_ANCILLARY_SERVICE, id);
